import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { has, pick, omit, sortBy } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { always, ifElse, join, pathOr, isEmpty } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import { Slider, Loader } from '@pure-escapes/webapp-ui-components';
import { useCurrentWidth } from 'effects';
import Label from 'pureUi/Label';
import Checkbox from 'pureUi/Checkbox';
import { formatDate } from 'utils';
import * as DateFNS from 'date-fns';

import * as RoomsStyles from './Rooms.styles';

import AccommodationCard from 'components/AccommodationCard';
import { getFromPrices } from 'components/AccommodationCard/utils';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { theme } from '../../../tailwind.config';
import * as HotelAccommodationProductActions from 'store/modules/hotelAccommodationProducts/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';

import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as HotelAccommodationProductSelectors from 'store/modules/hotelAccommodationProducts/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as SearchSelectors from 'store/modules/bookingBuilder/subdomains/search/selectors';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';
import * as BookingsSelectors from 'store/modules/bookings/selectors';
import {
  IHAPFormattedAccommodationProduct,
  IHAPFormattedAccommodationProductWithLiveRatesWithBookingComRates,
} from 'store/modules/hotelAccommodationProducts/types';
import { BasketRateTypes, TCurrencyCode } from 'interfaces';
import { Multiselect } from 'ui/Multiselect';
import * as Sentry from '@sentry/browser';
import * as StaticRatesAvailabilityActions from 'store/modules/staticRatesAvailability/actions';
import * as StaticRatesAvailabilitySelectors from 'store/modules/staticRatesAvailability/selectors';

export const filterRoomsByCategoryType = (rooms, categoryTypes) => {
  if (!Array.isArray(categoryTypes)) {
    categoryTypes = [categoryTypes];
  }

  if (categoryTypes.length <= 0) {
    return rooms;
  }

  return rooms.filter(room => {
    return categoryTypes.includes(room.categoryType);
  });
};

export const numbersInArrayBetweenRange = (nums, from, to) => {
  return nums.reduce((count, num) => {
    if (num >= from && num <= to) {
      count++;
    }
    return count;
  }, 0);
};

export const doGuestAgesFitInsideLodging = (guestAges, lodgingOccupancy, ages) => {
  const totalGuests = guestAges.numberOfAdults + guestAges.agesOfAllChildren.length;

  // first - is there too many people in total
  if (totalGuests > lodgingOccupancy.maximumPeople) {
    return false;
  }

  // get all the ageRanges and their counts into an object
  const ageBrackets = {
    default: guestAges.numberOfAdults,
  };
  ages.forEach(ageBracket => {
    ageBrackets[ageBracket.name] = numbersInArrayBetweenRange(
      guestAges.agesOfAllChildren,
      ageBracket.ageFrom,
      ageBracket.ageTo
    );
  });

  // for instead of forEach so we can do the return neatly
  for (let i = 0; i < lodgingOccupancy.limits.length; i++) {
    const limit = lodgingOccupancy.limits[i];
    const ageBracketForThisLimit = ageBrackets[limit.name];
    if (ageBracketForThisLimit < limit.minimum || ageBracketForThisLimit > limit.maximum) {
      return false;
    }
  }

  return true;
};

const mergeAccommodationProductData = (
  staticRates,
  liveRates,
  onlyShowLiveRates,
  bookingComRatesData
): IHAPFormattedAccommodationProductWithLiveRatesWithBookingComRates[] =>
  staticRates
    .map(accommodation => {
      // add in the booking com data first
      const bookingComRateForAccommodation =
        bookingComRatesData && bookingComRatesData.find(bc => bc.accommodationUuid === accommodation.uuid);
      if (bookingComRateForAccommodation) {
        accommodation.bookingComRate = { ...bookingComRateForAccommodation };
      }

      const liveRateAccommodation =
        Array.isArray(liveRates) && liveRates.find(item => accommodation.uuid === item.product.uuid);
      if (liveRateAccommodation) {
        accommodation.liveRates = liveRateAccommodation.rates;
      } else if (onlyShowLiveRates) {
        return null;
      }

      return accommodation;
    })
    .filter(accommodation => accommodation !== null);

export const Rooms = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hotelUuid, className } = props;

  const loadStatus = useSelector(HotelAccommodationProductSelectors.loadStatusSelector);

  const formattedAccommodationProducts: IHAPFormattedAccommodationProduct[] = useSelector(
    HotelAccommodationProductSelectors.getHotelAccommodationProductsSelector
  );

  const searchQuery = useSelector(FastSearchSelectors.offersQuerySelector);
  const currencyCode = useSelector(BookingsSelectors.getBookingCurrencySymbol) as TCurrencyCode;
  const bookingStatus = useSelector(BookingsSelectors.getBookingStatus);

  const staticRateAvailabilityNetworkRequests = useSelector(StaticRatesAvailabilitySelectors.networkRequestsSelector);
  const liveRates = useSelector(HotelAccommodationProductSelectors.getCurrentHotelAccommodationLiveRates);
  const liveRatesCurrency = useSelector(
    HotelAccommodationProductSelectors.getCurrentHotelAccommodationLiveRatesCurrency
  ) as TCurrencyCode;
  const roomsError = useSelector(
    HotelAccommodationProductSelectors.getCurrentHotelAccommodationProductsError
  ) as string;
  const isSr = useSelector(AuthSelectors.isSRRole);
  const company = useSelector(CompanyInfoSelectors.companyDataSelector) || 
                  useSelector(SearchSelectors.selectedCompanySelector);
  const basketRateType = useSelector(BookingBuilderSelectors.bookingBasketRateType) as BasketRateTypes;

  const [filteredAccommodationProducts, setFilteredAccommodationProducts] = useState(formattedAccommodationProducts);
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState<string[]>([]);
  const [accommodationProductMarkup, setAccommodationProductMarkup] = useState<JSX.Element[]>([]);
  const [onlyShowLiveRates, setOnlyShowLiveRates] = useState(false);
  const { isMobile } = useCurrentWidth();
  const bookingComRatesData = useSelector(HotelAccommodationProductSelectors.bookingComRatesDataSelector);

  if (roomsError) {
    Sentry.configureScope(function(scope) {
      scope.setTag('custom_source', 'hotelAccommodationProducts');
      scope.setTag('rooms_error', roomsError);
      scope.setTag('request_start_date', searchQuery.startDate);
      scope.setTag('request_end_date', searchQuery.endDate);
      scope.setTag('request_guest_ages', JSON.stringify(searchQuery.lodgings));
      scope.setTag('hotel_uuid', hotelUuid);
      scope.setTag('browser_url', window.location.href);
      Sentry.captureException(new Error(roomsError));
    });
  }
  // when the hotel changes, load new accommodation products
  useEffect(() => {
    async function load() {
      // request hotel accommodation products aka static rates
      // together with the live rates
      await dispatch(HotelAccommodationProductActions.fetchHotelAccommodationProductsRequestAction(hotelUuid));
    }
    load();
  }, [hotelUuid]);

  // after we've loaded the accommodation products, we need to load the static rates availabilities
  // for the time covering the search range
  useEffect(() => {
    if (isEmpty(formattedAccommodationProducts)) {
      return;
    }
    // const cursor = new Date();
    // const startDate = formatDate(Date.UTC(cursor.getFullYear(), cursor.getMonth(), 1));
    // const endDate = formatDate(DateFNS.addDays(new Date(startDate), 62));
    const accommodationUuids = formattedAccommodationProducts.map(x => x.uuid);
    async function load() {
      // request hotel accommodation products aka static rates
      // together with the live rates
      await dispatch(
        StaticRatesAvailabilityActions.fetchStaticRatesAvailabilityAggregateRequestAction(
          accommodationUuids,
          formatDate(DateFNS.startOfMonth(new Date(searchQuery.startDate))),
          formatDate(DateFNS.endOfMonth(DateFNS.addMonths(new Date(searchQuery.endDate), 1)))
        )
      );
    }
    load();
  }, [formattedAccommodationProducts]);

  // when the category changes, get new filtered accommodation products
  useEffect(() => {
    setFilteredAccommodationProducts(filterRoomsByCategoryType(formattedAccommodationProducts, selectedCategoryTypes));
  }, [formattedAccommodationProducts, selectedCategoryTypes]);

  // MARKUP
  useEffect(() => {
    const mergedAccommodationProducts = mergeAccommodationProductData(
      filteredAccommodationProducts,
      liveRates,
      onlyShowLiveRates,
      bookingComRatesData
    );

    const mergedAccommodationProductsSorted = sortBy(mergedAccommodationProducts, x => {
      const { fromPrice } = getFromPrices(x.staticRates, x.liveRates);
      return parseFloat(fromPrice || '');
    });

    const handleAddRoom = accommodationProductData => {
      const { liveRateData } = accommodationProductData;
      const isLiveRate = has(liveRateData, 'externalRateId') && has(liveRateData, 'externalMealPlanCode');
      const liveRate = isLiveRate
        ? pick(liveRateData, ['externalRateId', 'externalMealPlanCode', 'externalMealPlanDescription', 'amount'])
        : undefined;
      const guestAges = isLiveRate
        ? omit(liveRateData, ['externalRateId', 'externalMealPlanCode', 'externalMealPlanDescription', 'amount'])
        : accommodationProductData.guestAges;
      const accommodationProduct = isLiveRate
        ? { ...accommodationProductData, defaultMealPlan: null, liveRate }
        : accommodationProductData;
      
      const includeServiceCharge = company?.serviceChargeOverride !== '0.00';

      // @ts-ignore legacy
      dispatch(BookingBuilderActions.addLodgingAction(
        accommodationProduct,
        searchQuery,
        hotelUuid,
        includeServiceCharge,
        guestAges
      ));
    };

    const markup = mergedAccommodationProductsSorted.map(accommodationProduct => {
      const roomInfoPdf =
        accommodationProduct.accommodationInformation?.length > 0
          ? accommodationProduct.accommodationInformation[0]
          : undefined;

      return (
        <AccommodationCard
          key={hash(accommodationProduct)}
          accommodationProduct={accommodationProduct}
          id={accommodationProduct.uuid}
          currencyCode={currencyCode}
          title={accommodationProduct.title}
          description={accommodationProduct.description}
          moreInformation={accommodationProduct.moreInformation}
          amenities={accommodationProduct.amenities}
          size={accommodationProduct.size}
          brochures={accommodationProduct.floorPlans}
          roomInfoPdf={roomInfoPdf}
          occupancy={accommodationProduct.occupancy}
          imageUri={pathOr(null, ['photos', 0, 'url'], accommodationProduct)}
          addRoom={handleAddRoom}
          staticRates={accommodationProduct.staticRates || []}
          liveRates={accommodationProduct.liveRates || []}
          liveRatesCurrency={liveRatesCurrency}
          basketRateType={basketRateType}
          isSr={isSr}
        />
      );
    });

    setAccommodationProductMarkup(markup);
  }, [
    searchQuery,
    filteredAccommodationProducts,
    currencyCode,
    bookingStatus,
    hotelUuid,
    liveRates,
    liveRatesCurrency,
    onlyShowLiveRates,
    basketRateType,
    bookingComRatesData,
  ]);

  const categoryTypes = useMemo(() => {
    const uniqueCategoryTypes = formattedAccommodationProducts.reduce((accum, room) => {
      accum[room.categoryType] = room.categoryType;
      return accum;
    }, {});
    const categoryTypes = Object.keys(uniqueCategoryTypes).map(categoryType => ({
      value: categoryType,
      label: t(categoryType),
    }));

    return categoryTypes;
  }, [formattedAccommodationProducts]);

  const toggleShowOnlyLiveRates = useCallback(() => {
    setOnlyShowLiveRates(!onlyShowLiveRates);
  }, [onlyShowLiveRates]);

  return (
    <RoomsStyles.StyledRooms className={className}>
      <RoomsStyles.Columns>
        <RoomsStyles.Column>
          {/* @ts-ignore */}
          <RoomsStyles.Title>{t('labels.selectAvailableAccommodations')}</RoomsStyles.Title>
        </RoomsStyles.Column>
        {Array.isArray(liveRates) && liveRates.length > 0 && (
          <RoomsStyles.Column className="text-right mt-1 mr-2">
            <Label
              className="live-rates-filter text-left flex-row-reverse max-w-100px"
              text={t('labels.availableOnline')}
              textColor={onlyShowLiveRates ? theme.colors['bistre'] : theme.colors['flint']}
              inline
              wordWrap
            >
              <Checkbox
                label={t('labels.availableOnline')}
                onChange={toggleShowOnlyLiveRates}
                checked={onlyShowLiveRates}
              />
            </Label>
          </RoomsStyles.Column>
        )}
        <RoomsStyles.Column>
          {!isNilOrEmpty(categoryTypes) && (
            <Multiselect
              className="bg-ivory"
              itemsClassname="bg-ivory"
              itemCtaClassName="hover:bg-gray-10"
              options={categoryTypes}
              isCloseOnSelect={true}
              isIncludeClearButton={true}
              onUpdate={(sv: string[]) => {
                setSelectedCategoryTypes(sv);
              }}
              selectedValues={selectedCategoryTypes}
            />
          )}
        </RoomsStyles.Column>
      </RoomsStyles.Columns>
      {roomsError && (
        // @ts-ignore
        <RoomsStyles.RoomsError>{t('errors.getCurrentHotelAccommodationProducts')}</RoomsStyles.RoomsError>
      )}
      {!roomsError && (
        <Loader
          isLoading={
            loadStatus === ENetworkRequestStatus.PENDING ||
            staticRateAvailabilityNetworkRequests.aggregate === ENetworkRequestStatus.PENDING
          }
          text={t('messages.gettingAccommodationProducts')}
        >
          <RoomsStyles.AccommodationPricesInfo>
            {/* @ts-ignore */}
            {t('labels.accommodationPricesInfo')}
          </RoomsStyles.AccommodationPricesInfo>
          {isNilOrEmpty(filteredAccommodationProducts) && (
            // @ts-ignore
            <RoomsStyles.NoResults>{t('labels.noRooms')}</RoomsStyles.NoResults>
          )}

          {filteredAccommodationProducts.length >= 1 && (
            <RoomsStyles.RoomsWrapper>
              {isMobile ? <Slider infinite={false}>{accommodationProductMarkup}</Slider> : accommodationProductMarkup}
            </RoomsStyles.RoomsWrapper>
          )}
        </Loader>
      )}
    </RoomsStyles.StyledRooms>
  );
};

export default Rooms;
