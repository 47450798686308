import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, flatten } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '@pure-escapes/webapp-ui-components';
import { StandardModal, ModalContent } from 'pureUi/Modal';
import CustomItemForm from 'pureUi/CustomItemForm';
import BookingGuestInformationForm from 'pureUi/BookingGuestInformationForm';
import * as TableCardUI from 'pureUi/TableCard';
import PureCheckbox from 'pureUi/Checkbox';
import Checkbox from 'ui/Checkbox';

import { DisplayTotalsBreakdown } from 'components';
import { formatPrice, filterByObjectProperties } from 'utils';
import { getTaFullName } from 'store/utils';

import connect from './SummaryFormExtras.state';
import { propTypes, defaultProps } from './SummaryFormExtras.props';
import * as SummaryFormExtrasStyles from './SummaryFormExtras.styles';

import { HotelName } from '../SummaryForm/SummaryForm.styles';

import { IFine } from 'store/modules/bookingManager/sagas/bookingTransforms/BookingModel';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder';

import * as AuthSelectors from 'store/modules/auth/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as UISelectors from 'store/modules/ui/selectors';
import * as BookingBuilderCustomItemSelectors from 'store/modules/bookingBuilder/subdomains/customItem/selectors';
import InfoIcon from 'ui/Icons/components/Info.component';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';
import ArrowDown from 'ui/Icons/arrowDown.component.svg';
import ArrowUp from 'ui/Icons/arrowUp.component.svg';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { BookingMargin, useMarginChange } from './BookingMargin';
import { TravelAgentInfo } from './TravelAgentInfo';

export const renderHotelName = ({ name }) => {
  return (
    <TableCardUI.TableCardBox>
      <TableCardUI.TableCardRow depth={1}>
        <HotelName>{name}</HotelName>
      </TableCardUI.TableCardRow>
    </TableCardUI.TableCardBox>
  );
};

const InfoIconWithModal = ({ modalRender }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <React.Fragment>
      <button
        onClick={e => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="outline-none bg-transparent border-none cursor-pointer"
      >
        <SvgIcon
          className="-mt-[11px]"
          IconComponent={InfoIcon}
          defaultFill={theme.colors['brown-100']}
          hoverFill={theme.colors['teal-100']}
          activeFill={theme.colors['teal-100']}
          width="24"
          height="24"
        />
      </button>

      {isModalOpen && <StandardModal onClose={() => setIsModalOpen(false)}>{modalRender()}</StandardModal>}
    </React.Fragment>
  );
};

const renderInlinePrice = (translate, currencyCode, total, totalBeforeDiscount, isOnRequestOrPartiallyOnRequest) => {
  if (isOnRequestOrPartiallyOnRequest === true) {
    return <label>{translate('labels.priceAvailableOnRequest')}</label>;
  }
  if (total !== totalBeforeDiscount) {
    if (totalBeforeDiscount === '0.00') {
      return (
        <React.Fragment>
          <SummaryFormExtrasStyles.InlinePriceLabel
            data-discounted={true}
            margin-right={true}
            className="price-after-discount"
          >
            {currencyCode}
            {formatPrice(total)}
          </SummaryFormExtrasStyles.InlinePriceLabel>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <SummaryFormExtrasStyles.InlinePriceLabel
          data-discounted={true}
          margin-right={true}
          className="price-after-discount"
        >
          {currencyCode}
          {formatPrice(total)}
        </SummaryFormExtrasStyles.InlinePriceLabel>
        <SummaryFormExtrasStyles.InlinePriceLabel data-secondary={true} className="price-before-discount">
          {currencyCode}
          {formatPrice(totalBeforeDiscount)}
        </SummaryFormExtrasStyles.InlinePriceLabel>
      </React.Fragment>
    );
  } else {
    return (
      <label className="price">
        {currencyCode}
        {formatPrice(total)}
      </label>
    );
  }
};

const renderTransferOptionsSimple = (
  translate,
  selectedTransferOptions,
  transferOptions,
  updateTransferAction,
  hotelUuid,
  currencyCode
) => {
  const simpleTransfers = transferOptions.map(t => ({
    name: t.products[0].name,
    uuid: t.products[0].uuid,
    direction: t.meta && t.meta.direction && t.meta.direction ? t.meta.direction : undefined,
    description: t.products[0].meta.description,
    priceFormatted: renderInlinePrice(
      translate,
      currencyCode,
      t.total,
      t.totalBeforeDiscount,
      t.isOnRequestOrPartiallyOnRequest
    ),
  }));

  const handleRadioClick = e => {
    const dynamicParameters = ParameterService.getParameters();
    // OWA-5182
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('cart-click', {
        type: 'transfers',
        hotelUuid,
        itemLabel: e.target.label,
        itemUuid: e.target.value,
      });
    }
    updateTransferAction(
      {
        uuid: e.target.value,
        direction: undefined,
      },
      hotelUuid
    );
  };

  const handleCheckboxClick = to => {
    const dynamicParameters = ParameterService.getParameters();
    // OWA-5182
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('cart-click', {
        type: 'transfers',
        hotelUuid,
        itemLabel: `${to.name} ${to.priceFormatted}`,
        itemUuid: to.uuid,
      });
    }
    updateTransferAction(
      {
        uuid: to.uuid,
        direction: to.direction,
      },
      hotelUuid
    );
  };

  const bothWayTransfersOptions = simpleTransfers
    .filter(t => t.direction === undefined)
    .map(t => {
      return {
        value: t.uuid,
        label: (
          <SummaryFormExtrasStyles.ProductLabel className="normal-case">
            <span className="return-transfer-option">
              {t.name} {t.priceFormatted}{' '}
            </span>
            <InfoIconWithModal
              modalRender={() => (
                <ModalContent>
                  <h2 className="uppercase color-gold transfer-info-heading">
                    {t.name} <small>{t.priceFormatted}</small>
                  </h2>
                  <p className="transfer-info-description">{t.description}</p>
                </ModalContent>
              )}
            />
          </SummaryFormExtrasStyles.ProductLabel>
        ),
      };
    });

  const selectedBothWays = (bothWayTransfersOptions || []).find(x => x.value === selectedTransferOptions[0]?.uuid);

  const bothWayTransferMarkup = (
    <div className="transfers-return-options">
      <RadioButton
        onChange={e => handleRadioClick(e)}
        options={bothWayTransfersOptions}
        value={selectedBothWays?.value || null}
      />
    </div>
  );

  const inDirectionTransferOptions = simpleTransfers.filter(t => t.direction === 'in');
  const outDirectionTransferOptions = simpleTransfers.filter(t => t.direction === 'out');

  const buildMarkupForOneWayTransfers = transfers => {
    return (
      <SummaryFormExtrasStyles.OptionList>
        {transfers.map(to => {
          const isChecked = selectedTransferOptions.some(sto => sto.uuid === to.uuid && sto.direction === to.direction);
          return (
            <li key={to.name}>
              <label className="optionListLabel transfer-item">
                <PureCheckbox onChange={() => handleCheckboxClick(to)} checked={isChecked} className="checkbox" />
                <span className="!labelText w-7/10">
                  {to.name} {to.priceFormatted}{' '}
                </span>
                <InfoIconWithModal
                  modalRender={() => (
                    <ModalContent>
                      <h2 className="uppercase color-gold">
                        {to.name} <small>{to.priceFormatted}</small>
                      </h2>
                      <p>{to.description}</p>
                    </ModalContent>
                  )}
                />
              </label>
            </li>
          );
        })}
      </SummaryFormExtrasStyles.OptionList>
    );
  };

  const inDirectionTransfersMarkup = buildMarkupForOneWayTransfers(inDirectionTransferOptions);
  const outDirectionTransfersMarkup = buildMarkupForOneWayTransfers(outDirectionTransferOptions);

  return (
    <div>
      {bothWayTransfersOptions && (
        <SummaryFormExtrasStyles.BothWayTransferRadioWrapper>
          <label className="uppercase font-bold return-transfers-label">Return Transfers</label>
          {bothWayTransferMarkup}
        </SummaryFormExtrasStyles.BothWayTransferRadioWrapper>
      )}

      {bothWayTransfersOptions && inDirectionTransferOptions && <hr />}

      {inDirectionTransferOptions && (
        <React.Fragment>
          <label className="uppercase font-bold one-way-transfers-in-label">One Way Transfers (In)</label>
          <div className="transfers-in-options">{inDirectionTransfersMarkup}</div>
        </React.Fragment>
      )}

      {((bothWayTransfersOptions && outDirectionTransferOptions) ||
        (inDirectionTransferOptions && outDirectionTransferOptions)) && <hr />}
      {outDirectionTransfersMarkup && (
        <React.Fragment>
          <label className="uppercase font-bold one-way-transfers-out-label">One Way Transfers (Out)</label>
          <div className="transfers-out-options">{outDirectionTransfersMarkup}</div>
        </React.Fragment>
      )}
    </div>
  );
};

const renderGroundServices = (
  translate,
  currencyCode,
  selectedGroundServices,
  groundServices,
  updateGroundServiceAction,
  hotelUuid
) => {
  // OWA-5182
  const handleCheckboxClick = (gsProduct, hotelUuid) => {
    const dynamicParameters = ParameterService.getParameters();
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('cart-click', {
        type: 'ground-services',
        hotelUuid,
        itemLabel: gsProduct.name,
        itemUuid: gsProduct.uuid,
      });
    }
    updateGroundServiceAction(gsProduct, hotelUuid)
  };
  return (
    <div className="ground-services-options">
      {groundServices.map(gs => {
        const gsProduct = gs.products[0];
        const isChecked = selectedGroundServices.some(sgs => sgs.uuid === gsProduct.uuid);

        return (
          <label
            className="flex items-center cursor-pointer font-hurmegeometric-sans py-3 w-full"
            key={`${gsProduct.uuid}/${gsProduct.name}`}
          >
            <Checkbox
              onChange={() => handleCheckboxClick(gsProduct, hotelUuid)}
              checked={isChecked}
              wrapperClassName="mr-3"
              checkedClassName="-top-2px"
              color="brown-prime"
              borderColor="brown-prime"
              borderWidth={2}
              left={'2px'}
              rounded
              large
            />
            <SummaryFormExtrasStyles.ProductLabel className="normal-case">
              <div className="w-4/5">
                {gsProduct.name}{' '}
                {renderInlinePrice(
                  translate,
                  currencyCode,
                  gs.total,
                  gs.totalBeforeDiscount,
                  gs.isOnRequestOrPartiallyOnRequest
                )}
              </div>
              <InfoIconWithModal
                modalRender={() => (
                  <ModalContent>
                    <h2 className="uppercase color-gold">
                      {gsProduct.name}{' '}
                      <small>
                        {renderInlinePrice(
                          translate,
                          currencyCode,
                          gs.total,
                          gs.totalBeforeDiscount,
                          gs.isOnRequestOrPartiallyOnRequest
                        )}
                      </small>
                    </h2>
                    <p>{gsProduct.meta.description}</p>
                  </ModalContent>
                )}
              />
            </SummaryFormExtrasStyles.ProductLabel>
          </label>
        );
      })}
    </div>
  );
};

const isCustomItem = supplementProduct =>
  supplementProduct.options && supplementProduct.options.genericIdentifier === 'customItem';

const renderAddons = (
  translate,
  currencySymbol,
  selectedSupplements,
  selectedFines,
  availableSupplements: IFine[],
  availableFines,
  updateSupplementAction,
  updateFineAction,
  hotelUuid,
  customItem
) => {
  const renderSupplement = (sp, idx, isCustomSupplement) => {
    const supplementProduct = sp.products[0];
    const isChecked = selectedSupplements.some(sgs => sgs.uuid === supplementProduct.uuid);

    const handleSupplementAction = (supplementProduct, hotelUuid) => {
      const dynamicParameters = ParameterService.getParameters();
      // OWA-5182
      if (dynamicParameters.WOOPRA_DOMAIN) {
        // @ts-ignore
        window.woopra && window.woopra.track('cart-click', {
          type: 'other-items',
          hotelUuid,
          itemLabel: `${supplementProduct.name}`,
          itemUuid: supplementProduct.uuid,
        });
      }
      updateSupplementAction(supplementProduct, hotelUuid);
    };

    return (
      <label
        className="flex items-center cursor-pointer w-full font-hurmegeometric-sans py-3"
        key={`${supplementProduct.uuid}/${supplementProduct.name}`}
      >
        <Checkbox
          onChange={() =>
            isCustomSupplement
              ? customItem.actions.remove(idx, hotelUuid)
              : handleSupplementAction(supplementProduct, hotelUuid)
          }
          checked={isChecked || isCustomSupplement}
          disabled={sp.mandatory && !isCustomSupplement}
          wrapperClassName="mr-3"
          checkedClassName="-top-2px"
          color="brown-prime"
          borderColor="brown-prime"
          borderWidth={2}
          left={'2px'}
          rounded
          large
        />
        <SummaryFormExtrasStyles.ProductLabel className="normal-case">
          <span>
            {supplementProduct.name}{' '}
            {renderInlinePrice(
              translate,
              currencySymbol,
              sp.total,
              sp.totalBeforeDiscount,
              sp.isOnRequestOrPartiallyOnRequest
            )}
          </span>
          <InfoIconWithModal
            modalRender={() => (
              <ModalContent>
                <h2 className="uppercase color-gold">
                  {supplementProduct.name}{' '}
                  <small>
                    {renderInlinePrice(
                      translate,
                      currencySymbol,
                      sp.total,
                      sp.totalBeforeDiscount,
                      sp.isOnRequestOrPartiallyOnRequest
                    )}
                  </small>
                </h2>
                <p>{supplementProduct.meta.description}</p>
              </ModalContent>
            )}
          />
        </SummaryFormExtrasStyles.ProductLabel>
      </label>
    );
  };

  const standardSupplements = availableSupplements.filter(sp => !isCustomItem(sp.products[0]));
  const customSupplements = availableSupplements.filter(sp => isCustomItem(sp.products[0]));

  const fineMarkup = (
    <React.Fragment>
      {availableFines.map(fp => {
        const fineProduct = fp.products[0];
        const isChecked = selectedFines.some(sgs => sgs.uuid === fineProduct.uuid);
        return (
          <label
            className="flex items-center cursor-pointer w-full font-hurmegeometric-sans py-3"
            key={`${fineProduct.uuid}/${fineProduct.name}`}
          >
            <Checkbox
              onChange={() => updateFineAction(fineProduct, hotelUuid)}
              checked={isChecked}
              wrapperClassName="mr-3"
              checkedClassName="-top-2px"
              color="brown-prime"
              borderColor="brown-prime"
              borderWidth={2}
              left={'2px'}
              rounded
              large
            />
            <SummaryFormExtrasStyles.ProductLabel>
              <span>
                {fineProduct.name}{' '}
                {renderInlinePrice(
                  translate,
                  currencySymbol,
                  fp.total,
                  fp.totalBeforeDiscount,
                  fp.isOnRequestOrPartiallyOnRequest
                )}
              </span>
              <InfoIconWithModal
                modalRender={() => (
                  <ModalContent>
                    <h2 className="uppercase color-gold">
                      {fineProduct.name}{' '}
                      <small>
                        {renderInlinePrice(
                          translate,
                          currencySymbol,
                          fp.total,
                          fp.totalBeforeDiscount,
                          fp.isOnRequestOrPartiallyOnRequest
                        )}
                      </small>
                    </h2>
                    <p>{fineProduct.meta.description}</p>
                  </ModalContent>
                )}
              />
            </SummaryFormExtrasStyles.ProductLabel>
          </label>
        );
      })}
    </React.Fragment>
  );
  const customItemMarkup = customItem ? (
    <CustomItemForm
      currencySymbol={currencySymbol}
      data={customItem.payload}
      validation={customItem.validation}
      onNameChange={customItem.actions.updateName}
      onTotalChange={customItem.actions.updateTotal}
      onDescriptionChange={customItem.actions.updateDescription}
      onCountAsMealPlanChange={customItem.actions.updateCountsAsMealPlan}
      onCountAsTransferChange={customItem.actions.updateCountsAsTransfer}
      onShow={customItem.actions.showForm}
      onCancel={customItem.actions.hideForm}
      onConfirm={() => customItem.actions.save(hotelUuid)}
    />
  ) : null;

  return (
    <div className="addon-options">
      {standardSupplements.map((sp, idx) => renderSupplement(sp, idx, false))}
      {fineMarkup}
      {customSupplements.map((sp, idx) => renderSupplement(sp, idx, true))}
      {customItemMarkup}
    </div>
  );
};


const renderGuestInfo = (data, onChange, validation) => {
  return (
    <TableCardUI.TableCardBox className="mt-4 mb-4">
      <TableCardUI.TableCardRow depth={3}>
        <SummaryFormExtrasStyles.Title>Lead Guest Info</SummaryFormExtrasStyles.Title>
        <BookingGuestInformationForm
          bookingGuestFormValues={data}
          onValueChange={onChange}
          sections={{ guestInfo: true }}
          validation={validation}
        />
      </TableCardUI.TableCardRow>
    </TableCardUI.TableCardBox>
  );
};

export const SummaryFormExtras = ({
  booking,
  currencySymbol,
  id,
  setIsBookingSummarySectionCollapsed,
  updateTransferAction,
  updateGroundServiceAction,
  updateSupplementAction,
  updateFineAction,
  customItemActions,
  updateBookingGuestInformationAction,
}) => {
  const { t } = useTranslation();
  const { dynamicParameters } = useDynamicParameters();

  const availableAddons = useSelector(BookingBuilderSelectors.bookingAvailableAddonsSelector);
  const groundServices = useSelector(BookingBuilderSelectors.bookingAvailableGroundServicesSelector);
  const transfers = useSelector(BookingBuilderSelectors.bookingAvailableTransfersSelector);
  const availableFines = useSelector(BookingBuilderSelectors.bookingAvailableFinesSelector);
  const availableSupplements = useSelector(BookingBuilderSelectors.bookingAvailableSupplementsSelector);
  const selectedFines = useSelector(BookingBuilderSelectors.bookingRequestedFinesSelector);
  const selectedGroundServices = useSelector(BookingBuilderSelectors.bookingRequestedGroundServicesSelector);
  const selectedSupplements = useSelector(BookingBuilderSelectors.bookingRequestedSupplementsSelector);
  const selectedTransfers = useSelector(BookingBuilderSelectors.bookingRequestedTransfersSelector);
  const canBook = useSelector(BookingBuilderSelectors.bookingCanBookSelector);
  const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
  const grandTotal = useSelector(BookingBuilderSelectors.bookingBuilderTotalSelector);
  const travelAgent = useSelector(AgentsSelectors.selectedTaSelector);
  const isTransferSectionCollapsed = useSelector(UISelectors.getIsTransferSectionCollapsed);
  const isGroundServicesSectionCollapsed = useSelector(UISelectors.getIsGroundServicesSectionCollapsed);
  const isAddonsSectionCollapsed = useSelector(UISelectors.getIsAddonsSectionCollapsed);
  const selectedTransfersBreakdown = useSelector(BookingBuilderSelectors.bookingRequestedTransfersBreakdownSelector);
  const isTAMarginApplied = useSelector(BookingBuilderSelectors.isTAMarginAppliedSelector);
  const taMarginType = useSelector(BookingBuilderSelectors.taMarginTypeSelector);
  const taMarginAmount = useSelector(BookingBuilderSelectors.taMarginAmountSelector);
  const customItem = {
    payload: useSelector(BookingBuilderCustomItemSelectors.customItemPayloadSelector),
    validation: useSelector(BookingBuilderCustomItemSelectors.customItemValidationSelector),
  };
  const isPristine = useSelector(BookingBuilderSelectors.isPristineSelector);
  const domainValidation = useSelector(BookingBuilderSelectors.domainValidationSelector);

  const isSr: boolean = useSelector(AuthSelectors.isSR);
  const isRl: boolean = useSelector(AuthSelectors.isRL);

  useEffect(() => {
    updateBookingGuestInformationAction({
      guestTitle: null,
      guestFirstName: '',
      guestLastName: '',
    });
  }, []);

  const validation = isPristine ? {} : domainValidation;
  const hasTAInfo = isSr && !isRl;

  const { onMarginChange } = useMarginChange(id);

  const selectedGroundServicesBreakdown = useCallback(() => {
    const selectedGroundServiceProducts = filterByObjectProperties(
      flatten(groundServices.map(g => g.products)),
      selectedGroundServices,
      ['uuid']
    );

    if (selectedGroundServiceProducts.length >= 1) {
      return selectedGroundServiceProducts.map(stp => stp.name).join(' & ');
    }

    return 'None selected';
  }, [selectedGroundServices, groundServices]);

  const selectedAddonsBreakdown = useCallback(() => {
    const selectedAddons = flatten([selectedFines, selectedSupplements]);

    const customItemProducts = flatten(availableSupplements.map(sp => sp.products)).filter(isCustomItem);

    const selectedAddonProducts = filterByObjectProperties(
      flatten(availableAddons.map(a => a.products)),
      selectedAddons,
      ['uuid']
    ).concat(customItemProducts);

    if (selectedAddonProducts.length >= 1) {
      return selectedAddonProducts.map(stp => stp.name).join(' & ');
    }

    return 'None selected';
  }, [selectedFines, selectedSupplements, availableAddons]);

  const renderTransfersWrapper = () => {
    return (
      <TableCardUI.TableCardBox className="table-card-box">
        <TableCardUI.TableCardRow className="table-card-row" depth={1}>
          {/* @ts-ignore */}
          <HotelName>{t('labels.transfers')}</HotelName>
        </TableCardUI.TableCardRow>

        <TableCardUI.TableCardRow className="table-card-row" depth={3}>
          <SummaryFormExtrasStyles.CollapseTitle>
            <label
              className={`selected-transfers-label uppercase font-bold ${
                !isTransferSectionCollapsed ? 'color-teal' : ''
              }`}
            >
              {selectedTransfersBreakdown}
            </label>
            <SummaryFormExtrasStyles.CollapseToggle
              className="collapse-toggle transfers"
              type="button"
              onClick={() =>
                setIsBookingSummarySectionCollapsed({
                  type: 'transfers',
                  value: !isTransferSectionCollapsed,
                })
              }
            >
              {isTransferSectionCollapsed === false ? (
                <SvgIcon width="13" height="19" IconComponent={ArrowUp} defaultFill={theme.colors.black} />
              ) : (
                <SvgIcon width="13" height="19" IconComponent={ArrowDown} defaultFill={theme.colors.black} />
              )}
            </SummaryFormExtrasStyles.CollapseToggle>
          </SummaryFormExtrasStyles.CollapseTitle>
        </TableCardUI.TableCardRow>

        {isTransferSectionCollapsed === false && (
          <TableCardUI.TableCardRow className="table-card-row" depth={3}>
            {renderTransferOptionsSimple(t, selectedTransfers, transfers, updateTransferAction, id, currencySymbol)}
          </TableCardUI.TableCardRow>
        )}
      </TableCardUI.TableCardBox>
    );
  };

  const renderGroundServicesWrapper = () => {
    const breakdown = selectedGroundServicesBreakdown();
    return (
      <TableCardUI.TableCardBox className="table-card-box mt-4">
        <TableCardUI.TableCardRow className="table-card-row" depth={1}>
          {/* @ts-ignore */}
          <HotelName>{t('labels.groundServices')}</HotelName>
        </TableCardUI.TableCardRow>
        <TableCardUI.TableCardRow className="table-card-row" depth={3}>
          <SummaryFormExtrasStyles.CollapseTitle>
            <label className={`uppercase font-bold ${!isGroundServicesSectionCollapsed ? 'color-teal ' : ''}`}>
              {breakdown}
            </label>

            <SummaryFormExtrasStyles.CollapseToggle
              className="collapse-toggle ground-services"
              type="button"
              onClick={() =>
                setIsBookingSummarySectionCollapsed({
                  type: 'ground_services',
                  value: !isGroundServicesSectionCollapsed,
                })
              }
            >
              {isGroundServicesSectionCollapsed === false ? (
                <SvgIcon width="13" height="19" IconComponent={ArrowUp} defaultFill={theme.colors.black} />
              ) : (
                <SvgIcon width="13" height="19" IconComponent={ArrowDown} defaultFill={theme.colors.black} />
              )}
            </SummaryFormExtrasStyles.CollapseToggle>
          </SummaryFormExtrasStyles.CollapseTitle>
        </TableCardUI.TableCardRow>
        {isGroundServicesSectionCollapsed === false && (
          <TableCardUI.TableCardRow className="table-card-row" depth={3}>
            {renderGroundServices(
              t,
              currencySymbol,
              selectedGroundServices,
              groundServices,
              updateGroundServiceAction,
              id
            )}
          </TableCardUI.TableCardRow>
        )}
      </TableCardUI.TableCardBox>
    );
  };

  const renderAddonsWrapper = () => {
    const breakdown = selectedAddonsBreakdown();
    return (
      <TableCardUI.TableCardBox className="table-card-box mt-4">
        <TableCardUI.TableCardRow className="table-card-row" depth={1}>
          {/* @ts-ignore */}
          <HotelName>{t('labels.addons')}</HotelName>
        </TableCardUI.TableCardRow>

        <TableCardUI.TableCardRow className="table-card-row" depth={3}>
          <SummaryFormExtrasStyles.CollapseTitle>
            <label className={`uppercase font-bold ${!isAddonsSectionCollapsed ? 'color-teal ' : ''}`}>
              {breakdown}
            </label>
            <SummaryFormExtrasStyles.CollapseToggle
              className="collapse-toggle addons"
              type="button"
              onClick={() =>
                setIsBookingSummarySectionCollapsed({
                  type: 'addons',
                  value: !isAddonsSectionCollapsed,
                })
              }
            >
              {isAddonsSectionCollapsed === false ? (
                <SvgIcon width="13" height="19" IconComponent={ArrowUp} defaultFill={theme.colors.black} />
              ) : (
                <SvgIcon width="13" height="19" IconComponent={ArrowDown} defaultFill={theme.colors.black} />
              )}
            </SummaryFormExtrasStyles.CollapseToggle>
          </SummaryFormExtrasStyles.CollapseTitle>
        </TableCardUI.TableCardRow>

        {isAddonsSectionCollapsed === false && (
          <TableCardUI.TableCardRow className="table-card-row" depth={3}>
            {renderAddons(
              t,
              currencySymbol,
              selectedSupplements,
              selectedFines,
              // @ts-ignore
              availableSupplements,
              availableFines,
              updateSupplementAction,
              updateFineAction,
              id,
              isSr
                ? {
                    ...customItem,
                    actions: customItemActions,
                  }
                : null
            )}
          </TableCardUI.TableCardRow>
        )}
      </TableCardUI.TableCardBox>
    );
  };

  const totalCents = booking?.response?.totals?.totalForPricedItemsCents | 0;

  return (
    <Fragment>
      <TableCardUI.TableCardNumberedBanner className="mt-4 mb-4">
        <TableCardUI.TableCardNumberBannerNumber>3</TableCardUI.TableCardNumberBannerNumber>
        <TableCardUI.TableCardNumberBannerText>Select Your Add-Ons</TableCardUI.TableCardNumberBannerText>
      </TableCardUI.TableCardNumberedBanner>

      {transfers.length >= 1 && renderTransfersWrapper()}
      {groundServices.length >= 1 && renderGroundServicesWrapper()}
      {availableAddons.length >= 1 && renderAddonsWrapper()}

      <TableCardUI.TableCardNumberedBanner className="mt-4 mb-4">
        <TableCardUI.TableCardNumberBannerNumber>4</TableCardUI.TableCardNumberBannerNumber>
        <TableCardUI.TableCardNumberBannerText>Review Costs</TableCardUI.TableCardNumberBannerText>
      </TableCardUI.TableCardNumberedBanner>

      {totalCents > 0 &&
        renderHotelName({
          name: booking?.response?.hotel?.name,
        })}

      {totalCents > 0 && (
        <DisplayTotalsBreakdown
          // @ts-ignore DisplayTotalsBreakdown definitely DOES take a translate func. I think this is styled components being weird
          translate={t}
          currencyCode={currencySymbol}
          displayTotals={booking.response.displayTotals}
        />
      )}
      {/* SRs need to be able to select TAs */}
      {hasTAInfo && <TravelAgentInfo />}
    </Fragment>
  );
};

SummaryFormExtras.propTypes = propTypes;
SummaryFormExtras.defaultProps = defaultProps;

export default compose(connect)(SummaryFormExtras);
