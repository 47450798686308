import { call, takeLatest, select, put } from 'redux-saga/effects';
import { selectedTaSelector } from '../../agents';
import { CREATE_TASK_COMMENT_REQUEST, createTaskCommentFailureAction, CreateTaskCommentRequestAction, createTaskCommentSuccessAction, listTaskCommentsRequestAction, removeFocusedCommentAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';

export function* createTaskCommentSaga(action: CreateTaskCommentRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);

    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    yield call(taskManagementApi.createTaskComment, action.taskId, action.comment?.trim()); 
    yield put(createTaskCommentSuccessAction());
    yield put(removeFocusedCommentAction(action.commentId));
    yield put(enqueueNotification({ message: 'New task created successfully.', options: { variant: 'success' } }));
    yield put(listTaskCommentsRequestAction(action.taskId));
  } catch (e) {
    yield put(createTaskCommentFailureAction(e));
    yield put(enqueueNotification({ message: 'Unable to save comment', options: { variant: 'error' } }));
  }
}

export function* watchCreateTaskCommentSaga() {
  yield takeLatest(
    [CREATE_TASK_COMMENT_REQUEST],
    createTaskCommentSaga
  );
}
