import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { BookingHotel } from './BookingHotel';
import { PageTitle } from 'ui/PageTitle';
import * as HotelSelectors from 'store/modules/hotel';
import * as HotelActions from 'store/modules/hotel/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as HotelAccommodationProductsActions from 'store/modules/hotelAccommodationProducts/actions';
import * as StaticRatesAvailabilityActions from 'store/modules/staticRatesAvailability/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { BasketRightColumn } from './BasketRightColumn';
import { useTranslation } from 'react-i18next';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import { History } from 'history';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { isNil } from 'lodash-es';
import { Multiselect } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { SvgIcon } from 'ui/SvgIcon';
import Copy from 'ui/Icons/copy.component.svg';
import { Link } from 'react-router-dom';
import { ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { BasketItem } from './BasketItem';
import { IBasketBuild, IBasketBuildWithHotelPhoto } from 'services/BackendApi/types/Basket';
import classNames from 'classnames';
import * as AuthSelectors from 'store/modules/auth/selectors';

interface IBasketPageParams {
  hotelUuid: string;
}

export const BasketPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const booking = useSelector(BookingBuilderSelectors.bookingBuilderSelector);
  const isTa = useSelector(AuthSelectors.isTA);
  const { t } = useTranslation();
  const [shouldShowLeaveAlert, setShouldShowLeaveAlert] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);

  const lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, booking);

  const [offset, setOffset] = useState(0);
  const [builds, setBuilds] = useState<IBasketBuildWithHotelPhoto[]>([]);
  const [totalBuilds, setTotalBuilds] = useState<number | null>(null);
  const [getBasketRequestPageLoad, setGetBasketRequestPageLoad] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketRequestOffsetChange, setGetBasketRequestOffsetChange] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [selectedBuildUuid, setSelectedBuildUuid] = useState<string | null>(null);

  const currentBookingBuild = useSelector(BookingBuilderSelectors.bookingBuilderSelector);
  const backendApi = makeBackendApi();

  useEffect(() => {
    if (!isTa) {
      offset === 0
        ? setGetBasketRequestPageLoad(ENetworkRequestStatus.SUCCESS)
        : setGetBasketRequestOffsetChange(ENetworkRequestStatus.SUCCESS);
      return;
    }
    offset === 0
      ? setGetBasketRequestPageLoad(ENetworkRequestStatus.PENDING)
      : setGetBasketRequestOffsetChange(ENetworkRequestStatus.PENDING);

    backendApi
      .getBasket({
        offset,
      })
      .then(async res => {
        // get photos for all the hotels...
        const hotelUuids = res.data.builds.map(build => build.initialBuildResponse.hotel.uuid);
        const uploadsForHotels = await backendApi.getPhotosForHotels(hotelUuids);

        // ...and then put a photo into the build for the hotel
        const buildsWithHotelPhotos = res.data.builds.map(build => {
          const hotelUploads = uploadsForHotels.data.data.filter(
            upload => upload.ownerUuid === build.initialBuildResponse.hotel.uuid
          );
          let hotelPhoto = hotelUploads.find(upload => upload.tag === 'featuredPhoto');
          if (!hotelPhoto) {
            hotelPhoto = hotelUploads[0];
          }
          return {
            ...build,
            hotelPhotoUrl: hotelPhoto?.url,
          };
        });

        setBuilds([...builds, ...buildsWithHotelPhotos]);
        setTotalBuilds(res.data.total);
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.SUCCESS)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.SUCCESS);
      })
      .catch(error => {
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.ERROR)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.ERROR);
      });
  }, [offset]);

  return (
    <div className="basket-page container mx-auto max-w-[1800px] font-pt-sans text-black px-[20px]">
      <div className="basket-columns flex gap-[40px] mt-[20px]">
        {/* the widths are the size of the right coluimn AND the x padding of the container */}
        <div
          className={classNames('basket-left-column flex flex-col w-[calc(100%-280px)] lg:w-[calc(100%-390px)]', {
            'opacity-50 pointer-events-none': getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING,
          })}
        >
          <div className="page-title-container flex items-center justify-between">
            <h1 className={'primary-title font-noe-display text-4xl leading-46px text-black font-normal m-0'}>
              Basket <span className="text-26px leading-33">- Select Product</span>
            </h1>

            <button
              disabled={true}
              className="bg-white border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm flex items-center space-x-5px disabled:opacity-50"
            >
              <SvgIcon IconComponent={Copy} className="w-[18px] h-[18px] fill-brown-prime" />
              <span>Copy Items</span>
            </button>
          </div>

          {getBasketRequestPageLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}

          {/* no results found */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && builds.length <= 0 && (
            <div className="flex flex-col">
              <span className="text-15px leading-18px mt-20px mb-30px">No results found</span>

              <Link
                className="block self-start bg-white border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm"
                to="/filters"
              >
                <span className="text-brown-prime">Search Products</span>
              </Link>
            </div>
          )}

          {/* filters (if theres results and you're a TA) */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && builds.length > 0 && !isTa && (
            <div className="filters flex space-x-5">
              <div className="flex flex-col space-y-2 w-full">
                <span>Basket Created by *</span>
                <Multiselect
                  className="bg-white"
                  itemsClassname="bg-white"
                  itemCtaClassName="hover:bg-gray-10"
                  options={[]}
                  isCloseOnSelect={true}
                  hideCheckboxes={true}
                  isSingleSelectMode={true}
                  isIncludeClearButton={true}
                  onUpdate={sv => {
                    console.log('selected values', sv);
                  }}
                  selectedValues={[]}
                  hideDropdownArrow
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <span>Travel Company - Travel Agent *</span>
                <Multiselect
                  className="bg-white"
                  itemsClassname="bg-white"
                  itemCtaClassName="hover:bg-gray-10"
                  options={[]}
                  isCloseOnSelect={true}
                  hideCheckboxes={true}
                  isSingleSelectMode={true}
                  isIncludeClearButton={true}
                  onUpdate={sv => {
                    console.log('selected values', sv);
                  }}
                  selectedValues={[]}
                  hideDropdownArrow
                />
              </div>
            </div>
          )}

          {/* results */}
          <div className="flex flex-col space-y-10px mt-20px">
            {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS &&
              builds.map(build => (
                <BasketItem
                  key={build.uuid}
                  build={build}
                  isSelected={selectedBuildUuid === build.uuid}
                  onSelectBuild={uuid => {
                    setSelectedBuildUuid(uuid);
                  }}
                />
              ))}
          </div>

          {/* the load more button */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && (
            <button
              className="bg-white mt-20px self-center block border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm items-center space-x-5px cursor-pointer min-w-[125px]"
              disabled={getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING}
              onClick={() => {
                setOffset(offset + 10);
              }}
            >
              {getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING ? (
                <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>

        <div className="basket-right-column-wrapper fixed right-[20px] top-[140px] w-[260px] lg:w-[370px]">
          <BasketRightColumn
            bookingBuild={currentBookingBuild}
            lodgingSummaries={lodgingSummaries}
            shouldShowLeaveAlert={shouldShowLeaveAlert}
            setShouldShowLeaveAlert={setShouldShowLeaveAlert}
            setRedirectLocation={setRedirectLocation}
          />
        </div>
      </div>

      <LeaveWithoutSavingModal
        title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
        when={shouldShowLeaveAlert}
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </div>
  );
});
