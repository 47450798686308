import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  availableHotelsLoadSelector,
  closeCreateMarkupModalAction,
  companyHotelWithoutMarkupSelector,
  createCHMarkupRequestAction,
  createMarkupLoadSelector,
  fetchCompanyHotelsWithoutMarkupRequestAction,
} from 'store/modules/companyHotelMarkup';
import { ICompanyHotel } from 'store/modules/companyHotelMarkup/model';

import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { getCountryName } from 'utils/country';
import { LoadingBar } from 'ui/NetworkStatusBar';

interface AddMarkupModalProps {
  isOpen: boolean;
}

interface IHotelProps extends ICompanyHotel {
  check: (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const Hotel: React.FC<IHotelProps> = ({ name, countryCode, checked, check }) => {
  const className = ['markup', ...name.split(' ')].join('-').toLowerCase();
  
  return (
    <div className="flex font-normal my-2 justify-between items-end">
      <label className="flex cursor-pointer items-end" onClick={check}>
        <Checkbox className={className} checked={checked} onClick={check} />
        <span className="ml-2">{name}</span>
      </label>
      <span className="text-13px">{getCountryName(countryCode)}</span>
    </div>
  );
};

export const AddMarkupModal: React.FC<AddMarkupModalProps> = React.memo(({ isOpen }) => {
  const [selectedHotels, setSelectedHotels] = useState<string[]>([]);
  const [markupPercentage, setMarkupPercentage] = useState<string>('00.00');
  const dispatch = useDispatch();
  const availableHotels = useSelector(companyHotelWithoutMarkupSelector);
  const availableHotelsLoad = useSelector(availableHotelsLoadSelector);
  const createMarkupLoad = useSelector(createMarkupLoadSelector);

  const hotelListClassName = classnames(
    'available-hotels border border-solid border-gray-40 bg-ivory p-1 pl-2 rounded',
    {
      'flex justify-center items-center': availableHotelsLoad === ENetworkRequestStatus.PENDING,
    }
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCompanyHotelsWithoutMarkupRequestAction());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (createMarkupLoad === ENetworkRequestStatus.SUCCESS) {
      dispatch(closeCreateMarkupModalAction());
      dispatch(fetchCompanyHotelsWithoutMarkupRequestAction());
      setSelectedHotels([]);
      setMarkupPercentage('00.00');
    } else if (createMarkupLoad === ENetworkRequestStatus.ERROR) {
      dispatch(closeCreateMarkupModalAction());
      setSelectedHotels([]);
      setMarkupPercentage('00.00');
    }
  }, [createMarkupLoad, dispatch]);

  const handleCloseClick = useCallback(() => {
    dispatch(closeCreateMarkupModalAction());
  }, [dispatch]);

  const handleCreateMarkup = useCallback(() => {
    dispatch(createCHMarkupRequestAction(markupPercentage, selectedHotels));
  }, [dispatch, markupPercentage, selectedHotels]);

  const toggleSelectAll = useCallback(
    (e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (availableHotels.length === selectedHotels.length) {
        setSelectedHotels([]);
        return;
      }

      setSelectedHotels(availableHotels.map(hotel => hotel.uuid));
    },
    [availableHotels, selectedHotels.length]
  );

  const toggleSelectHotel = useCallback(
    (checked: boolean, uuid: string) => (
      e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>
    ) => {
      e.stopPropagation();
      e.preventDefault();

      if (checked) {
        setSelectedHotels(selectedHotels.filter(selectedHotel => selectedHotel !== uuid));
        return;
      }

      setSelectedHotels([...selectedHotels, uuid]);
    },
    [selectedHotels]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName={`pt-4 px-8 pb-8 border border-solid text-black border-black-true font-pt-sans overflow-y-hidden`}
      showCloseButton={false}
      closeOnOutsideClick={true}
      onClose={handleCloseClick}
      removePadding
    >
      <div
        className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
        onClick={handleCloseClick}
      >
        <i className="fas fa-times"></i>
      </div>
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Add New Mark Up</h3>

      <div className="my-2">
        <span className="font-bold">Mark-Up</span>
        <span className="ml-1">(%)</span>
        <div className="flex text-black items-baseline my-2">
          <div className="w-16">
            <NumericMaskedInput
              inputClassName="markup-input"
              initialValue="0.00"
              value={markupPercentage}
              mask="##.##"
              onChange={setMarkupPercentage}
              allowNegative
            />
          </div>
          <span className="ml-2 text-15px">Including two decimals (##.##)</span>
        </div>
      </div>

      <div className="mb-2">
        <span className="font-bold">Select Hotels</span>
        <span className="ml-1">{selectedHotels.length > 0 && `(${selectedHotels.length} selected)`}</span>
      </div>

      <div className={hotelListClassName} style={{ width: '400px', minHeight: '366px' }}>
        {availableHotelsLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}
        {availableHotelsLoad === ENetworkRequestStatus.SUCCESS && (
          <>
            <div className="flex font-normal py-2 px-1 bg-white rounded">
              <label className="flex cursor-pointer items-end" onClick={toggleSelectAll}>
                <Checkbox checked={availableHotels.length === selectedHotels.length} onClick={toggleSelectAll} />
                <span className="ml-2">Select / Unselect All</span>
              </label>
            </div>

            <div className="overflow-y-auto px-1 pt-2 pb-2" style={{ height: '320px' }}>
              {availableHotels.map(hotel => {
                const checked = selectedHotels.findIndex(selectedHotel => selectedHotel === hotel.uuid) > -1;
                return (
                  <Hotel key={hotel.uuid} check={toggleSelectHotel(checked, hotel.uuid)} checked={checked} {...hotel} />
                );
              })}
            </div>
          </>
        )}
      </div>

      <div className="flex mt-8">
        <FluidButton
          type="secondary"
          className="cancel-button ml-3"
          disabled={createMarkupLoad === ENetworkRequestStatus.PENDING}
          onClick={handleCloseClick}
        >
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="create-markup-button ml-3"
          isLoading={createMarkupLoad === ENetworkRequestStatus.PENDING}
          disabled={Number(markupPercentage) === 0 || selectedHotels.length === 0}
          onClick={handleCreateMarkup}
        >
          Create Mark-up
        </FluidButton>
      </div>
    </StandardModal>
  );
});
