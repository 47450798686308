import { call, select, takeLatest, put } from 'redux-saga/effects';

import { makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';
import {
  createCHMarkupFailureAction,
  CreateCHMarkupRequestAction,
  createCHMarkupSuccessAction,
  CREATE_COMPANY_HOTEL_MARKUP_REQUEST,
} from '../actions';
import { travelCompanyUuidSelector } from '../selectors';

function *successNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'success' }})
  );
}

function *errorNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'error' }})
  );
}

function* postCompanyHotelMarkupSaga(action: CreateCHMarkupRequestAction) {
  try {
    const companyUuid = yield select(travelCompanyUuidSelector);
    const backendApi = makeBackendApi();
    yield call(
      backendApi.postCompanyHotelMarkups,
      companyUuid,
      Number(action.markupPercentage),
      action.hotelUuids
    );

    yield put(createCHMarkupSuccessAction());
    yield successNotification('This mark up has been created');
  } catch (e) {
    yield put(createCHMarkupFailureAction(e));
    yield errorNotification('Error while creating mark up');
  }
}

export function* watchPostCHMarkupSaga() {
  yield takeLatest(
    [CREATE_COMPANY_HOTEL_MARKUP_REQUEST],
    postCompanyHotelMarkupSaga
  );
}
