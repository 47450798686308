import { AxiosResponse } from 'axios';
import { call, select, put, takeLeading } from 'redux-saga/effects';
import {
  bootstrapAppSuccessAction,
  bootstrapAppFailureAction,
  bootstrapDynamicParametersSuccessAction,
  setMainCompanyInfoAction,
  saveBootstrapDataAction,
  bootstrapDynamicParametersFailureAction,
} from '../actions';
import { AUTH_LOG_IN_SUCCESS } from '../../auth/actions';
import { BOOTSTRAP_APP_REQUEST } from '../actions';
import { getBearerTokenFromLocalStorage } from 'services/tokenLocalStorage';
import { selectedTaSelector } from '../../agents';
import { IDynamicParametersResponse, makeParametersProviderApi } from 'services/ParametersProviderApi';
import { IMainCompanyInfoResponse, makeBackendApi } from 'services/BackendApi';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { enqueueNotification } from 'store/modules/ui';
import { getDepositStatementTotalsSaga } from 'store/modules/ledger/saga';
import { getMainCompanySelector } from '../selectors';
import { getCompanySelector, isTA } from 'store/modules/auth';
import { depositStatementTotalsRequestAction } from 'store/modules/ledger/actions';
import { fetchCompanyDataSaga, watchFetchCompanyData } from 'store/modules/companyInfo/sagas/watchFetchCompanyDataSaga';
import { fetchCompanyDataRequestAction } from 'store/modules/companyInfo';

export function* bootstrapDynamicParametersSaga() {
  try {
    const dynamicParameters: IDynamicParametersResponse = yield call(ParameterService.fetchParameters.bind(ParameterService));
    yield put(bootstrapDynamicParametersSuccessAction(dynamicParameters));
  } catch (e) {
    yield put(bootstrapDynamicParametersFailureAction(e?.response?.data?.error ?? 'Unspecified error'));
    yield put(enqueueNotification({
      message: 'Service temporary unavailable. Please try again later.',
      options: { variant: 'error' }
    }));
    throw e;
  }
}

export function* bootstrapCommonDataSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const hotelsResult: AxiosResponse = yield call(backendApi.getHotelsAsHotelNames);
    const hotels = hotelsResult.data.data;

    const countriesResult: AxiosResponse = yield call(backendApi.getBootstrapCountries);
    const countries = countriesResult.data.data;

    const extraPersonSupplementProductResult: AxiosResponse = yield call(
      backendApi.getBootstrapExtraPersonSupplementProduct
    );
    const extraPersonSupplementProduct = extraPersonSupplementProductResult.data.data;

    yield put(saveBootstrapDataAction(countries, hotels, extraPersonSupplementProduct));
  } catch (e) {
    yield put(bootstrapAppFailureAction(e));
  }
}

export function* bootstrapMainCompanyInfoSaga() {
  try {
    const backendApi = makeBackendApi();
    const mainCompanyResponse: AxiosResponse<IMainCompanyInfoResponse> = yield call(backendApi.fetchMainCompanyInfo);

    const mainCompanyInfo = mainCompanyResponse.data;
    yield put(setMainCompanyInfoAction(mainCompanyInfo));
  } catch (e) {
    return null;
  }
}

export function* bootstrapAppRequestSaga() {
  try {
    const isTaUser = yield select(isTA);
    const company = yield select(getCompanySelector);
    yield call(bootstrapDynamicParametersSaga);
    yield call(bootstrapMainCompanyInfoSaga);
    if (isTaUser && company.enableInstantBooking) {
      yield put(depositStatementTotalsRequestAction(company.uuid));
      yield put(fetchCompanyDataRequestAction(company.uuid));
    }
    const token = getBearerTokenFromLocalStorage();
    if (!token.value || token.expired) {
      return;
    }

    yield call(bootstrapCommonDataSaga);

    yield put(bootstrapAppSuccessAction());
  } catch (e) {
    yield put(bootstrapAppFailureAction(e));
  }
}

export function* watchBootstrapAppRequest() {
  yield takeLeading([BOOTSTRAP_APP_REQUEST, AUTH_LOG_IN_SUCCESS], bootstrapAppRequestSaga);
}
