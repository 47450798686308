import * as Actions from './actions';
import { ICompanyHotelMarkupDomain, initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const companyHotelMarkupReducer = (
  state: ICompanyHotelMarkupDomain = initialState,
  action: Actions.CompanyHotelMarkupAction
): ICompanyHotelMarkupDomain => {
  switch (action.type) {
    case Actions.SET_TRAVEL_COMPANY_ACTION:
      return {
        ...state,
        companyUuid: action.companyUuid,
      };

    case Actions.FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST:
      return {
        ...state,
        list: [],
        listLoad: ENetworkRequestStatus.PENDING,
        listLoadError: undefined,
      };

    case Actions.FETCH_COMPANY_HOTEL_MARKUP_LIST_SUCCESS:
      return {
        ...state,
        list: action.companyHotelMarkups,
        listLoad: ENetworkRequestStatus.SUCCESS,
        listLoadError: undefined,
      };

    case Actions.FETCH_COMPANY_HOTEL_MARKUP_LIST_FAILURE:
      return {
        ...state,
        list: [],
        listLoad: ENetworkRequestStatus.ERROR,
        listLoadError: action.error,
      };

    case Actions.FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST:
      return {
        ...state,
        productsWithCompanyHotelMarkup: [],
        productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus.PENDING,
        productsWithCompanyHotelMarkupError: undefined,
      };

    case Actions.FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_SUCCESS:
      return {
        ...state,
        productsWithCompanyHotelMarkup: action.productsWithCompanyHotelMarkup,
        productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus.SUCCESS,
        productsWithCompanyHotelMarkupError: undefined,
      };

    case Actions.FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_FAILURE:
      return {
        ...state,
        productsWithCompanyHotelMarkup: [],
        productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus.ERROR,
        productsWithCompanyHotelMarkupError: action.error,
      };

    case Actions.SET_CH_MARKUP_SORT_FIELD:
      return {
        ...state,
        sortField: action.sortField,
        sortOrder: action.sortOrder,
      };

    case Actions.OPEN_CREATE_MARKUP_MODAL:
      return {
        ...state,
        addMarkupModalOpen: true
      };

    case Actions.CLOSE_CREATE_MARKUP_MODAL:
      return {
        ...state,
        addMarkupModalOpen: false
      };

    case Actions.FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST:
      return {
        ...state,
        availableHotels: [],
        availableHotelsLoad: ENetworkRequestStatus.PENDING,
        availableHotelsError: undefined
      };

    case Actions.FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_SUCCESS:
      return {
        ...state,
        availableHotels: action.companyHotels,
        availableHotelsLoad: ENetworkRequestStatus.SUCCESS,
        availableHotelsError: undefined
      };

    case Actions.FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_FAILURE:
      return {
        ...state,
        availableHotels: [],
        availableHotelsLoad: ENetworkRequestStatus.ERROR,
        availableHotelsError: action.error
      };

    case Actions.CREATE_COMPANY_HOTEL_MARKUP_REQUEST:
      return {
        ...state,
        createMarkupLoad: ENetworkRequestStatus.PENDING,
        createMarkupError: undefined
      };

    case Actions.CREATE_COMPANY_HOTEL_MARKUP_SUCCESS:
      return {
        ...state,
        createMarkupLoad: ENetworkRequestStatus.SUCCESS,
        createMarkupError: undefined
      };

    case Actions.CREATE_COMPANY_HOTEL_MARKUP_FAILURE:
      return {
        ...state,
        createMarkupLoad: ENetworkRequestStatus.ERROR,
        createMarkupError: action.error
      };

    case Actions.SET_CH_MARKUP_EDITING_MODE:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.companyUuid === action.companyUuid && item.hotelUuid === action.hotelUuid) {
            return { ...item, isEditMode: action.isEditMode, editedValue: item.markupPercentage }
          }
          return item;
        })
      };

    case Actions.SET_CH_MARKUP_PERCENTAGE_EDITED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.companyUuid === action.companyUuid && item.hotelUuid === action.hotelUuid) {
            return { ...item, editedValue: action.editedValue }
          }
          return item;
        })
      };

    case Actions.EDIT_MARKUP_REQUEST:
      return {
        ...state,
        editMarkupLoad:  ENetworkRequestStatus.PENDING,
        editMarkupError: undefined,
      };

    case Actions.EDIT_MARKUP_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.companyUuid === action.companyUuid && item.hotelUuid === action.hotelUuid) {
            return {
              ...item,
              markupPercentage: action.editedValue,
              isEditMode: false,
              editedValue: ''
            }
          }
          return item;
        }),
        bulkEdit: null,
        editMarkupLoad: ENetworkRequestStatus.SUCCESS,
        editMarkupError: undefined,
      };

    case Actions.EDIT_MARKUP_FAILURE:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.companyUuid === action.companyUuid && item.hotelUuid === action.hotelUuid) {
            return {
              ...item,
              isEditMode: false,
              editedValue: '',
            }
          }
          return item;
        }),
        bulkEdit: null,
        editMarkupLoad: ENetworkRequestStatus.ERROR,
        editMarkupError: action.error,
      };

    case Actions.OPEN_DELETE_MODAL:
      return {
        ...state,
        deleteError: undefined,
        chMarkupToDelete: {
          companyUuid: action.companyUuid,
          hotelUuid: action.hotelUuid,
        }
      };

    case Actions.CLOSE_DELETE_MODAL:
      return {
        ...state,
        chMarkupToDelete: undefined
      };

    case Actions.DELETE_MARKUP_REQUEST:
      return {
        ...state,
        deleteLoad: ENetworkRequestStatus.PENDING,
        deleteError: undefined,
      };

    case Actions.DELETE_MARKUP_SUCCESS:
      return {
        ...state,
        deleteLoad: ENetworkRequestStatus.SUCCESS,
        chMarkupToDelete: undefined,
        deleteError: undefined,
        bulkDelete: null
      };

    case Actions.DELETE_MARKUP_FAILURE:
      return {
        ...state,
        deleteLoad: ENetworkRequestStatus.ERROR,
        chMarkupToDelete: undefined,
        deleteError: action.error,
        bulkDelete: null
      };

    case Actions.OPEN_BULK_EDIT_MODAL:
      return {
        ...state,
        bulkEdit: {
          editedValue: ''
        }
      };

    case Actions.SET_BULK_EDIT_VALUE:
      return {
        ...state,
        bulkEdit: {
          ...state.bulkEdit,
          editedValue: action.editedValue
        }
      };

    case Actions.CLOSE_BULK_EDIT_MODAL:
      return {
        ...state,
        bulkEdit: null
      };

    case Actions.OPEN_BULK_DELETE_MODAL:
      return {
        ...state,
        bulkDelete: {}
      };

    case Actions.CLOSE_BULK_DELETE_MODAL:
      return {
        ...state,
        bulkDelete: null
      };

    case Actions.TOGGLE_MARKUP:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.hotelUuid === action.hotelUuid) {
            return {
              ...item,
              selected: !item.selected
            }
          }
          return item;
        }),
      };

    case Actions.TOGGLE_ALL_MARKUPS:
      const selected = state.list.filter(x => x.selected);
      const value = state.list.length !== selected.length;

      return {
        ...state,
        list: state.list.map(item => {
          return {
            ...item,
            selected: value
          };
        }),
      };

    default:
      return state;
  }
};

export default companyHotelMarkupReducer;
