import React, { useEffect, useCallback, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCurrentUserType, isAdmin as isAdminSelector, isSRRole } from 'store/modules/auth';
import { Formik, FormikProps, Field, FormikBag } from 'formik';

import {
  ENetworkRequestStatus,
  ETravelAgentStatus,
  EUserType,
  ICompany,
  ISalesRepresentative,
  ITravelAgent,
} from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import FormLabel from 'ui/FormLabel';
import { TextInput } from 'ui/TextInput';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';

import { titleOptions } from 'utils/titles';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { getTravelAgentFormValidationSchema } from './formValidation';
import { countryOptions, ETravelAgentFormMode } from './helpers';
import { companySelectOptionsSelector, getCompaniesRequestAction } from 'store/modules/agents';
import {
  taCompaniesModalVisibleSelector,
  travelAgentCreateRequestSelector,
  travelAgentUpdateRequestSelector,
} from 'store/modules/travelAgent/selectors';
import {
  createTravelAgentRequestAction,
  initTaSaveStatusAction,
  setSrAssignmentsRequestAction,
  setTaCompaniesModalVisibleAction,
  updateTravelAgentRequestAction,
} from 'store/modules/travelAgent/actions';
import { TravelAgentSettingsTabs } from '../Edit/TravelAgentSettingsTabs';
import { getSRListRequestAction } from 'store/modules/sr/actions';
import {
  getEnabledNotificationsRequestAction,
  getPossibleNotificationsRequestAction,
  resetNotificationsAction,
} from 'store/modules/notifications/actions';
import { areNotificationsLoadingSelector, notificationsSelector } from 'store/modules/notifications/selectors';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { INotification } from 'services/BackendApi/types/Notification';
import MagnifyingGlassIcon from 'ui/Icons/magnifying-glass.component.svg';
import { TravelCompaniesListModal } from 'ui/TravelCompaniesListModal';
import { theme } from '../../../../tailwind.config';
import { EUITextAreaResizeType, UITextArea } from 'ui/UITextArea';
import { UserStatusSelect } from 'ui/UserStatusSelect';
import { SlideToggle } from 'ui/SlideToggle';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { SvgIcon } from 'ui/SvgIcon';
import { InfoTransparentIcon } from 'ui/Icons/components/InfoTransparent.component';

export interface ITravelAgentFormProps {
  travelAgent?: ITravelAgent;
}

const companyRoleOptions: ISingleSelectOption[] = [
  {
    label: 'Owner',
    value: 'Owner',
  },
  {
    label: 'Board',
    value: 'Board',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Reservations',
    value: 'Reservations',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const titleOptionsWithoutNoTitle = titleOptions.slice(1);

export interface ITravelAgentFormValues {
  uuid: string | null;
  title: string;
  taFirstName: string;
  taLastName: string;
  countryCode: string | null;
  companyUuid: string | null;
  companyRole: string;
  taEmail: string;
  taPassword: string;
  phoneNumber: string | null;
  mobileNumber: string | null;
  status: ETravelAgentStatus | null;
  type: EUserType | null;
  comment: string | null;
  isExistingPartner: boolean;
  companyBookingManager: boolean;
  companyTaManager: boolean;
  canUseApp: boolean;
  taCanReadFinance: boolean;
  createdBy?: ISalesRepresentative;
}

const buildTravelAgentDataBasedOnFormValues = (
  formValues: ITravelAgentFormValues,
  userType: EUserType,
  mode: ETravelAgentFormMode
): Partial<ITravelAgent> => {
  if (userType === EUserType.SR && mode === ETravelAgentFormMode.EDIT) {
    return {
      comment: formValues.comment || '',
      isExistingPartner: formValues.isExistingPartner || false,
      companyBookingManager: formValues.companyBookingManager || false,
      companyTaManager: formValues.companyTaManager || false,
      taCanReadFinance: formValues.taCanReadFinance || false,
    };
  }

  const newTravelAgentData: Partial<ITravelAgent> = {
    title: formValues.title,
    firstName: formValues.taFirstName ?? '',
    lastName: formValues.taLastName,
    countryCode: formValues.countryCode ?? undefined,
    companyRole: formValues.companyRole || '',
    email: formValues.taEmail,
    phoneNumber: formValues.phoneNumber || null,
    mobileNumber: formValues.mobileNumber || null,
    status: formValues.status ?? undefined,
    type: EUserType.TA,
    comment: formValues.comment || '',
    isExistingPartner: formValues.isExistingPartner || false,
    companyBookingManager: formValues.companyBookingManager || false,
    companyTaManager: formValues.companyTaManager || false,
    canUseApp: formValues.canUseApp || false,
    taCanReadFinance: formValues.taCanReadFinance || false,
  };
  if (formValues.companyUuid) {
    newTravelAgentData.companyUuid = formValues.companyUuid;
  }
  if (formValues.taPassword) {
    newTravelAgentData.password = formValues.taPassword;
  }

  return newTravelAgentData;
};

export const TravelAgentForm: React.FC<ITravelAgentFormProps> = React.memo(({ travelAgent }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const travelCompaniesSelectOptions = useSelector(companySelectOptionsSelector);
  const travelAgentCreateNetwork = useSelector(travelAgentCreateRequestSelector);

  const travelAgentUpdateNetwork = useSelector(travelAgentUpdateRequestSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSR = useSelector(isSRRole);
  const userType = useSelector(getCurrentUserType) as EUserType;
  const taCompaniesModalVisible = useSelector(taCompaniesModalVisibleSelector);
  const mode = travelAgent?.uuid ? ETravelAgentFormMode.EDIT : ETravelAgentFormMode.CREATE;
  const areFieldsDisabled =
    (mode === ETravelAgentFormMode.EDIT && !isAdmin) || (mode === ETravelAgentFormMode.CREATE && !isAdmin && !isSR);

  const userWithoutCompany = !!!travelAgent?.companyUuid;
  let travelCompaniesSelectOptionsSelected = travelCompaniesSelectOptions.slice(1);
  const isSaving =
    travelAgentCreateNetwork === ENetworkRequestStatus.PENDING ||
    travelAgentUpdateNetwork === ENetworkRequestStatus.PENDING;
  const isAlreadySaved = travelAgentCreateNetwork === ENetworkRequestStatus.SUCCESS;

  const [assignedSrs, setAssignedSrs] = useState(travelAgent?.assignedSalesRepresentatives ?? []);
  const [isAssignedSrsChanged, setAssignedSrsChanged] = useState(false);

  const initialNotifications = useSelector(notificationsSelector);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [isNotificationsChanged, setNotificationsChanged] = useState(false);
  const isLoading = useSelector(areNotificationsLoadingSelector);

  useLayoutEffect(() => {
    dispatch(initTaSaveStatusAction());
    dispatch(getCompaniesRequestAction());
    dispatch(getSRListRequestAction());
    dispatch(getPossibleNotificationsRequestAction(EUserType.TA));
    if (travelAgent?.uuid) {
      dispatch(getEnabledNotificationsRequestAction(travelAgent.uuid));
    }

    return () => {
      dispatch(resetNotificationsAction());
    };
  }, []);

  useEffect(() => {
    setNotifications(initialNotifications);
  }, [initialNotifications]);

  const handleSRAssignmentChange = useCallback((srs: ISalesRepresentative[]) => {
    setAssignedSrs(srs);
    setAssignedSrsChanged(true);
  }, []);

  const handleNotificationSettingsChange = useCallback((updatedNotifications: INotification[]) => {
    setNotifications(updatedNotifications);
    setNotificationsChanged(true);
  }, []);

  const showTaCompaniesModal = useCallback(() => {
    dispatch(setTaCompaniesModalVisibleAction(true));
  }, []);

  const closeTaCompaniesModal = useCallback(() => {
    dispatch(setTaCompaniesModalVisibleAction(false));
  }, []);

  let initialStatus = travelAgent?.status ?? null;
  if (!initialStatus && isSR) {
    initialStatus = ETravelAgentStatus.PENDING;
  }
  const initialValues: ITravelAgentFormValues = {
    uuid: travelAgent?.uuid ?? null,
    title: travelAgent?.title ?? titleOptionsWithoutNoTitle[0].value,
    taFirstName: travelAgent?.firstName ?? '',
    taLastName: travelAgent?.lastName ?? '',
    countryCode: travelAgent?.countryCode ?? null,
    companyUuid: travelAgent?.companyUuid ?? null,
    companyRole: travelAgent?.companyRole ?? '',
    taEmail: travelAgent?.email ?? '',
    taPassword: '',
    phoneNumber: travelAgent?.phoneNumber ?? '',
    mobileNumber: travelAgent?.mobileNumber ?? '',
    status: initialStatus,
    type: EUserType.TA,
    comment: travelAgent?.comment ?? '',
    isExistingPartner: travelAgent?.isExistingPartner ?? false,
    companyBookingManager: travelAgent?.companyBookingManager ?? false,
    companyTaManager: travelAgent?.companyTaManager ?? false,
    canUseApp: travelAgent?.canUseApp ?? false,
    taCanReadFinance: travelAgent?.taCanReadFinance ?? false,
    createdBy: travelAgent?.createdBy,
  };

  const handleCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFormSubmit = useCallback(
    (
      formValues: ITravelAgentFormValues,
      formikBag: FormikBag<FormikProps<ITravelAgentFormValues>, ITravelAgentFormValues>
    ) => {
      const newTravelAgentData: Partial<ITravelAgent> = buildTravelAgentDataBasedOnFormValues(
        formValues,
        userType,
        mode
      );
      setAssignedSrsChanged(false);
      setNotificationsChanged(false);
      formikBag.setFieldValue('taPassword', '');
      formikBag.setFieldTouched('taPassword', false, false);
      const assignedSrUuids = assignedSrs.map(item => item.uuid);

      if (travelAgent) {
        dispatch(
          updateTravelAgentRequestAction(
            travelAgent.uuid,
            newTravelAgentData,
            notifications,
            userType,
            initialValues as Partial<ITravelAgent>
          )
        );
        if (isAdmin) {
          dispatch(setSrAssignmentsRequestAction(travelAgent.uuid, assignedSrUuids));
        }
      } else {
        dispatch(createTravelAgentRequestAction(newTravelAgentData, assignedSrUuids, notifications, history));
      }
    },
    [dispatch, travelAgent, history, assignedSrs, notifications, mode]
  );

  if (isLoading) {
    return (
      <div className="travel-company-form">
        <div className="mt-5">
          <LoadingBar label="Loading..." />
        </div>
      </div>
    );
  }

  return (
    <div className="travel-company-form">
      <p className="mt-5 mb-0 font-pt-sans text-black-true font-bold text-17px leading-22px">Agent Info</p>
      <Formik
        initialValues={initialValues}
        validationSchema={getTravelAgentFormValidationSchema(mode)}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<ITravelAgentFormValues>) => {
          const onFieldChange = (fieldName: string, fieldValue: boolean) => {
            form.setFieldValue(fieldName, fieldValue);
          };

          const onCompanySelect = (company: ICompany | undefined) => {
            form.setFieldValue('companyUuid', company?.uuid);
          };

          const canUseAppIsEnabled = form.values.status === ETravelAgentStatus.VERIFIED;

          return (
            <form onSubmit={form.handleSubmit} autoComplete="off">
              <div className="travel-agent-form">
                <div className="first-row flex gap-20px mt-5">
                  <Field name="title">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="title"
                        label="Title"
                        className="title w-150px"
                        value={value ?? titleOptionsWithoutNoTitle[0].value}
                        showSelectedOption
                        disabled={areFieldsDisabled}
                        options={titleOptionsWithoutNoTitle}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </Field>

                  <Field
                    as={TextInput}
                    type="text"
                    name="taFirstName"
                    id="taFirstName"
                    className="taFirstName w-200px"
                    label="First Name"
                    disabled={areFieldsDisabled}
                    errorMessage={form.touched.taFirstName && form.errors.taFirstName ? form.errors.taFirstName : null}
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="taLastName"
                    id="taLastName"
                    className="taLastName w-200px"
                    label="Last Name"
                    disabled={areFieldsDisabled}
                    errorMessage={form.touched.taLastName && form.errors.taLastName ? form.errors.taLastName : null}
                    errorClassName="lastName-error"
                  />

                  <Field name="countryCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="country"
                        label="Country"
                        className="country w-200px"
                        value={value}
                        options={countryOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        showSelectedOption
                        maxVisibleItems={10}
                        errorMessage={
                          form.touched.countryCode && form.errors.countryCode ? form.errors.countryCode : null
                        }
                        disabled={areFieldsDisabled}
                        errorClassName="countryCode-error"
                      />
                    )}
                  </Field>

                  <div className="companyUuid-wrapper relative">
                    <Field name="companyUuid">
                      {({ field: { name, value }, form: { setFieldValue } }) => {
                        const companySelectOptions: ISingleSelectOption[] = travelCompaniesSelectOptionsSelected.map(
                          item => ({
                            label: item.label,
                            value: item.value ?? undefined,
                          })
                        );
                        return (
                          <SingleSelect
                            fieldId="companyUuid"
                            label="Travel Company"
                            className="companyUuid w-280px"
                            value={value}
                            showSelectedOption
                            options={companySelectOptions}
                            onChange={value => {
                              setFieldValue(name, value ?? null); // Undefined fallback to null, Formik/Yup only sees null as 'not filled'.
                            }}
                            maxVisibleItems={6}
                            disabled={areFieldsDisabled}
                            errorMessage={
                              form.touched.companyUuid && form.errors.companyUuid ? form.errors.companyUuid : null
                            }
                            errorClassName="companyUuid-error"
                            labelWhenNothingSelected={userWithoutCompany ? 'Select ...' : undefined}
                            useCustomLabelWhenNothingSelected={userWithoutCompany}
                          />
                        );
                      }}
                    </Field>
                    {isAdmin && (
                      <div
                        className="company-select flex items-center cursor-pointer absolute right-0 top-0"
                        onClick={showTaCompaniesModal}
                      >
                        <MagnifyingGlassIcon
                          className="block mx-auto relative top-1px w-[20px]"
                          fill={theme.colors['brown-100']}
                        />
                        <span className="font-pt-sans text-13px leading-17px underline text-brown-100 ml-3px hover:no-decoration active:no-decoration">
                          Select from list
                        </span>
                      </div>
                    )}
                  </div>

                  <Field name="companyRole">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="companyRole"
                        label="Company Role"
                        className="companyRole w-150px"
                        value={value}
                        showSelectedOption
                        options={companyRoleOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={areFieldsDisabled}
                      />
                    )}
                  </Field>
                </div>

                <div className="second-row flex gap-20px mt-5">
                  <Field
                    as={TextInput}
                    type="text"
                    name="mobileNumber"
                    id="mobile"
                    className="mobile w-150px"
                    label="Mobile Phone"
                    disabled={areFieldsDisabled}
                    errorMessage={
                      form.touched.mobileNumber && form.errors.mobileNumber ? form.errors.mobileNumber : null
                    }
                    errorClassName="mobile-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="phoneNumber"
                    id="landline"
                    className="landline w-150px"
                    label="Landline"
                    disabled={areFieldsDisabled}
                    errorMessage={form.touched.phoneNumber && form.errors.phoneNumber ? form.errors.phoneNumber : null}
                    errorClassName="landline-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="taEmail"
                    id="taEmail"
                    className="taEmail w-200px"
                    label="Email"
                    disabled={areFieldsDisabled}
                    errorMessage={form.touched.taEmail && form.errors.taEmail ? form.errors.taEmail : null}
                    errorClassName="email-error"
                    autoComplete="off"
                    data-lpignore="true"
                  />

                  {(isAdmin || mode === ETravelAgentFormMode.EDIT) && (
                    <Field name="status">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <UserStatusSelect
                          className="status w-200px"
                          disabled={areFieldsDisabled}
                          value={value}
                          allowedStatuses={[
                            ETravelAgentStatus.PENDING,
                            ETravelAgentStatus.ACCEPTED,
                            ETravelAgentStatus.VERIFIED,
                            ETravelAgentStatus.REJECTED,
                          ]}
                          label="Status"
                          onChange={value => {
                            setFieldValue(name, value);
                            if (value !== ETravelAgentStatus.VERIFIED) {
                              setFieldValue('canUseApp', false);
                            }
                          }}
                          errorMessage={form.touched.status && form.errors.status ? form.errors.status : null}
                          errorClassName="status-error"
                        />
                      )}
                    </Field>
                  )}

                  {isAdmin && (
                    <Field name="canUseApp">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <div className="flex flex-col">
                          <label
                            className="flex text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[10px] gap-[5px]"
                            htmlFor="access-to-app-toggle"
                          >
                            <span>Access To App</span>
                            <HidingTextTooltip
                              tooltipContent="Allows Verified TA to access to APP. The user receives an invitation to Sign-in with the credentials." 
                              width="193px"
                              position="right-bottom"
                              tooltipContentClassname="px-[8px] py-5px"
                            >
                              <span className="cursor-pointer flex">
                                <SvgIcon
                                  IconComponent={InfoTransparentIcon}
                                  defaultFill={theme.colors['gray-80']}
                                  hoverFill={theme.colors['gray-80']}
                                  activeFill={theme.colors['gray-80']}
                                  width="18px"
                                  height="18px"
                                />
                              </span>
                            </HidingTextTooltip>
                          </label>
                          <div className="flex items-center relative">
                            <span className="font-pt-sans text-black text-16px">
                              No
                            </span>
                            <SlideToggle
                              className="access-to-app-toggle mx-2 relative top-[1px]"
                              checked={value}
                              id="access-to-app-toggle"
                              disableToggling={!canUseAppIsEnabled}
                              labelClassName={value ? 'bg-teal-40' : 'bg-gray-20'}
                              buttonClassName={value ? 'bg-teal-100' : 'bg-gray-40'}
                              onActivate={() => {
                                setFieldValue(name, true);
                              }}
                              onDeactivate={() => {
                                setFieldValue(name, false);
                              }}
                            />
                            <span className="font-pt-sans text-black text-16px">
                              Yes
                            </span>
                          </div>
                        </div>
                      )}
                    </Field>
                  )}

                  {isAdmin && travelAgent?.newlyCreatedTemporaryPassword && (
                    <Field name="temporayPassword">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <div className="flex flex-col">
                          <label
                            className="flex text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[10px] gap-[5px]"
                            htmlFor="temp-password-show"
                          >
                            <span>New Temporary Password</span>
                          </label>
                          <div className="flex items-center relative">
                            <span className="font-pt-sans text-black text-16px">
                              {travelAgent?.newlyCreatedTemporaryPassword}
                            </span>
                          </div>
                        </div>
                      )}
                    </Field>
                  )}
                </div>
                <div className="third-row flex flex-col mt-5">
                  <Field name="comment">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <FormLabel className="comment-label" text="Comment">
                        <UITextArea
                          className="comment"
                          value={value}
                          id="comment"
                          resizable={EUITextAreaResizeType.BOTH}
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                        />
                      </FormLabel>
                    )}
                  </Field>
                  {isSR && mode === ETravelAgentFormMode.CREATE && (
                    <p className="comment-explanation font-pt-sans text-13px leading-17px tracking-2xs text-gray-100 mt-5px mb-0">
                      If the TA belongs to a new Travel Company, provide the following information: Name, Country,
                      Address, Phone, Website, Marketing Source, Type.
                    </p>
                  )}

                  {(isAdmin || mode === ETravelAgentFormMode.EDIT) && (
                    <TravelAgentSettingsTabs
                      travelAgentFormData={buildTravelAgentDataBasedOnFormValues(form.values, userType, mode)}
                      assignedSrs={assignedSrs}
                      notifications={notifications}
                      onChange={onFieldChange}
                      onSRAssignmentChange={handleSRAssignmentChange}
                      onNotificationSettingsChange={handleNotificationSettingsChange}
                    />
                  )}

                  <div className="flex items-center mt-5">
                    {!form.values.uuid && (
                      <FluidButton
                        className="cancel-button mr-10px"
                        onClick={handleCancelButton}
                        type="secondary"
                        disabled={isSaving || isAlreadySaved}
                      >
                        Cancel
                      </FluidButton>
                    )}

                    <FluidButton
                      type="primary"
                      className="confirm-button"
                      submit
                      disabled={
                        !(
                          (form.dirty || isAssignedSrsChanged || isNotificationsChanged) &&
                          !isAlreadySaved &&
                          !isSaving
                        )
                      }
                      isLoading={isSaving}
                    >
                      Save
                    </FluidButton>
                  </div>
                </div>
              </div>
              <LeaveWithoutSavingModal
                title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
                when={(form.dirty || isAssignedSrsChanged || isNotificationsChanged) && !isAlreadySaved && !isSaving}
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
              />
              {taCompaniesModalVisible && (
                <TravelCompaniesListModal
                  companyName={travelAgent?.companyUuid ? '' : travelAgent?.companySignupInfo?.name}
                  countryCode={form.values.countryCode || travelAgent?.companySignupInfo?.countryCode}
                  selectedCompanyUuid={form.values.companyUuid}
                  onCompanySelect={onCompanySelect}
                  onClose={closeTaCompaniesModal}
                />
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
});
