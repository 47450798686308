import { createSelector } from 'reselect';
import {
  CHMarkupSortField,
  CHMarkupSortOrder,
  ICompanyHotelMarkupDomain,
  ICompanyHotel,
  ICompanyHotelMarkupModel,
  ChMarkupToDelete,
} from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const domainSelector = (state: any) => state.companyHotelMarkup;

export const travelCompanyUuidSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): string | null => domain.companyUuid
);

export const companyHotelMarkupListSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ICompanyHotelMarkupModel[] => domain.list
);

export const companyHotelMarkupListLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ENetworkRequestStatus => domain.listLoad
);

export const companyHotelMarkupListErrorSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): Error | undefined => domain.listLoadError
);

export const companyHotelMarkupSortFieldSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): CHMarkupSortField | undefined => domain.sortField
);

export const companyHotelMarkupSortOrderSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): CHMarkupSortOrder | undefined => domain.sortOrder
);

export const addMarkupModalStateSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): boolean => domain.addMarkupModalOpen
)

export const companyHotelWithoutMarkupSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ICompanyHotel[] => domain.availableHotels
);

export const createMarkupLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ENetworkRequestStatus => domain.createMarkupLoad
);

export const chmDeleteLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ENetworkRequestStatus => domain.deleteLoad
);

export const chmDeleteErrorSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): Error | undefined => domain.deleteError
);

export const chmMarkupToDeleteSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ChMarkupToDelete | undefined => domain.chMarkupToDelete
);

export const chmEditLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ENetworkRequestStatus => domain.editMarkupLoad
);

export const availableHotelsLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain): ENetworkRequestStatus => domain.availableHotelsLoad
);

export const bulkEditSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.bulkEdit
);

export const bulkDeleteSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.bulkDelete
);

export const selectedSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.list.filter(x => x.selected)
);

export const productsWithCompanyHotelMarkupSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.productsWithCompanyHotelMarkup
);

export const productsWithCompanyHotelMarkupLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.productsWithCompanyHotelMarkupLoad
);

export const productsWithCompanyHotelMarkupErrorSelector = createSelector(
  domainSelector,
  (domain: ICompanyHotelMarkupDomain) => domain.productsWithCompanyHotelMarkupError
);
