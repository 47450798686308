import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAncillaryData, IHeadlineLineItemBreakdownLineItem, makeBookingManagerApi } from 'services/BookingManagerApi';
import { formatDateDisplay, getCurrencyBySymbol } from 'utils';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { TCountryCode } from 'interfaces';
import { isNil } from 'lodash-es';
import { IHeadlineLineItemBreakdownComponent } from '../../ui/HeadlineLineItemBreakdown';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  AncillaryFilter,
  EAncillaryLocationType,
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  LocationDirectionWithCountry,
  LocationFromSearchLocationsWithType,
  isAncillaryRatedResultPerUnit,
  EAncillaryProductType,
  SelectedAncillarySearchResult,
  isAncillaryRatedResult,
  EAncillaryTransferTripType,
} from 'services/BookingManagerApi/types/AncillaryService';
import { AddTransferModal } from 'ui/AddTransferModal/AddTransferModal';
import _ from 'lodash-es';
import {
  adjustInboundOutboundForReturns,
  getErrorIdsMissingFlexibleAttributes,
  generateBreakdownItemDataForAncillaryProduct,
  generateBreakdownItemDataForAncillaryTransfers,
} from 'ui/AddAncillarySharedComponents/utils';
import * as uuid from 'uuid';

export interface IAddTransferModalContainerProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

export interface iAddTransferModalCustomData {
  pickupFrom: string | null;
  dropOff: string | null;
}
export const AddTransferModalContainer = (props: IAddTransferModalContainerProps) => {
  const isTransferModalOpen = useSelector(BreakdownSelectors.breakdownAncillaryTransferModalToggleSelector);
  const dispatch = useDispatch();
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const bookingManagerApi = makeBookingManagerApi();
  const existingCancellationPolicies = useSelector(BreakdownSelectors.cancellationPoliciesSelector);
  const existingPaymentTerms = useSelector(BreakdownSelectors.paymentTermsSelector);
  const currencyData = getCurrencyBySymbol(props.bookingCurrencySymbol!);
  const currencyCode = currencyData?.code;

  const [pickupFromLocation, setPickupFromLocation] = React.useState<LocationDirectionWithCountry | null>(null);
  const [dropOffLocation, setDropOffLocation] = React.useState<LocationDirectionWithCountry | null>(null);
  const [locations, setLocations] = React.useState<LocationFromSearchLocationsWithType[]>([]);

  const [dates, setDates] = React.useState<string[]>([]);

  const [filters, setFilters] = useState<AncillaryFilter[]>([]);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>(['-1']); // defaults to -1 to represent "All"
  const [selectedSearchResult, setSelectedSearchResult] = useState<SelectedAncillarySearchResult | null>(null);

  const [guestAges, setGuestAges] = useState<{ numberOfAdults: number; agesOfChildren: number[] }>({
    numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
    agesOfChildren: topNavigationData!.childrenAges,
  });

  const [selectedTransferOrSupplier, setSelectedTransferOrSupplier] = useState<{
    id: string;
    type: 'transfer' | 'supplier';
  } | null>(null);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [errorIds, setErrorIds] = useState<string[]>([]);

  const [tripType, setTripType] = useState<EAncillaryTransferTripType>(EAncillaryTransferTripType.ONE_WAY);

  // the pickup time on the right hand panel
  const [pickupTime, setPickupTime] = useState<string>('');
  const [returnTime, setReturnTime] = useState<string>('');

  const [getSearchResultsNetworkStatus, setGetSearchResultsNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getFiltersNetworkStatus, setGetFiltersNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getTransferProductNetwork, setGetTransferProductNetwork] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  const [getLocationsNetwork, setGetLocationsNetwork] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const locationOptions = locations.map(l => {
    return {
      value: l.id.toString(),
      label: `${l.name}${l.iata_code ? ` (${l.iata_code})` : ''}`,
    };
  });
  // we need state variables for the given instance of a search
  const [searchInstanceVars, setSearchInstanceVars] = useState<{
    dates?: string[];
    guestAges?: { numberOfAdults: number; agesOfChildren: number[] };
    pickupFromLocation?: LocationDirectionWithCountry | null;
    dropOffLocation?: LocationDirectionWithCountry | null;
  }>({});

  // this is the one that comes down from the API, and is the one that we'll be updating
  const [selectedTransferProduct, setSelectedTransferProduct] = useState<AncillaryProductWithRatesWithUserData | null>(
    null
  );

  // we're doing a custom transfer if any of the locations are custom locations
  const [customTransferData, setCustomTransferData] = useState<iAddTransferModalCustomData>({
    pickupFrom: null,
    dropOff: null,
  });

  // we mount open modal, we need to get the locations that are going to power the pickup and dropoff
  useEffect(() => {
    async function fetchLocations() {
      setGetLocationsNetwork(ENetworkRequestStatus.PENDING);
      try {
        const c = await bookingManagerApi.postAncillaryLocationsSearch(EAncillaryProductType.TRANSFER);
        setLocations([
          ...c.data.airports.map(x => ({ ...x, type: EAncillaryLocationType.AIRPORT })),
          ...c.data.hotels.map(x => ({ ...x, type: EAncillaryLocationType.HOTEL })),
          ...c.data.places.map(x => ({ ...x, type: EAncillaryLocationType.PLACE })),
        ]);
        setGetLocationsNetwork(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setLocations([]);
        setGetLocationsNetwork(ENetworkRequestStatus.ERROR);
      }
    }
    if (isTransferModalOpen) {
      fetchLocations();
    }
  }, [isTransferModalOpen]);

  // when we change the pickup or drop off location, get new filter ids
  useEffect(() => {
    async function fetchFilterCodes() {
      if (isNil(pickupFromLocation) || isNil(dropOffLocation)) {
        return;
      }
      setGetFiltersNetworkStatus(ENetworkRequestStatus.PENDING);
      const countryCodes: TCountryCode[] = [];
      if (pickupFromLocation.countryCode) {
        countryCodes.push(pickupFromLocation.countryCode);
      }
      if (dropOffLocation.countryCode) {
        countryCodes.push(dropOffLocation.countryCode);
      }
      try {
        const r = await bookingManagerApi.getAncillaryTransfersFilterIds({
          currencyCode,
          countryCodes,
        });
        setFilters(r.data);
        setGetFiltersNetworkStatus(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setFilters([]);
        setGetFiltersNetworkStatus(ENetworkRequestStatus.ERROR);
      }
    }

    fetchFilterCodes();
  }, [pickupFromLocation, dropOffLocation]);

  const handleSearchButtonClick = async () => {
    setGetSearchResultsNetworkStatus(ENetworkRequestStatus.PENDING);
    setSelectedSearchResult(null);
    if (isNil(dates) || isNil(pickupFromLocation) || isNil(dropOffLocation)) {
      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.IDLE);
      setSelectedSearchResult(null);
      return;
    }

    try {
      const searchResults = await bookingManagerApi.getAncillaryTransfersSearchResults({
        bookingUuid: props.bookingUuid,
        dates,
        numberOfAdults: guestAges.numberOfAdults,
        agesOfAllChildren: guestAges.agesOfChildren,
        transferLocations: {
          from: pickupFromLocation,
          to: dropOffLocation,
        },
        isReturn: tripType === EAncillaryTransferTripType.RETURN ? true : undefined,
        filterValueIds: selectedFilterIds.map(f => parseInt(f)),
        supplierId:
          selectedTransferOrSupplier?.type === 'supplier' ? parseInt(selectedTransferOrSupplier.id) : undefined,
        baseProductId:
          selectedTransferOrSupplier?.type === 'transfer' ? parseInt(selectedTransferOrSupplier.id) : undefined,
      });

      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.SUCCESS);
      setSearchResults(searchResults.data.results);
      setSearchInstanceVars({
        dates,
        guestAges,
        pickupFromLocation,
        dropOffLocation,
      });
    } catch (e) {
      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.ERROR);
      setSearchResults([]);
    }
  };

  const handleCloseModal = () => {
    dispatch(BreakdownActions.setAddAncillaryTransferModalToggleAction(false));
    // also reset all the data
    setDates([]);
    setPickupFromLocation(null);
    setDropOffLocation(null);
    setSelectedFilterIds(['-1']);
    setSelectedTransferOrSupplier(null);
    setSearchResults([]);
    setSelectedSearchResult(null);
    setGetSearchResultsNetworkStatus(ENetworkRequestStatus.IDLE);
    setPickupTime('');
    setReturnTime('');
    setTripType(EAncillaryTransferTripType.ONE_WAY);
    setCustomTransferData({
      pickupFrom: null,
      dropOff: null,
    });
    setGuestAges({
      numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
      agesOfChildren: topNavigationData!.childrenAges,
    });
    setErrorIds([]);
    setSelectedTransferProduct(null);
    setGetTransferProductNetwork(ENetworkRequestStatus.IDLE);
  };

  const handleSetSelectedActivityOrSupplier = d => {
    if (isNil(d)) {
      setSelectedTransferOrSupplier(null);
      return;
    }
    setSelectedTransferOrSupplier({ id: d.id, type: d.type });
  };

  const handleTransferOrSupplierSelectOnLookup = async (searchTerm: string, signal: AbortSignal) => {
    if (isNil(pickupFromLocation) || isNil(dropOffLocation) || isNil(dates)) {
      return [];
    }
    const as = await bookingManagerApi.getAncillaryTransfersTransferOrSupplier({
      currencyCode,
      searchTerm,
      signal,
      date: dates[0],
      transferLocations: {
        from: pickupFromLocation,
        to: dropOffLocation,
      },
    });

    const options: IMultiselectValueLabelPair[] = [];

    as.data.products.forEach(p => {
      options.push({ value: `activity-${p.id.toString()}`, label: p.name });
    });

    as.data.suppliers.forEach(s => {
      options.push({ value: `supplier-${s.id.toString()}`, label: s.name });
    });

    return options;
  };

  // retrieves the activity product for the current state
  // and updates the selectedActivityProduct state as well as the
  // network state variables
  const retrieveAndUpdateSelectedProduct = async (
    selectedSearchResult: SelectedAncillarySearchResult,
    perUnitQuantityOverride?: number,
    retainUserData?: boolean
  ) => {
    if (
      isNil(searchInstanceVars.dates) ||
      isNil(searchInstanceVars.guestAges) ||
      isNil(selectedSearchResult) ||
      isNil(searchInstanceVars.pickupFromLocation) ||
      isNil(searchInstanceVars.dropOffLocation)
    ) {
      return;
    }

    setGetTransferProductNetwork(ENetworkRequestStatus.PENDING);

    try {
      const t = await bookingManagerApi.getAncillaryRatesProduct({
        bookingUuid: props.bookingUuid,
        dates: searchInstanceVars.dates,
        numberOfAdults: searchInstanceVars.guestAges.numberOfAdults,
        agesOfAllChildren: searchInstanceVars.guestAges.agesOfChildren,
        baseProductId: parseInt(selectedSearchResult!.baseProductId),
        variantId: selectedSearchResult!.variantId ? parseInt(selectedSearchResult!.variantId) : undefined,
        perUnitQuantityOverride,
        transferLocations: {
          from: searchInstanceVars.pickupFromLocation,
          to: searchInstanceVars.dropOffLocation,
        },
      });

      // the selected rate is:
      //  if we already have a selected product, its the one with the same date
      // if we dont, its the first one that has a rate
      let selectedRateDateIndex = selectedTransferProduct
        ? Object.values(t.data.rates).findIndex(r => r.date === selectedTransferProduct!.userData?.rate?.date)
        : Object.values(t.data.rates).findIndex(r => r.hasRateForDate);

      // if, after that, we still dont have a selectedRateDateIndex
      // (probably because we already had a product, but we couldn't get a matching new rate)
      // then just select the first one that does have a rate after all
      if (selectedRateDateIndex === -1) {
        selectedRateDateIndex = Object.values(t.data.rates).findIndex(r => r.hasRateForDate);
      }

      const selectedRate = Object.values(t.data.rates)[selectedRateDateIndex] as AncillaryRatedResult;

      const originalUserData = selectedTransferProduct?.userData;

      if (retainUserData && originalUserData) {
        setSelectedTransferProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            ...originalUserData,

            // this is "set unit count to perUnitQuantityOverride if we have it...
            // ...if we dont, set it to the rates quantity; if we dont have THAT,
            // ...set it to undefined"
            unitCount: perUnitQuantityOverride
              ? perUnitQuantityOverride
              : isAncillaryRatedResultPerUnit(selectedRate)
              ? selectedRate.quantity
              : undefined,

            // update the rate, but keep the original selected date
            rate: selectedRate,
          },
        });
      } else {
        setSelectedTransferProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            rate: selectedRate,
            optionSelections: {},
            unitCount: isAncillaryRatedResultPerUnit(selectedRate) ? selectedRate.quantity : undefined,
          },
        });
      }
      setGetTransferProductNetwork(ENetworkRequestStatus.SUCCESS);
    } catch (e) {
      setSelectedTransferProduct(null);
      setGetTransferProductNetwork(ENetworkRequestStatus.ERROR);
    }
  };

  const handleAddTransfer = () => {
    if (isNil(selectedTransferProduct)) {
      return;
    }
    if (!isAncillaryRatedResult(selectedTransferProduct.userData.rate!)) {
      return;
    }

    const newErrorIds = getErrorIdsMissingFlexibleAttributes(selectedTransferProduct);

    if (pickupTime === '') {
      newErrorIds.push('pickupTime');
    }

    if (tripType === EAncillaryTransferTripType.RETURN) {
      if (returnTime === '') {
        newErrorIds.push('returnTime');
      }
    }

    setErrorIds(newErrorIds);

    if (newErrorIds.length > 0) {
      return;
    }

    const pickupFromLocationName = locations.find(l => l.id === searchInstanceVars.pickupFromLocation!.id)?.name;
    const dropofflocationName = locations.find(l => l.id === searchInstanceVars.dropOffLocation!.id)?.name;

    if (isNil(pickupFromLocationName) || isNil(dropofflocationName)) {
      return;
    }

    const isReturn = tripType === EAncillaryTransferTripType.RETURN;

    let transferTitle = '';

    if (isReturn) {
      const {
        inboundInitialData,
        outboundInitialData,
        inboundAncillaryRequestData,
        outboundAncillaryRequestData,
      } = generateBreakdownItemDataForAncillaryTransfers({
        selectedTransferProduct: selectedTransferProduct!,
        guestAges,
        pickupTime,
        pickupFromLocationName,
        dropofflocationName,
        pickupFromLocation: pickupFromLocation!,
        dropOffLocation: dropOffLocation!,
        dates,
        returnTime,
        isReturn: true,
      });

      // @ts-ignore This is fine
      delete inboundInitialData.flexibleAttributesNonValidatedSelections;
      // @ts-ignore This is fine
      delete outboundInitialData.flexibleAttributesNonValidatedSelections;
      transferTitle = inboundInitialData.title;
      dispatch(
        BreakdownActions.addHeadlineLineItemAction('Transfer.items', inboundInitialData, inboundAncillaryRequestData)
      );
      dispatch(
        BreakdownActions.addHeadlineLineItemAction('Transfer.items', outboundInitialData, outboundAncillaryRequestData)
      );
    } else {
      const { inboundInitialData, inboundAncillaryRequestData } = generateBreakdownItemDataForAncillaryTransfers({
        selectedTransferProduct: selectedTransferProduct!,
        guestAges,
        pickupTime,
        pickupFromLocationName,
        dropofflocationName,
        pickupFromLocation: pickupFromLocation!,
        dropOffLocation: dropOffLocation!,
        dates,
        returnTime,
        isReturn: false,
      });
      dispatch(
        BreakdownActions.addHeadlineLineItemAction('Transfer.items', inboundInitialData, inboundAncillaryRequestData)
      );
    }

    dispatch(
      BreakdownActions.setCancellationPoliciesAction(`${
        isNil(existingCancellationPolicies) ? '' : existingCancellationPolicies
      }${
        isNil(existingCancellationPolicies) || existingCancellationPolicies === '' ? '' : '\n\n'
      }Transfer: ${transferTitle}
${selectedTransferProduct.userData.rate.rateCancellationPolicy}`)
    );
    dispatch(
      BreakdownActions.setPaymentTermsAction(`${isNil(existingPaymentTerms) ? '' : existingPaymentTerms}${
        isNil(existingPaymentTerms) || existingPaymentTerms === '' ? '' : '\n\n'
      }Transfer: ${transferTitle}
${selectedTransferProduct.userData.rate.ratePaymentTerms}`)
    );

    handleCloseModal();
  };

  const handleCustomTransferAdd = async () => {
    let customTransferPickupFrom: string | null = null;
    if (customTransferData.pickupFrom) {
      customTransferPickupFrom = customTransferData.pickupFrom;
    }
    if (pickupFromLocation) {
      // get the label of the pickup location from the locations
      customTransferPickupFrom =
        locationOptions.find(location => parseInt(location.value || '') === pickupFromLocation?.id)?.label || null;
    }
    // now do the same for dropoff
    let customTransferDropOff: string | null = null;
    if (customTransferData.dropOff) {
      customTransferDropOff = customTransferData.dropOff;
    }
    if (dropOffLocation) {
      customTransferDropOff =
        locationOptions.find(location => parseInt(location.value || '') === dropOffLocation?.id)?.label || null;
    }

    if (tripType === EAncillaryTransferTripType.ONE_WAY) {
      const tertiaryTextChunks: string[] = [];
      tertiaryTextChunks.push(formatDateDisplay(dates));
      tertiaryTextChunks.push(guestAges.numberOfAdults.toString() + ' Adults');
      if (guestAges.agesOfChildren.length >= 1) {
        tertiaryTextChunks.push(guestAges.agesOfChildren.length.toString() + ' Children');
      }
      tertiaryTextChunks.push(`From: ${customTransferPickupFrom!}`);
      tertiaryTextChunks.push(`To: ${customTransferDropOff!}`);

      dispatch(
        BreakdownActions.addHeadlineLineItemAction(
          'Transfer.items',
          {
            title: 'Custom Transfer',
            tertiaryText: tertiaryTextChunks.join(', '),
            saleCostCents: 0,
            purchaseCostCents: 0,
            selectedDate: dates[0],
          },
          undefined,
          {
            isNew: true,
            selectedDate: dates[0],
            numberOfAdults: guestAges.numberOfAdults,
            agesOfAllChildren: guestAges.agesOfChildren,
            transferLocations: {
              from: customTransferPickupFrom!,
              to: customTransferDropOff!,
            },
          }
        )
      );
    } else if (tripType === EAncillaryTransferTripType.RETURN) {
      const returnHash = uuid.v4();
      const tertiaryTextChunks: string[] = [];
      tertiaryTextChunks.push(guestAges.numberOfAdults.toString() + ' Adults');
      if (guestAges.agesOfChildren.length >= 1) {
        tertiaryTextChunks.push(guestAges.agesOfChildren.length.toString() + ' Children');
      }

      dispatch(
        BreakdownActions.addHeadlineLineItemAction(
          'Transfer.items',
          {
            title: 'Custom Transfer',
            tertiaryText:
              'Inbound, ' +
              formatDateDisplay(dates[0]) +
              ', ' +
              tertiaryTextChunks.join(', ') +
              `, From: ${customTransferPickupFrom!}, To: ${customTransferDropOff!}`,
            saleCostCents: 0,
            purchaseCostCents: 0,
            selectedDate: dates![0],
          },
          undefined,
          {
            returnHash,
            selectedDate: dates![0],
            numberOfAdults: guestAges.numberOfAdults,
            agesOfAllChildren: guestAges.agesOfChildren,
            transferLocations: {
              from: customTransferPickupFrom!,
              to: customTransferDropOff!,
            },
          }
        )
      );

      dispatch(
        BreakdownActions.addHeadlineLineItemAction(
          'Transfer.items',
          {
            title: 'Custom Transfer',
            tertiaryText:
              'Outbound, ' +
              formatDateDisplay(dates[dates.length - 1]) +
              ', ' +
              tertiaryTextChunks.join(', ') +
              `, From: ${customTransferDropOff!}, To: ${customTransferPickupFrom!}`,
            saleCostCents: 0,
            purchaseCostCents: 0,
            selectedDate: dates[dates.length - 1],
          },
          undefined,
          {
            returnHash,
            selectedDate: dates[dates.length - 1],
            numberOfAdults: guestAges.numberOfAdults,
            agesOfAllChildren: guestAges.agesOfChildren,
            transferLocations: {
              from: customTransferDropOff!,
              to: customTransferPickupFrom!,
            },
          }
        )
      );
    }

    handleCloseModal();
  };

  const handleSelectSearchResult = async (selectedSearchResult: SelectedAncillarySearchResult | null) => {
    if (isNil(selectedSearchResult)) {
      setSelectedTransferProduct(null);
      return;
    }
    setSelectedSearchResult(selectedSearchResult);
    setPickupTime('');
    await retrieveAndUpdateSelectedProduct(selectedSearchResult);
  };

  const handleUpdateActivityProductWithUserData = async (
    data: Partial<AncillaryProductWithRatesWithUserData['userData']>
  ) => {
    setSelectedTransferProduct({
      ...selectedTransferProduct!,
      userData: {
        ...selectedTransferProduct!.userData,
        ...data,
      },
    });

    // if they've just updated the unit count...
    if (!isNil(data['unitCount']) && !isNil(selectedSearchResult)) {
      // ...retrieve the product again
      await retrieveAndUpdateSelectedProduct(selectedSearchResult, data['unitCount'], true);
    }
  };

  return (
    <AddTransferModal
      isOpen={isTransferModalOpen}
      currencySymbol={props.bookingCurrencySymbol!}
      onClose={handleCloseModal}
      // pickup from and dropoff stuff
      pickupFromLocation={pickupFromLocation}
      handleSelectPickupLocation={(pickupFromLocationCompositeId: string | undefined, isCustom: boolean) => {
        if (isNil(pickupFromLocationCompositeId)) {
          setPickupFromLocation(null);
          setCustomTransferData({
            ...customTransferData,
            pickupFrom: null,
          });
          return;
        }
        if (isCustom) {
          setCustomTransferData({
            ...customTransferData,
            pickupFrom: pickupFromLocationCompositeId,
          });
          return;
        }
        const location = locations.find(l => l.id === parseInt(pickupFromLocationCompositeId));
        if (isNil(location)) {
          return;
        }
        setCustomTransferData({
          ...customTransferData,
          pickupFrom: null,
        });
        setPickupFromLocation({
          id: parseInt(pickupFromLocationCompositeId),
          type: location.type,
          countryCode: location.countryCode,
        });
      }}
      dropOffLocation={dropOffLocation}
      handleSelectDropOffLocation={(dropOffLocationCompositeId: string | undefined, isCustom: boolean) => {
        if (isNil(dropOffLocationCompositeId)) {
          setDropOffLocation(null);
          setCustomTransferData({
            ...customTransferData,
            dropOff: null,
          });
          return;
        }
        if (isCustom) {
          setCustomTransferData({
            ...customTransferData,
            dropOff: dropOffLocationCompositeId,
          });
          return;
        }
        const location = locations.find(l => l.id === parseInt(dropOffLocationCompositeId));
        if (isNil(location)) {
          return;
        }
        setCustomTransferData({
          ...customTransferData,
          dropOff: null,
        });
        setDropOffLocation({
          id: parseInt(dropOffLocationCompositeId),
          type: location.type,
          countryCode: location.countryCode,
        });
      }}
      locationOptions={locationOptions}
      // date stuff
      dates={dates}
      setDates={setDates}
      //
      getSearchResultsNetwork={getSearchResultsNetworkStatus}
      onSearchButtonClick={handleSearchButtonClick}
      searchResults={searchResults}
      selectedSearchResult={selectedSearchResult}
      setSelectedSearchResult={(selectedSearchResult: SelectedAncillarySearchResult | null) => {
        handleSelectSearchResult(selectedSearchResult);
      }}
      typesOptions={filters.map(f => {
        return {
          value: f.id.toString(),
          label: f.value,
        };
      })}
      selectedFilterIds={selectedFilterIds}
      setSelectedFilterIds={setSelectedFilterIds}
      selectedTransferOrSupplier={selectedTransferOrSupplier}
      setSelectedTransferOrSupplier={handleSetSelectedActivityOrSupplier}
      transferOrSupplierSelectOnLookup={handleTransferOrSupplierSelectOnLookup}
      guestAges={guestAges}
      searchInstanceVars={searchInstanceVars}
      setGuestAges={(a, b) => {
        setGuestAges({ numberOfAdults: a, agesOfChildren: b ? b : [] });
      }}
      getTypesResultsNetworkStatus={getFiltersNetworkStatus}
      errorIds={errorIds}
      handleAddTransfer={handleAddTransfer}
      selectedTransferProduct={selectedTransferProduct}
      updateSelectedTransferProductWithUserData={handleUpdateActivityProductWithUserData}
      getTransferProductNetwork={getTransferProductNetwork}
      pickupTime={pickupTime}
      setPickupTime={setPickupTime}
      getLocationsNetwork={getLocationsNetwork}
      customTransferData={customTransferData}
      onCustomTransferAdd={handleCustomTransferAdd}
      tripType={tripType}
      setTripType={tripType => {
        setDates([]);
        setTripType(tripType);
      }}
      returnTime={returnTime}
      setReturnTime={setReturnTime}
    />
  );
};
