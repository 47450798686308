import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  isSR as isSrSelector,
  isAdmin as isAdminSelector,
  isFinanceUser as isFinanceUserSelector,
} from 'store/modules/auth/selectors';
import { IInvoiceAddressee, IInvoiceDueDate, IPaymentMethod } from 'services/BookingManagerApi/types';
import { EBankAccount } from 'interfaces';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import {
  EInvoiceAddresseeType,
  EInvoiceType,
  EInvoiceMutationMode,
  EInvoiceLang,
} from 'store/modules/bookingManager/subdomains/breakdown/model';
import { breakdownNetworkRequestsSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { enqueueNotification } from 'store/modules/ui';
import { CancellationDueDate } from 'containers/BookingStatusCtaCollection/CancellationDueDate';
import { formatPrice, roundToDecimalPlaces, formatDate } from 'utils';
import FluidButton from 'ui/FluidButton';
import { edit } from 'ui/Icons';
import { InvoiceModalDueDateRow } from 'ui/InvoiceModalDueDateRow';
import { Multiselect } from 'ui/Multiselect';
import { IValueLabelPair } from '../../interfaces';
import { GeneralModal } from 'ui/GeneralModal';
import { ModalHeading } from 'ui/GeneralModal/ModalHeading';
import { InvoiceAddresseeText } from './InvoiceAddresseeText';
import SingleSelect from 'ui/SingleSelect';
import { VerticalSpace } from 'ui/VerticalSpace';
import { InvoiceTPOrClient } from './InvoiceTPOrClient';

const langOptions: IValueLabelPair[] = [
  { value: EInvoiceLang.EN, label: 'English' },
  { value: EInvoiceLang.AR, label: 'Arabic' },
];

export interface IInvoiceModal {
  isOpen: boolean;
  mode: EInvoiceMutationMode | null;
  invoiceType: EInvoiceType;
  invoiceAddresseeType: EInvoiceAddresseeType;
  invoiceAddressee?: IInvoiceAddressee;
  invoiceAddresseeLoad: ENetworkRequestStatus;
  selectedBankAccount: EBankAccount;
  lang: EInvoiceLang;
  initialOutstandingAmountCents: number;
  bookingCurrencySymbol: string;
  paymentTerms: string;
  invoiceDueDates: IInvoiceDueDate[];
  paymentMethods: IPaymentMethod[];
}

export const getNewAmountFromPercentageOfOriginal = (initial: number, percentage: number) => {
  return Math.round((initial / 100) * percentage);
};

// eslint-disable-next-line react/display-name
export const InvoiceModal: React.FC<IInvoiceModal> = React.memo(props => {
  const dispatch = useDispatch();
  const breakdownNetworkRequests = useSelector(breakdownNetworkRequestsSelector);

  const isSr = useSelector(isSrSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);
  const isSupervisor = isSr || isAdmin || isFinanceUser;

  const { isOpen } = props;
  const [outstandingBalanceCents, setOutstandingBalanceCents] = useState<number>(props.initialOutstandingAmountCents);
  const [invoiceDueDates, setInvoiceDueDates] = useState<IInvoiceDueDate[]>(() => {
    if (props.invoiceType === EInvoiceType.CANCELLATION && props.invoiceDueDates.length === 0) {
      return [
        {
          date: '',
          percentage: 100,
          amountCents: props.initialOutstandingAmountCents,
          isConfirmed: true,
        },
      ];
    }

    return props.invoiceDueDates;
  });

  const [isPaymentMethodDropdownOpen, setPaymentMethodDropdownOpen] = useState(false);

  const paymentMethodOptions = useMemo(() =>
    props.paymentMethods.map(x => ({
      value: x.name,
      label: x.name,
    })),
    [props.paymentMethods]
  );

  const selectedPaymentMethod = useMemo(() =>
    props.paymentMethods.find(x => x.name === props.selectedBankAccount),
    [props.paymentMethods, props.selectedBankAccount]
  );

  useEffect(() => {
    const amountLeftToCover = invoiceDueDates
      .filter(idd => idd.isConfirmed)
      .reduce((acum, curr) => {
        acum -= curr.amountCents || 0;
        return acum;
      }, props.initialOutstandingAmountCents);

    setOutstandingBalanceCents(amountLeftToCover);
  }, [invoiceDueDates, props.initialOutstandingAmountCents]);

  const handleGenerateInvoiceClick = useCallback(() => {
    dispatch(
      BreakdownActions.generateInvoiceRequestAction(props.invoiceType, props.selectedBankAccount, invoiceDueDates)
    );
  }, [dispatch, props.invoiceType, props.selectedBankAccount, invoiceDueDates]);

  const handleCloseClick = useCallback(() => {
    dispatch(BreakdownActions.closeCreateInvoiceModalAction());

    if (props.mode === EInvoiceMutationMode.UPDATE) {
      dispatch(
        enqueueNotification({
          message: 'The breakdown was not updated. Please save new invoice to confirm changes',
          options: { variant: 'warning' },
        })
      );
    }
  }, [dispatch, props.mode]);

  const handleSetBankAccount = useCallback(
    bankAccount => {
      dispatch(BreakdownActions.setInvoiceBankAccountAction(bankAccount));
    },
    [dispatch]
  );

  const handleSetTravelPartnerInvoice = useCallback(() => {
    if (props.invoiceAddresseeType !== EInvoiceAddresseeType.TRAVEL_PARTNER) {
      dispatch(BreakdownActions.setInvoiceAddresseeTypeAction(EInvoiceAddresseeType.TRAVEL_PARTNER));
      dispatch(BreakdownActions.getInvoiceAddresseeRequestAction());
    }
  }, [dispatch, props.invoiceAddresseeType]);

  const handleSetFinalClientInvoice = useCallback(() => {
    if (props.invoiceAddresseeType !== EInvoiceAddresseeType.FINAL_CLIENT) {
      dispatch(BreakdownActions.setInvoiceAddresseeTypeAction(EInvoiceAddresseeType.FINAL_CLIENT));
      dispatch(BreakdownActions.getInvoiceAddresseeRequestAction());
    }
  }, [dispatch, props.invoiceAddresseeType]);

  const handleEditAddressee = useCallback(() => {
    dispatch(BreakdownActions.openAddresseeModalAction());
  }, [dispatch]);

  const handleLang = useCallback(
    xs => {
      dispatch(BreakdownActions.setInvoiceLangAction(xs[0]));
    },
    [dispatch]
  );

  const addInvoiceDueDate = () => {
    let newInvoiceDueDates: IInvoiceDueDate[] = [...invoiceDueDates];

    // get whatever is left
    const percentageLeft = invoiceDueDates.reduce((acum, cur) => {
      acum -= cur.percentage || 0;
      return acum;
    }, 100);

    const amountCentsLeft = invoiceDueDates.reduce((acum, cur) => {
      acum -= cur.amountCents || 0;
      return acum;
    }, props.initialOutstandingAmountCents);

    newInvoiceDueDates.push({
      date: null,
      percentage: percentageLeft,
      amountCents: amountCentsLeft,
      isConfirmed: false,
    });

    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleInvoiceDueDateUpdateAmountCents = (index: number, newAmountCents: number) => {
    const newInvoiceDueDates = [...invoiceDueDates];
    const newPercentage = (newAmountCents * 100) / props.initialOutstandingAmountCents;
    newInvoiceDueDates[index].amountCents = newAmountCents;
    newInvoiceDueDates[index].percentage = roundToDecimalPlaces(newPercentage, 2);
    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleInvoiceDueDateUpdatePercentage = (index: number, percentage: number) => {
    const newInvoiceDueDates = [...invoiceDueDates];
    newInvoiceDueDates[index].percentage = percentage;
    // rounding because JS math is nonsense. see OWA-2803
    const newAmount = getNewAmountFromPercentageOfOriginal(props.initialOutstandingAmountCents, percentage);
    newInvoiceDueDates[index].amountCents = newAmount;
    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleInvoiceDueDateUpdateDate = (index: number, newDate: string) => {
    const newInvoiceDueDates = [...invoiceDueDates];
    newInvoiceDueDates[index].date = newDate;

    // we need to work out the amount for this one too
    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleInvoiceDueDateRemove = (indexToRemove: number) => {
    const newInvoiceDueDates = invoiceDueDates.filter((idd, index) => index !== indexToRemove);
    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleInvoiceDueDateConfirm = (indexToConfirm: number) => {
    const newInvoiceDueDates = [...invoiceDueDates];
    newInvoiceDueDates[indexToConfirm].isConfirmed = true;
    setInvoiceDueDates(newInvoiceDueDates);
  };

  const handleCancellationDueDateUpdateDate = (newDate: Date) => {
    handleInvoiceDueDateUpdateDate(0, formatDate(newDate));
  };

  const handleCancellationDueDateUpdatePercentage = (percentage: number) => {
    handleInvoiceDueDateUpdatePercentage(0, percentage);
  };

  const handleCancellationDueDateSetAmountCents = (amount: any) => {
    handleInvoiceDueDateUpdateAmountCents(0, parseInt(amount));
    handleInvoiceDueDateConfirm(0);
  };

  let invoiceName: string;
  switch (props.invoiceType) {
    case EInvoiceType.PROFORMA:
      invoiceName = 'proforma invoice';
      break;
    case EInvoiceType.FINAL:
    default:
      invoiceName = 'invoice';
      break;
  }

  const generateDisabled = useMemo(() => {
    if (props.invoiceType === EInvoiceType.PROFORMA) {
      return props.initialOutstandingAmountCents > 0 && (outstandingBalanceCents !== 0 || invoiceDueDates.length <= 0 || !props.selectedBankAccount);
    } else if (props.invoiceType === EInvoiceType.CANCELLATION) {
      return !invoiceDueDates[0]?.date;
    }
    return false;
  }, [invoiceDueDates, outstandingBalanceCents, props.initialOutstandingAmountCents, props.invoiceType, props.selectedBankAccount]);

  const monitorPaymentMethodDropdownStatus = useCallback((isOpen: boolean) => {
    setPaymentMethodDropdownOpen(isOpen);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal
      onClose={handleCloseClick}
      modalWindowClassName={classNames('px-35px py-25px rounded', {
        'w-[800px]': props.invoiceType !== EInvoiceType.CANCELLATION,
        'w-[778px]': props.invoiceType === EInvoiceType.CANCELLATION,
      })}
      shouldCloseByClickingOutside
    >
      <ModalHeading>Create {invoiceName}</ModalHeading>

      <InvoiceTPOrClient edit={edit}
       handleEditAddressee={handleEditAddressee} 
       handleSetFinalClientInvoice={handleSetFinalClientInvoice} 
       handleSetTravelPartnerInvoice={handleSetTravelPartnerInvoice} 
       invoiceAddresseeType={props.invoiceAddresseeType} 
       isSupervisor={isSupervisor}
       invoiceType={props.invoiceType}
       />

      <InvoiceAddresseeText
        invoiceAddressee={props.invoiceAddressee}
        invoiceAddresseeLoad={props.invoiceAddresseeLoad}
      />

      <SingleSelect
        fieldId="invoice-payment-method"
        label="Payment Method"
        className="invoice-payment-method max-w-[730px] mt-[30px]"
        labelClassName="text-[16px] leading-[21px] font-bold"
        value={props.selectedBankAccount}
        options={paymentMethodOptions}
        onChange={handleSetBankAccount}
        maxVisibleItems={5}
        notifyOpenStatus={monitorPaymentMethodDropdownStatus}
      />

      {!!selectedPaymentMethod?.warning && (
        <div className="mt-[5px] mb-5 min-h-[100px] max-h-[100px] overflow-y-scroll max-w-fit border border-solid border-gray-20 rounded p-4 font-pt-sans leading-xs text-13px text-black">
          {selectedPaymentMethod.warning}
        </div>
      )}

      {props.invoiceType === EInvoiceType.PROFORMA && (
        <>
          <p className="font-pt-sans font-bold text-base leading-sm text-black mt-[30px] mb-4">Due Dates</p>
          <div className="mb-4 font-pt-sans">
            <div className="flex justify-between items-center bg-brown-10 p-2 px-4">
              <span className="text-sm uppercase">Outstanding Balance</span>
              <span className="font-bold">
                {props.bookingCurrencySymbol}
                {formatPrice(props.initialOutstandingAmountCents / 100)}
              </span>
            </div>

            <div
              style={{
                maxHeight: '155px', // specific from designs
                overflowY: 'auto',
              }}
            >
              {invoiceDueDates.map((invoiceDueDate, index) => {
                return (
                  <InvoiceModalDueDateRow
                    className="p-2 px-4"
                    key={index}
                    index={index}
                    date={invoiceDueDate.date || null}
                    allOtherDates={invoiceDueDates
                      .filter((x, i) => i !== index && x.date !== null)
                      .map(x => x.date || '')}
                    percentage={invoiceDueDate.percentage || 0}
                    amountCents={invoiceDueDate.amountCents || 0}
                    currencySymbol={props.bookingCurrencySymbol}
                    isConfirmed={invoiceDueDate.isConfirmed}
                    onUpdateAmountCents={(index, newAmountCents) => {
                      handleInvoiceDueDateUpdateAmountCents(index, newAmountCents);
                    }}
                    onUpdatePercentage={(index, newPercent) => {
                      handleInvoiceDueDateUpdatePercentage(index, newPercent);
                    }}
                    onUpdateDate={(index, newDate) => {
                      handleInvoiceDueDateUpdateDate(index, newDate);
                    }}
                    onClickRemove={index => {
                      handleInvoiceDueDateRemove(index);
                    }}
                    onClickDelete={index => {
                      handleInvoiceDueDateRemove(index);
                    }}
                    onClickConfirm={index => {
                      handleInvoiceDueDateConfirm(index);
                    }}
                  />
                );
              })}
            </div>

            {props.initialOutstandingAmountCents > 0 && (
              <div className="flex justify-left p-2 px-4 items-center bg-brown-10">
                {
                  <FluidButton type="secondary" onClick={addInvoiceDueDate} disabled={outstandingBalanceCents <= 0}>
                    Add Due Date
                  </FluidButton>
                }
                <div className="message">
                  {props.mode === EInvoiceMutationMode.UPDATE && (
                    <div className="ml-2 text-brown-100 font-bold">
                      Due Dates have been recalculated based on previous percentages. Please confirm they are correct.
                    </div>
                  )}
                  {invoiceDueDates.length <= 0 && (
                    <span className="ml-2 text-brown-100">
                      <i className="fas fa-exclamation-circle"></i> You must add at least one Due Date to Generate
                      Proforma Invoice
                    </span>
                  )}
                  {invoiceDueDates.length >= 1 && outstandingBalanceCents > 0 && (
                    <span className="ml-2 text-brown-100">
                      <span className="font-bold">
                        {props.bookingCurrencySymbol}
                        {formatPrice(outstandingBalanceCents / 100)}
                      </span>{' '}
                      to match outstanding balance.
                    </span>
                  )}
                  {invoiceDueDates.length >= 1 && outstandingBalanceCents === 0 && (
                    <span className="ml-2 text-brown-100">
                      <span className="font-bold">100% match.</span> You can&apos;t add more Due Dates.
                    </span>
                  )}
                  {invoiceDueDates.length >= 1 && outstandingBalanceCents < 0 && (
                    <span className="ml-2 text-red-100">
                      <span className="font-bold">
                        {props.bookingCurrencySymbol}
                        {formatPrice(outstandingBalanceCents / 100)}
                      </span>
                      <span> Please delete one or more Due Dates.</span>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          {props.initialOutstandingAmountCents > 0 && (
            <div className="border border-solid border-gray-40 font-pt-sans text-[13px] leading-[18px] p-2 text-black">
              <span className="block font-bold">Payment Terms</span>
              <span className="block">{props.paymentTerms}</span>
            </div>
          )}
        </>
      )}

      {props.invoiceType === EInvoiceType.CANCELLATION && (
        <div className="mb-6">
          <p className="font-pt-sans font-bold text-base leading-sm text-black mt-[30px] mb-4">Select Due Dates</p>
          <CancellationDueDate
            bookingCurrencySymbol={props.bookingCurrencySymbol}
            dueDate={invoiceDueDates[0].date ?? ''}
            percentage={invoiceDueDates[0]?.percentage ?? 100}
            amountCents={invoiceDueDates[0]?.amountCents ?? props.initialOutstandingAmountCents}
            isConfirmed={invoiceDueDates[0]?.isConfirmed ?? true}
            initialOutstandingCents={props.initialOutstandingAmountCents}
            outstandingBalanceCents={outstandingBalanceCents}
            onChangeDate={handleCancellationDueDateUpdateDate}
            onChangePercentage={handleCancellationDueDateUpdatePercentage}
            setAmountCents={handleCancellationDueDateSetAmountCents}
          />
        </div>
      )}

      {/* Allow extra space when dropdown is open */}
      {props.invoiceType === EInvoiceType.FINAL && isPaymentMethodDropdownOpen && !selectedPaymentMethod?.warning && (
        <VerticalSpace height="106px" />
      )}
      
      <div className="flex items-center justify-between mt-[30px]">
        <FluidButton
          type="primary"
          fixedWidth="220px"
          isLoading={breakdownNetworkRequests.createInvoice === ENetworkRequestStatus.PENDING}
          onClick={handleGenerateInvoiceClick}
          disabled={generateDisabled}
        >
          Generate {invoiceName}
        </FluidButton>
        <div className="lang flex items-center">
          <div className="label font-pt-sans text-13px text-black max-w-70px">Invoice language:</div>
          <Multiselect
            className="min-w-120px bg-ivory"
            itemsClassname="bg-ivory"
            onUpdate={handleLang}
            selectedValues={[props.lang]}
            hideCheckboxes={true}
            isSingleSelectMode={true}
            isCloseOnSelect={true}
            options={langOptions}
            dropdownPosition="top"
          />
        </div>
      </div>
    </GeneralModal>
  );
});

export default InvoiceModal;
