import React, { useCallback } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { IHeadlineLineItemBreakdownLineItem } from 'services/BookingManagerApi';
import { toClassName } from './helpers';
import { ClosingProductsRow } from './ClosingProductsRow';
import { RemoveHeadlineButton, EditHeadlineButton } from './HeadlineActionButtons';
import { EmptyHeaderProducts } from './EmptyHeaderProducts';
import { formatDateDisplay } from 'utils';
import classNames from 'classnames';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { theme } from '../../../tailwind.config';
import { format } from 'date-fns';
import DateRangeInput from 'pureUi/DateRangeInput';
import { Multiselect } from 'ui/Multiselect';
import { isNil } from 'lodash-es';
import { EAncillaryProductType, SuppliersSearchItem } from 'services/BookingManagerApi/types/AncillaryService';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { ProductTypes } from 'config/enums';
// @ts-ignore Styled component errors
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
`;

interface IGenericProductsBlock {
  pathPrefix?: string;
  className?: string;
  productType: string; // this is what they get stored in the DB as e.g 'Accommodation', 'Bespoke', 'Transfer'
  productDisplayType: string; // this is what we want them to render as e.g Activity is what we render Bespoke as
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  bookingCurrencySymbol?: string | null;
  subTotalCents: number;
  includeTableHeaders?: boolean;
  allowNegative?: boolean;
  setHeadlineLineItemFieldAction: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  isPurchaseCostEditable: boolean;
  isEditable: boolean;
  cta?: React.ReactElement;
  isSR: boolean;
  isAdmin: boolean;
  isFinanceUser: boolean;
  isLiveRate?: boolean;
  titlePlaceholder?: string;
  tertiaryTextPlaceholder?: string;
  isAccommodationSubBlock?: boolean; // if this is true, we need to fire the accommodation modified when user changes prices
  suppliers: SuppliersSearchItem[];
}

// necessary because we dont have placeholder utility classes
const LineItemInput = styled.input`
  &::placeholder {
    font-style: italic;
  }
`;

const NonAncillaryProduct = ({
  lineItem,
  index,
  keyPrefix,
  isEditable,
  setHeadlineLineItemFieldActionCallback,
  pathPrefix,
  productType,
  productDisplayType,
  includeTableHeaders,
  lineItems,
  isPurchaseCostVisible,
  titlePlaceholder,
  tertiaryTextPlaceholder,
  isPurchaseCostDisabled,
  allowNegative,
  isPurchaseCostEditable,
  setIsAccommodationModifiedAction,
  isSalesCostEditable,
  removeHeadlineLineItemAction,
  suppliers,
  isAdmin,
  isSR,
  isFinanceUser,
  isTimeVisible,
}: {
  lineItem: IHeadlineLineItemBreakdownLineItem;
  index: number;
  keyPrefix: string;
  isEditable: boolean;
  setHeadlineLineItemFieldActionCallback: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  pathPrefix: string;
  productType: string;
  productDisplayType: string;
  includeTableHeaders: boolean;
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  isPurchaseCostVisible: boolean;
  titlePlaceholder: string;
  tertiaryTextPlaceholder: string;
  isPurchaseCostDisabled: boolean;
  allowNegative: boolean;
  isPurchaseCostEditable: boolean;
  setIsAccommodationModifiedAction: () => void;
  isSalesCostEditable: boolean;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  suppliers: SuppliersSearchItem[];
  isAdmin: boolean;
  isSR: boolean;
  isFinanceUser: boolean;
  isTimeVisible: boolean;
}) => {
  return (
    <React.Fragment key={`${keyPrefix}-wrapper`}>
      <tr
        key={`htop-non-ancillary-${productDisplayType}-${index}`}
        className={`title p-0 w-1/4 non-ancillary-${productDisplayType}`}
      >
        <td className="p-0 pr-2 pb-4 pt-2">
          <h2 className="text-17px text-left m-0">
            {productDisplayType} {index + 1}
          </h2>
        </td>
        <td colSpan={2} className="pl-2 pr-2 pb-4 pt-2">
          <div
            className={classNames('flex flex-col', {
              hidden: !isAdmin && !isSR && !isFinanceUser,
            })}
          >
            <span
              className={classNames('text-gray-darker text-xs uppercase font-pt-sans mb-4', {
                hidden: !includeTableHeaders || index !== 0,
              })}
            >
              Supplier
            </span>
            <Multiselect
              className="bg-white"
              itemsClassname="bg-white"
              itemCtaClassName="hover:bg-gray-10"
              options={suppliers.map(s => ({ label: s.name, value: s.id.toString() }))}
              isCloseOnSelect={true}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              onUpdate={sv => {
                if (sv.length <= 0 || isNil(sv[0])) {
                  setHeadlineLineItemFieldActionCallback(
                    `${pathPrefix}${productType}.items[${index}].supplierData`,
                    undefined
                  );
                }

                const supplierData = suppliers.find(s => s.id === parseInt(sv[0]));

                if (isNil(supplierData)) {
                  return;
                }

                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].supplierData`,
                  supplierData
                );
              }}
              selectedValues={lineItem.supplierData ? [lineItem.supplierData.id.toString()] : []}
            />
          </div>
        </td>
        <td key={`htop-non-ancillary-${productDisplayType}-${index}-col-1`} className="pl-2 pr-4 pb-4 pt-2">
          <div className="flex flex-col">
            <span
              className={classNames('text-gray-darker text-xs uppercase font-pt-sans text-right mb-4', {
                hidden: !includeTableHeaders || index !== 0,
              })}
            >
              Confirmation N.
            </span>
            <input
              id={`${keyPrefix}-${index}-confirmation-number`}
              key={`${keyPrefix}-${index}-confirmation-number`}
              placeholder={'Confirmation N.'}
              value={lineItem.itemConfirmationNumber}
              type="text"
              readOnly={!isEditable}
              className={classnames(
                'w-[130px] resort-confirmation-number-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans',
                {
                  'bg-gray-10': isEditable === false,
                }
              )}
              onChange={event => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].itemConfirmationNumber`,
                  event.target.value
                );
              }}
            />
          </div>
        </td>
      </tr>
      <tr
        key={`h-${index}`}
        className={classNames('title p-0 w-1/4', {
          hidden: index !== 0,
        })}
      >
        {includeTableHeaders && lineItems.length >= 1 && (
          <React.Fragment>
            <td className={`${productDisplayType}-name text-gray-darker text-xs uppercase text-left pr-2 font-pt-sans`}>
              <span>Name</span>
            </td>
            <td
              className={classNames(
                `${productDisplayType}-date w-14% text-gray-darker text-xs uppercase text-left pl-2 font-pt-sans`,
                {
                  'w-[30%]': productType === 'Transfer',
                }
              )}
            >
              <span>Date</span>
            </td>
            {isTimeVisible && (
              <td
                className={`${productDisplayType}-date w-14% text-gray-darker text-xs uppercase text-left pl-2 font-pt-sans`}
              >
                <span>Local time</span>
              </td>
            )}
            {isPurchaseCostVisible && (
              <td className="cost-before-discount w-14% text-gray-darker text-xs uppercase text-right p-2 font-pt-sans">
                <span>Purchase Cost</span>
              </td>
            )}
            <td
              colSpan={isPurchaseCostVisible ? 1 : 2}
              className="cost-to-ta w-14% text-gray-darker text-xs uppercase text-right pr-4 font-pt-sans"
            >
              <span>Cost to TP</span>
            </td>
          </React.Fragment>
        )}
        {isPurchaseCostVisible && <td></td>}
      </tr>
      <tr key={`r-${index}`}>
        <td className="p-0 pr-2 pb-4 pt-2">
          <LineItemInput
            key={`${keyPrefix}-${index}-title`}
            disabled={isEditable === false}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': isEditable === false,
              }
            )}
            value={lineItem.title}
            placeholder={titlePlaceholder || 'Line Item Title'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].title`,
                event.target.value
              );
            }}
          />
        </td>
        <td colSpan={isPurchaseCostVisible ? 1 : 2} className="pl-2 pr-2 pb-4 pt-2">
          <DatePickerStateProvider
            isSingleDateSelection={true}
            defaultSelectedDates={[lineItem.selectedDate || '']}
            onDateChange={dates => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].selectedDate`,
                format(new Date(dates[0]), 'yyyy-MM-dd')
              );
            }}
            placeholder="Select date"
            render={(params: IDatePickerSateParams) => {
              return (
                <StyledDateRangeInput
                  disabled={isEditable === false}
                  displayString={params.displayString}
                  currentDate={params.datePickerCurrentDate}
                  selectedDates={[...params.selectedDates]}
                  onDayClick={params.handleDayClick}
                  onDayMouseOver={params.handleDateMouseOver}
                  showDatePicker={params.showDatePicker}
                  onNextClick={params.incrementDate}
                  onPrevClick={params.decrementDate}
                  onMouseDown={params.toggleDatePicker}
                  onClickOutside={params.hideDatePicker}
                  // placeholder={'placeholder test'}
                  showTotalNights={false}
                  enablePastDates
                  noPortal
                />
              );
            }}
          />
        </td>
        {isTimeVisible && (
          <td className="p-0 px-2 pb-4 pt-2">
            <input
              onChange={e => {
                const path = !isNil(lineItem.customData) ? `${pathPrefix}${productType}.items[${index}].customData.time` : `${pathPrefix}${productType}.items[${index}].extraData.time`;
                setHeadlineLineItemFieldActionCallback(
                  path,
                  e.currentTarget.value
                );
              }}
              type="time"
              className={classNames(
                'arrival-time-input p-2 border border-solid border-gray-40 min-h-35px max-h-35px w-full'
              )}
              value={lineItem.customData?.time || lineItem.extraData?.time}
            />
          </td>
        )}

        {isPurchaseCostVisible && (
          <td className="w-14% p-0 pl-2 pr-2 pb-4 pt-2">
            {/* cost before discount price */}
            <PriceAsCentsInput
              disabled={isPurchaseCostDisabled}
              allowNegative={allowNegative}
              key={`${keyPrefix}-${index}-purchaseCostCents`}
              className={classnames(
                'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                {
                  'bg-gray-10': isPurchaseCostEditable === false,
                }
              )}
              cents={lineItem.purchaseCostCents || 0}
              onBlurUpdate={val => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                  val
                );
              }}
              onChange={setIsAccommodationModifiedAction}
            />
          </td>
        )}
        <td className="w-14% p-0 pl-2 pr-4 pb-4 pt-2">
          <PriceAsCentsInput
            disabled={!isSalesCostEditable}
            allowNegative={allowNegative}
            key={`${keyPrefix}-${index}-saleCostCents`}
            className={classnames(
              'focus:outline-gray-80 text-black cost-to-ta border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': !isSalesCostEditable,
              }
            )}
            cents={lineItem.saleCostCents || 0}
            onBlurUpdate={val => {
              return setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].saleCostCents`,
                val
              );
            }}
            onChange={setIsAccommodationModifiedAction}
          />
        </td>
        {isEditable === true && (
          <RemoveHeadlineButton
            action={removeHeadlineLineItemAction}
            pathPrefix={pathPrefix}
            productType={productType}
            index={index}
          />
        )}
      </tr>
      <tr>
        <td colSpan={isPurchaseCostVisible ? 2 : 3} className="pr-2 pb-4 pt-2">
          <LineItemInput
            disabled={isEditable === false}
            className={classnames(
              'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': isEditable === false,
              }
            )}
            value={lineItem.tertiaryText}
            placeholder={tertiaryTextPlaceholder || 'Line Item Description'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].tertiaryText`,
                event.target.value
              );
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

const AncillaryProduct = ({
  lineItem,
  index,
  keyPrefix,
  isEditable,
  setHeadlineLineItemFieldActionCallback,
  pathPrefix,
  productType,
  productDisplayType,
  includeTableHeaders,
  lineItems,
  isPurchaseCostVisible,
  titlePlaceholder,
  tertiaryTextPlaceholder,
  isPurchaseCostDisabled,
  allowNegative,
  isPurchaseCostEditable,
  setIsAccommodationModifiedAction,
  isSalesCostEditable,
  removeHeadlineLineItemAction,
  editHeadlineLineItemAction,
  isAdmin,
  isSR,
  isFinanceUser,
  suppliers,
  isTimeVisible,
}: {
  lineItem: IHeadlineLineItemBreakdownLineItem;
  index: number;
  keyPrefix: string;
  isEditable: boolean;
  setHeadlineLineItemFieldActionCallback: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  pathPrefix: string;
  productType: string;
  productDisplayType: string;
  includeTableHeaders: boolean;
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  isPurchaseCostVisible: boolean;
  titlePlaceholder: string;
  tertiaryTextPlaceholder: string;
  isPurchaseCostDisabled: boolean;
  allowNegative: boolean;
  isPurchaseCostEditable: boolean;
  setIsAccommodationModifiedAction: () => void;
  isSalesCostEditable: boolean;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  editHeadlineLineItemAction: () => void;
  isAdmin: boolean;
  isSR: boolean;
  isFinanceUser: boolean;
  suppliers: SuppliersSearchItem[];
  isTimeVisible: boolean;
}) => {
  const { dynamicParameters } = useDynamicParameters();

  const renderEditButton =
    (dynamicParameters.ENABLE_EDIT_ACTIVITIES_MODAL === true &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.ACTIVITY) ||
    (dynamicParameters.ENABLE_EDIT_GROUND_SERVICES_MODAL === true &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.GROUND_SERVICE) ||
    (dynamicParameters.ENABLE_EDIT_TRANSFERS_MODAL &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.TRANSFER);

  return (
    <React.Fragment key={`${keyPrefix}-wrapper`}>
      <tr key={`htop-${index}`} className={`title p-0 w-1/4 ancillary-${productDisplayType}`}>
        <td className="p-0 pr-2 pb-4 pt-2">
          <h2 className="text-17px text-left m-0">
            {productDisplayType} {index + 1}
          </h2>
        </td>
        <td colSpan={2} className="pl-2 pr-2 pb-4 pt-2">
          <div
            className={classNames('flex flex-col', {
              hidden: !isAdmin && !isSR && !isFinanceUser,
            })}
          >
            <span
              className={classNames('text-gray-darker text-xs uppercase font-pt-sans mb-4', {
                hidden: !includeTableHeaders || index !== 0,
              })}
            >
              Supplier
            </span>
            <Multiselect
              className="bg-gray-10 o:cursor-auto"
              primaryCtaClassName="o:cursor-auto"
              itemsClassname="bg-gray-10 o:cursor-auto"
              itemCtaClassName="hover:bg-gray-10 o:cursor-auto"
              options={suppliers.map(s => ({ label: s.name, value: s.id.toString() }))}
              isCloseOnSelect={true}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              disabled={true}
              hideDropdownArrow={true}
              onUpdate={sv => {
                // void
              }}
              selectedValues={
                lineItem.ancillaryData?.product?.supplier
                  ? [lineItem.ancillaryData?.product?.supplier.supplierId.toString()]
                  : []
              }
            />
          </div>
        </td>
        <td key={`htop-non-ancillary-${productDisplayType}-${index}-col-1`} className="pl-2 pr-4 pb-4 pt-2">
          <div className="flex flex-col">
            <span
              className={classNames('text-gray-darker text-xs uppercase font-pt-sans text-right mb-4', {
                hidden: !includeTableHeaders || index !== 0,
              })}
            >
              Confirmation N.
            </span>
            <input
              id={`${keyPrefix}-${index}-confirmation-number`}
              key={`${keyPrefix}-${index}-confirmation-number`}
              placeholder={'Confirmation N.'}
              value={lineItem.itemConfirmationNumber}
              type="text"
              readOnly={!isEditable}
              className={classnames(
                'w-[130px] resort-confirmation-number-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans',
                {
                  'bg-gray-10': isEditable === false,
                }
              )}
              onChange={event => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].itemConfirmationNumber`,
                  event.target.value
                );
              }}
            />
          </div>
        </td>
      </tr>
      <tr
        key={`h-${index}`}
        className={classNames('title p-0 w-1/4', {
          hidden: index !== 0,
        })}
      >
        {includeTableHeaders && lineItems.length >= 1 && (
          <React.Fragment>
            <td className={`${productDisplayType}-name text-gray-darker text-xs uppercase text-left pr-2 font-pt-sans`}>
              <span>Name</span>
            </td>
            <td
              className={`${productDisplayType}-date w-14% text-gray-darker text-xs uppercase text-left pl-2 font-pt-sans`}
            >
              <span>Date</span>
            </td>
            {isTimeVisible && (
              <td
                className={`${productDisplayType}-date w-14% text-gray-darker text-xs uppercase text-left pl-2 font-pt-sans`}
              >
                <span>Local time</span>
              </td>
            )}
            {isPurchaseCostVisible && (
              <td className="cost-before-discount w-14% text-gray-darker text-xs uppercase text-right p-2 font-pt-sans">
                Purchase Cost
              </td>
            )}
            <td
              colSpan={isPurchaseCostVisible ? 1 : 2}
              className="cost-to-ta w-14% text-gray-darker text-xs uppercase text-right pr-4 font-pt-sans"
            >
              Cost to TP
            </td>
          </React.Fragment>
        )}
        {isPurchaseCostVisible && <td></td>}
      </tr>
      <tr key={`r-${index}`}>
        <td className="p-0 pr-2 pb-4 pt-2">
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={lineItem.title}
            placeholder={titlePlaceholder || 'Line Item Title'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].title`,
                event.target.value
              );
            }}
          />
        </td>
        <td className="p-0 px-2 pb-4 pt-2" colSpan={isPurchaseCostVisible ? 1 : 2}>
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={formatDateDisplay(lineItem.ancillaryData?.userSelections.selectedDate)}
            placeholder={'Line Item Dates'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].dateString`,
                event.target.value
              );
            }}
          />
        </td>
        {isTimeVisible && (
          <td className="p-0 px-2 pb-4 pt-2">
            <input
              disabled = {true}
              type="time"
              className={classNames(
                'arrival-time-input p-2 border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                {
                  'bg-gray-10': true,
                }
              )}
              value={lineItem.ancillaryData?.userSelections.time}
            />
          </td>
        )}

        {isPurchaseCostVisible && (
          <td className="w-14% p-0 pl-2 pr-2 pb-4 pt-2">
            {/* cost before discount price */}
            <PriceAsCentsInput
              disabled={isPurchaseCostDisabled}
              allowNegative={allowNegative}
              key={`${keyPrefix}-${index}-purchaseCostCents`}
              className={classnames(
                'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                {
                  'bg-gray-10': isPurchaseCostEditable === false,
                }
              )}
              cents={lineItem.purchaseCostCents || 0}
              onBlurUpdate={val => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                  val
                );
              }}
              onChange={setIsAccommodationModifiedAction}
            />
          </td>
        )}
        <td className="w-14% p-0 pl-2 pr-4 pb-4 pt-2">
          <PriceAsCentsInput
            disabled={!isSalesCostEditable}
            allowNegative={allowNegative}
            key={`${keyPrefix}-${index}-saleCostCents`}
            className={classnames(
              'focus:outline-gray-80 text-black cost-to-ta border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': !isSalesCostEditable,
              }
            )}
            cents={lineItem.saleCostCents || 0}
            onBlurUpdate={val => {
              setHeadlineLineItemFieldActionCallback(`${pathPrefix}${productType}.items[${index}].saleCostCents`, val);
            }}
            onChange={setIsAccommodationModifiedAction}
          />
        </td>
        {isEditable === true && (
          <>
            {renderEditButton && (
              <EditHeadlineButton
                action={editHeadlineLineItemAction}
                pathPrefix={pathPrefix}
                productType={productType}
                index={index}
              />
            )}
            <RemoveHeadlineButton
              action={removeHeadlineLineItemAction}
              pathPrefix={pathPrefix}
              productType={productType}
              index={index}
            />
          </>
        )}
      </tr>
      <tr>
        <td colSpan={isPurchaseCostVisible ? 2 : 3} className="pr-2 pb-4 pt-2">
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={lineItem.tertiaryText}
            placeholder={tertiaryTextPlaceholder || 'Line Item Description'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].tertiaryText`,
                event.target.value
              );
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export const GenericProductsBlock = (props: IGenericProductsBlock) => {
  const {
    pathPrefix = '',
    className = '',
    lineItems,
    includeTableHeaders = false,
    productType,
    productDisplayType,
    subTotalCents,
    allowNegative,
    setHeadlineLineItemFieldAction,
  } = props;

  const isEditable = props.isEditable && !props.isLiveRate;
  const isPurchaseCostVisible = props.isSR || props.isFinanceUser;
  const isPurchaseCostDisabled = !props.isPurchaseCostEditable || !isEditable;

  let isSalesCostEditable = props.isEditable;
  if (props.isLiveRate) {
    isSalesCostEditable = isSalesCostEditable && (props.isAdmin || props.isSR);
  }

  const dispatch = useDispatch();

  const setIsAccommodationModifiedAction = useCallback(() => {
    if (props.isAccommodationSubBlock) {
      dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
    }
  }, [dispatch, props.isAccommodationSubBlock]);

  return (
    <div className={`product ${toClassName(props.productType)} ${className}`} key={props.productType}>
      <table className="w-full">
        <thead>
          <tr>{!includeTableHeaders && <EmptyHeaderProducts />}</tr>
        </thead>

        <tbody key="activities">
          {lineItems.length <= 0 && (
            <tr>
              <td className="p-0 pt-2 pb-4"></td>
            </tr>
          )}
          {lineItems.length >= 1 &&
            lineItems.map((lineItem, index) => {
              if (lineItem.ancillaryData) {
                return (
                  <AncillaryProduct
                    key={`ancillary-${productDisplayType}-${index}`}
                    lineItem={lineItem}
                    index={index}
                    keyPrefix={`ancillary-${productDisplayType}-${index}`}
                    isEditable={isEditable}
                    setHeadlineLineItemFieldActionCallback={setHeadlineLineItemFieldAction}
                    pathPrefix={pathPrefix}
                    productType={productType}
                    productDisplayType={productDisplayType}
                    includeTableHeaders={includeTableHeaders}
                    lineItems={lineItems}
                    isTimeVisible={productType === ProductTypes.TRANSFER}
                    isPurchaseCostVisible={isPurchaseCostVisible}
                    titlePlaceholder={props.titlePlaceholder || 'Line Item Title'}
                    tertiaryTextPlaceholder={props.tertiaryTextPlaceholder || 'Line Item Description'}
                    isPurchaseCostDisabled={isPurchaseCostDisabled}
                    allowNegative={allowNegative || false}
                    isPurchaseCostEditable={props.isPurchaseCostEditable}
                    setIsAccommodationModifiedAction={setIsAccommodationModifiedAction}
                    isSalesCostEditable={isSalesCostEditable}
                    removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
                    editHeadlineLineItemAction={() => {
                      dispatch(BreakdownActions.setCurrentlyEditingLineItemAction(lineItem, index));
                    }}
                    isAdmin={props.isAdmin}
                    isSR={props.isSR}
                    isFinanceUser={props.isFinanceUser}
                    suppliers={props.suppliers}
                  />
                );
              }
              return (
                <NonAncillaryProduct
                  suppliers={props.suppliers}
                  key={`non-ancillary-${productDisplayType}-${index}`}
                  lineItem={lineItem}
                  index={index}
                  keyPrefix={`non-ancillary-${productDisplayType}-${index}`}
                  isEditable={isEditable}
                  setHeadlineLineItemFieldActionCallback={setHeadlineLineItemFieldAction}
                  pathPrefix={pathPrefix}
                  productType={productType}
                  productDisplayType={productDisplayType}
                  includeTableHeaders={includeTableHeaders}
                  lineItems={lineItems}
                  isTimeVisible={productType === ProductTypes.TRANSFER}
                  isPurchaseCostVisible={isPurchaseCostVisible}
                  titlePlaceholder={props.titlePlaceholder || 'Line Item Title'}
                  tertiaryTextPlaceholder={props.tertiaryTextPlaceholder || 'Line Item Description'}
                  isPurchaseCostDisabled={isPurchaseCostDisabled}
                  allowNegative={allowNegative || false}
                  isPurchaseCostEditable={props.isPurchaseCostEditable}
                  setIsAccommodationModifiedAction={setIsAccommodationModifiedAction}
                  isSalesCostEditable={isSalesCostEditable}
                  removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
                  isAdmin={props.isAdmin}
                  isSR={props.isSR}
                  isFinanceUser={props.isFinanceUser}
                />
              );
            })}

          <ClosingProductsRow
            subTotalCents={subTotalCents}
            bookingCurrencySymbol={props.bookingCurrencySymbol}
            cta={props.cta}
            hasItems={lineItems.length >= 1}
          />
        </tbody>
      </table>
    </div>
  );
};
