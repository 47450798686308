import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENetworkRequestStatus } from 'services/BackendApi';
import * as LedgerActions from 'store/modules/ledger/actions';
import * as LedgerSelectors from 'store/modules/ledger/selectors';
import { companiesSelector } from 'store/modules/agents/selectors';
import {
  isSR as isSRSelector,
  isAdmin as isAdminSelector,
  isFinanceUser as isFinanceUserSelector,
} from 'store/modules/auth/selectors';
import { BookingLedgerHeadline, BookingLedgerTable, Controls } from 'ui/Ledger';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { getCompaniesRequestAction } from 'store/modules/agents/actions';
import { BookingLedgerFilter, FILTERS_ANIMATION_DURATION } from './BookingLedgerFilter';
import { TCurrencyCode } from 'interfaces';
import { StandardModal } from 'pureUi/Modal';
import { BalanceCarriedForwardModal } from 'ui/BalanceCarriedForwardModal';
import { IManualBookingLedgerRowForSave } from 'store/modules/ledger/model';
import { PdfDownloadHistory, DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION } from '../PdfDownloadHistory';
import { delay } from 'utils';

interface IBookingLedgerProps {
  companyUuid: string;
  currency: TCurrencyCode;
}

const BookingLedger: React.FC<IBookingLedgerProps> = React.memo(props => {
  const dispatch = useDispatch();

  const { companyUuid } = props;
  const currency: TCurrencyCode = props.currency.toUpperCase() as TCurrencyCode;

  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);
  const isSR = useSelector(isSRSelector);

  const bookingLedger = useSelector(LedgerSelectors.ledgerBookingLedgerSelector);
  const csvExportLoading = useSelector(LedgerSelectors.ledgerBookingLedgerCsvExportLoading);
  const pdfGenerationInProgress = useSelector(LedgerSelectors.ledgerBookingLedgerPdfGenerationInProgress);
  const downloadHistoryInProgress = useSelector(LedgerSelectors.ledgerBookingLedgerDownloadHistoryInProgress);
  const downloadHistoryPdfs = useSelector(LedgerSelectors.ledgerBookingLedgerDownloadedPdfs);

  const companies = useSelector(companiesSelector);

  const depositTotals = useSelector(LedgerSelectors.ledgerDepositStatementTotalsSelector);

  const isBCFModalOpen = useSelector(LedgerSelectors.isBCFModalOpenSelector);
  const initialBCFRowLoading = useSelector(LedgerSelectors.initialBCFRowLoadingSelector);
  const bcfRowCreateInProgress = useSelector(LedgerSelectors.bcfRowCreateSelector);
  const bookingLedgerRequests = useSelector(LedgerSelectors.bookingLedgerRequestSelector);
  const isDataRefetching = bookingLedgerRequests.updateFilters === ENetworkRequestStatus.PENDING;

  const depositTotalForCurrency = depositTotals.find(dt => dt.currency === currency);

  const [isFiltersDetailOpen, setIsFiltersDetailOpen] = useState(false);
  const [isDownloadPdfHistoryDetailOpen, setIsDownloadPdfHistoryDetailOpen] = useState(false);

  const toggleFilters = useCallback(
    async (isOpen: boolean) => {
      if (isDownloadPdfHistoryDetailOpen) {
        setIsDownloadPdfHistoryDetailOpen(false);
        await delay(DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION);
      }
      setIsFiltersDetailOpen(isOpen);
    },
    [isDownloadPdfHistoryDetailOpen]
  );

  const toggleDownloadPdfHistory = useCallback(
    async (isOpen: boolean) => {
      if (isFiltersDetailOpen) {
        setIsFiltersDetailOpen(false);
        await delay(FILTERS_ANIMATION_DURATION);
      }
      setIsDownloadPdfHistoryDetailOpen(isOpen);
    },
    [isFiltersDetailOpen]
  );

  const { data } = bookingLedger;

  useEffect(() => {
    dispatch(getCompaniesRequestAction());
  }, []);

  useEffect(() => {
    if (companyUuid) {
      dispatch(LedgerActions.selectCompanyAction(companyUuid));
    }
  }, [companyUuid]);

  useEffect(() => {
    if (currency) {
      dispatch(LedgerActions.bookingLedgerRequestAction(currency));
    }
  }, [currency]);

  useEffect(() => {
    dispatch(LedgerActions.depositStatementTotalsRequestAction(companyUuid));
    if (companyUuid && currency) {
      dispatch(LedgerActions.bookingLedgerDownloadPdfHistoryRequestAction(companyUuid, currency));
    }
  }, [companyUuid, currency]);

  const handleCsvExport = useCallback(() => {
    dispatch(LedgerActions.bookingLedgerPostExportRequestAction());
  }, []);

  const handleGeneratePdf = useCallback(() => {
    dispatch(LedgerActions.bookingLedgerGeneratePdfRequestAction());
  }, []);

  const handleOpenBalanceCarriedForwardModal = useCallback(() => {
    dispatch(LedgerActions.checkInitialBCFRequestAction());
  }, []);

  const handleCloseBalanceCarriedForwardModal = useCallback(() => {
    dispatch(LedgerActions.closeBalanceCarriedForwardModalAction());
  }, []);

  const csvExport = useMemo(
    () => (isAdmin || isFinanceUser || isSR ? [{ handler: handleCsvExport, loading: csvExportLoading }] : undefined),
    [isAdmin, isFinanceUser, isSR, csvExportLoading]
  );

  const generatePdf = useMemo(() => ({ handler: handleGeneratePdf, loading: pdfGenerationInProgress }), [
    pdfGenerationInProgress,
  ]);

  const balanceCarriedForward = useMemo(
    () =>
      isAdmin || isFinanceUser
        ? {
            handler: handleOpenBalanceCarriedForwardModal,
            loading: initialBCFRowLoading,
          }
        : undefined,
    [isAdmin, isFinanceUser, initialBCFRowLoading, handleOpenBalanceCarriedForwardModal]
  );

  const filters = useMemo(() => ({ handler: toggleFilters, isOpen: isFiltersDetailOpen }), [
    toggleFilters,
    isFiltersDetailOpen,
  ]);

  const downloadHistory = useMemo(
    () => ({ handler: toggleDownloadPdfHistory, isOpen: isDownloadPdfHistoryDetailOpen }),
    [toggleDownloadPdfHistory, isDownloadPdfHistoryDetailOpen]
  );

  const handleSaveBCFRow = useCallback((newRowWithoutUpload: IManualBookingLedgerRowForSave, files: File[]) => {
    dispatch(LedgerActions.saveBCFRowRequestAction(newRowWithoutUpload, files));
  }, []);

  const selectedCompanyName = useMemo(() => companies?.find(company => company.uuid === companyUuid)?.name, [
    companies,
    companyUuid,
  ]);

  return (
    <div className="booking-ledger-container container mx-auto max-w-1280px font-pt-sans text-black">
      {bookingLedger?.bookingLedgerLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}
      {bookingLedger?.bookingLedgerLoad === ENetworkRequestStatus.SUCCESS && data && (
        <>
          <BookingLedgerHeadline
            balanceOwed={data.positiveTotal}
            depositsHeld={data.negativeTotal}
            netBalance={data.netTotal}
            accountBalance={depositTotalForCurrency?.netTotal || 0}
            currency={currency}
          />
          <Controls
            csvExport={csvExport}
            generatePdf={generatePdf}
            balanceCarriedForward={balanceCarriedForward}
            filters={filters}
            downloadHistory={downloadHistory}
          />

          <BookingLedgerFilter isOpen={isFiltersDetailOpen} />

          <PdfDownloadHistory
            pdfs={downloadHistoryPdfs}
            loading={downloadHistoryInProgress}
            isOpen={isDownloadPdfHistoryDetailOpen}
          />

          <BookingLedgerTable
            financeRows={data.rows}
            balanceCarriedForward={data.lastPageTotal}
            currency={currency}
            tableLoading={isDataRefetching}
          />

          {isBCFModalOpen && (
            <StandardModal
              className="balance-carried-forward-modal font-pt-sans"
              frameClassName="w-670px px-35px pt-25px pb-30px border border-solid"
              showCloseButton={true}
              removePadding={true}
              closeOnOutsideClick={true}
              onClose={handleCloseBalanceCarriedForwardModal}
            >
              <BalanceCarriedForwardModal
                submitButtonLoading={bcfRowCreateInProgress === ENetworkRequestStatus.PENDING}
                onSubmit={handleSaveBCFRow}
                onClose={handleCloseBalanceCarriedForwardModal}
              />
            </StandardModal>
          )}
        </>
      )}
    </div>
  );
});

export { BookingLedger };
