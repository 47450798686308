import React, { Dispatch, SetStateAction, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BookingRightTotalCost } from './BookingRightTotalCost';
import { BookingLeadGuestInfo } from './BookingLeadGuestInfo';
import { useCurrentWidth } from 'effects';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as BookingsSelectors from 'store/modules/bookings/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder';
import { BookingMargin, useMarginChange } from 'containers/SummaryFormExtras/BookingMargin';
import { useTranslation } from 'react-i18next';
import { BookingButtons } from './BookingButtons';
import { TermsAndConditions } from 'containers/HotelContainer/TermsAndConditions';
import { LodgingSummary } from 'interfaces';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { BookingBuilder, IHotel } from 'services/BackendApi';
import { TravelAgentInfo } from './TravelAgentInfo';

interface IBookingRightColumnProps {
  booking: BookingBuilder;
  hotel: IHotel;
  lodgingSummaries: LodgingSummary[];
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  shouldShowLeaveAlert: boolean;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const BookingRightColumn: React.FC<IBookingRightColumnProps> = React.memo(({ booking, className, hotel, lodgingSummaries, setRedirectLocation, setShouldShowLeaveAlert }) => {
  const { currentWidth } = useCurrentWidth();
  const isTAMarginApplied = useSelector(BookingBuilderSelectors.isTAMarginAppliedSelector);
  const grandTotal = useSelector(BookingBuilderSelectors.bookingBuilderTotalSelector);
  const taMarginType = useSelector(BookingBuilderSelectors.taMarginTypeSelector);
  const canBook = useSelector(BookingBuilderSelectors.bookingCanBookSelector);
  const currencyCode = useSelector(BookingsSelectors.getBookingCurrencySymbol);
  const taMarginAmount = useSelector(BookingBuilderSelectors.taMarginAmountSelector);
  const { t } = useTranslation();
  const rightColumnClassname = classnames(
    'booking-right-column ', className, 
    { 'w-[260px]': currentWidth < RIGHT_COLUMN_BREAKPOINT, 'w-[370px]': currentWidth >= RIGHT_COLUMN_BREAKPOINT }
  );
  const { onMarginChange } = useMarginChange(hotel.uuid);
  const isSr: boolean = useSelector(AuthSelectors.isSR);
  const isRl: boolean = useSelector(AuthSelectors.isRL);
  const isTA: boolean = useSelector(AuthSelectors.isTA);
  const shouldShowTAInfo = isSr && !isRl;
  const [isRequestInProgress, setRequestInProgress] = useState(false);
  const [liveRatePricesModalData, setLiveRatePricesModalData] = useState([]);
  const [isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen] = useState(false);
  const handleLiveRatePricesModalOpen = (value: boolean) => {
    setIsLiveRatePricesModalOpen(value);
  }
  const handleSetLiveRatePricesModalData = (value: any) => {
    setLiveRatePricesModalData(value);
  }

  return (
    <div className={rightColumnClassname}>
        <BookingRightTotalCost booking={booking} />
        <BookingLeadGuestInfo booking={booking} setShouldShowLeaveAlert={setShouldShowLeaveAlert} />
        {shouldShowTAInfo ? (
          <TravelAgentInfo />
        ) : null}
        <BookingMargin
          t={t}
          currencyCode={currencyCode}
          onMarginChange={onMarginChange}
          grandTotal={grandTotal}
          canBook={canBook}
          isTAMarginApplied={isTAMarginApplied}
          taMarginType={taMarginType}
          taMarginAmount={taMarginAmount}
          textClassname="normal-case"
          depth={0}
          titleClassname="font-hurmegeometric-sans font-[12px] leading-[14px] font-bold text-flint pb-[10px] mb-[10px] ml-[-20px] border-b border-gray-17"
          marginClassname="mx-[-20px]"
        />
        <BookingButtons
          booking={booking}
          lodgingSummaries={lodgingSummaries}
          isRequestInProgress={isRequestInProgress}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRequestInProgress={setRequestInProgress}
          setRedirectLocation={setRedirectLocation}
          canBook={canBook}
          isLiveRatePricesModalOpen={isLiveRatePricesModalOpen}
          setIsLiveRatePricesModalOpen={handleLiveRatePricesModalOpen}
          liveRatePricesModalData={liveRatePricesModalData}
          setLiveRatePricesModalData={handleSetLiveRatePricesModalData}
        />
        <div className="terms-and-conditions uppercase">
          <TermsAndConditions className="font-hurmegeometric-sans text-brown-100 visited:text-brown-100 inline-block mt-[18px] text-[12px] uppercase" />
        </div>
    </div>
	);
});