import * as Actions from './actions';
import { produce } from 'immer';
import { 
  ITCBookingStatementDomain,
  initialState,
} from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { ITCBookingStatementPageRequest } from 'services/BookingManagerApi/types';
import * as ExportCsvPollingReducerFunctions from 'store/modules/exportCsv/reducer';

export const tcBookingStatementReducer = (
  state: ITCBookingStatementDomain = initialState,
  action: Actions.TCBookingStatementAction
): ITCBookingStatementDomain => {
  
  switch (action.type) {

    case Actions.TC_BOOKING_STATEMENT_TOTALS_REQUEST: {
      return produce(state, draftState => {
        draftState.tcBookingTotals = [];
        draftState.tcBookingTotalsLoad = ENetworkRequestStatus.PENDING;
        draftState.tcBookingTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_TOTALS_SUCCESS: {
      return produce(state, draftState => {
        draftState.tcBookingTotals = action.tcTotals;
        draftState.tcBookingTotalsLoad = ENetworkRequestStatus.SUCCESS;
        draftState.tcBookingTotalsError = undefined;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_TOTALS_FAILURE: {
      return produce(state, draftState => {
        draftState.tcBookingTotalsLoad = ENetworkRequestStatus.ERROR;
        draftState.tcBookingTotalsError = action.error;

        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_FROM_DATE: {
      return produce(state, draftState => {
        draftState.statementFilterFromDate = action.fromDate;
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_TO_DATE: {
      return produce(state, draftState => {
        draftState.statementFilterToDate = action.toDate;
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_STATUS: {
      return produce(state, draftState => {
        draftState.statementFilterStatus = action.status;
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_AMOUNT_MIN: {
      return produce(state, draftState => {
        if (action.amount !== undefined) {
          draftState.statementFilterAmountMin = action.amount;
        } else {
          draftState.statementFilterAmountMin = null;
        }
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_AMOUNT_MAX: {
      return produce(state, draftState => {
        if (action.amount !== undefined) {
          draftState.statementFilterAmountMax = action.amount;
        } else {
          draftState.statementFilterAmountMax = null;
        }
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_ADD_BOOKING_REF: {
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs.push('');
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_SET_BOOKING_REF: {
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs[action.index] = action.bookingRefValue;
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_DELETE_BOOKING_REF: {
      return produce(state, draftState => {
        draftState.statementFilterBookingRefs.splice(action.index, 1);
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_RESET: {
      return produce(state, draftState => {
        draftState.statementFilterFromDate = initialState.statementFilterFromDate;
        draftState.statementFilterToDate = initialState.statementFilterToDate;
        draftState.statementFilterStatus = initialState.statementFilterStatus;
        draftState.statementFilterBookingRefs = initialState.statementFilterBookingRefs;
        draftState.statementFilterAmountMax = initialState.statementFilterAmountMax;
        draftState.statementFilterAmountMin = initialState.statementFilterAmountMin;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_RESET: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency = initialState.bookingsByCurrency;

        draftState.statementFilterFromDate = initialState.statementFilterFromDate;
        draftState.statementFilterToDate = initialState.statementFilterToDate;
        draftState.statementFilterStatus = initialState.statementFilterStatus;
        draftState.statementFilterBookingRefs = initialState.statementFilterBookingRefs;
        draftState.statementFilterAmountMax = initialState.statementFilterAmountMax;
        draftState.statementFilterAmountMin = initialState.statementFilterAmountMin;
        return draftState;
      });
    }

    case Actions.TC_STATEMENT_FILTER_UPDATE_RESULTS: {
      return produce(state, draftState => {
        const filter: ITCBookingStatementPageRequest['filter'] = {};

        if (state.statementFilterFromDate) {
          filter.fromDate = state.statementFilterFromDate;
        }
        if (state.statementFilterToDate) {
          filter.toDate = state.statementFilterToDate;
        }
        if (state.statementFilterStatus) {
          filter.status = state.statementFilterStatus;
        }

        const refsSanitised = state.statementFilterBookingRefs.filter(x => x);
        if (refsSanitised.length) {
          filter.humanReadableId = [...refsSanitised];
        }

        if (state.statementFilterAmountMin !== null) {
          filter.amountMinCents = state.statementFilterAmountMin;
        }
        if (state.statementFilterAmountMax !== null) {
          filter.amountMaxCents = state.statementFilterAmountMax;
        }

        draftState.bookingsByCurrency.filter = filter;
        draftState.bookingsByCurrency.currentPage = 0;
        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_SELECT_COMPANY: {
      return produce(state, draftState => {
        draftState.selectedCompanyUuid = action.company;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_REQUEST: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.data = null;
        draftState.bookingsByCurrency.currency = action.currency;
        draftState.bookingsByCurrency.tcBookingStatementLoad = ENetworkRequestStatus.PENDING;
        draftState.bookingsByCurrency.tcBookingStatementError = undefined;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_SUCCESS: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.data = action.tcBookingStatement;
        draftState.bookingsByCurrency.tcBookingStatementLoad = ENetworkRequestStatus.SUCCESS;
        draftState.bookingsByCurrency.tcBookingStatementError = undefined;
        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.SUCCESS;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_FAILURE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.tcBookingStatementLoad = ENetworkRequestStatus.ERROR;
        draftState.bookingsByCurrency.tcBookingStatementError = undefined;
        draftState.bookingsByCurrency.request.updateFilters = ENetworkRequestStatus.ERROR;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_SET_SORT: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.sortBy = action.sortBy;
        draftState.bookingsByCurrency.sortOrder = action.sortOrder;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_SET_PAGE_NUMBER: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.currentPage = action.pageNumber;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE: {
      return produce(state, draftState => {
        draftState.bookingsByCurrency.itemsPerPage = action.itemsPerPage;

        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.generate = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_SUCCESS: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.generate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_FAILURE: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.generate = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.downloadHistory = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_SUCCESS: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.downloadHistory = ENetworkRequestStatus.SUCCESS;
        draftState.statementPdf.downloadedPdfs = action.pdfs;
        return draftState;
      });
    }

    case Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_FAILURE: {
      return produce(state, draftState => {
        draftState.statementPdf.requests.downloadHistory = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.TRIGGER_EXPORT_CSV_REQUEST:
      return ExportCsvPollingReducerFunctions.triggerRequest(state);

    case Actions.TRIGGER_EXPORT_CSV_SUCCESS:
      return ExportCsvPollingReducerFunctions.triggerSuccess(state, action.exportUuid);

    case Actions.TRIGGER_EXPORT_CSV_FAILURE:
      return ExportCsvPollingReducerFunctions.triggerFailure(state);

    case Actions.POLL_EXPORT_CSV_REQUEST:
      return ExportCsvPollingReducerFunctions.pollRequest(state);

    case Actions.POLL_EXPORT_CSV_SUCCESS:
      return ExportCsvPollingReducerFunctions.pollSuccess(state, action.signedUrl);

    case Actions.POLL_EXPORT_CSV_CONTINUE:
      return ExportCsvPollingReducerFunctions.pollContinue(state);

    case Actions.POLL_EXPORT_CSV_FAILURE:
      return ExportCsvPollingReducerFunctions.pollFailure(state);

    case Actions.POLL_EXPORT_CSV_CANCEL:
      return ExportCsvPollingReducerFunctions.pollCancel(state);

    default:
      return (state)
  };  

};
