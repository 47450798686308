import { equals, mapObjIndexed, always } from 'ramda';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { STATUS_TO_IDLE, STORE_RESET, resetStoreStatuses } from './common';

import auth from './modules/auth/reducer';
import userDomainReducer from './modules/user/reducer';
import bookings from './modules/bookings/reducer';
import countries from './modules/countries/reducer';
import companyInfo from './modules/companyInfo/reducer';
import hotel from './modules/hotel/reducer';
import hotels from './modules/hotels/reducer';
import indexes from './modules/indexes/reducer';
import offers from './modules/offers/reducer';
import pages from './modules/pages/reducer';
import proposals from './modules/proposals/reducer';
import search from './modules/search/reducer';
import ui from './modules/ui/reducer';
import users from './modules/users/reducer';
import hotelAccommodationProducts from './modules/hotelAccommodationProducts/reducer';
import fastSearch from './modules/fastSearch/reducer';
import bookingBuilder from './modules/bookingBuilder/reducer';
import proposalsList from './modules/proposalsList/reducer';
import proposalDetails from './modules/proposalDetails/reducer';
import proposalGenerate from './modules/proposalGenerate/reducer';
import proposalEditor from './modules/proposalEditor/reducer';
import bookingsList from './modules/bookingsList/reducer';
import agents from './modules/agents/reducer';
import offersList from './modules/offersList/reducer';
import { offer } from './modules/offer/reducer';
import { bootstrap } from './modules/bootstrap/reducer';
import { ratesImport } from './modules/ratesImport/reducer';
import { ancillarySuppliersImport } from './modules/ancillarySuppliersImport/reducer';
import { ancillaryActivitiesImport } from './modules/ancillaryActivitiesImport/reducer';
import { ancillaryTransfersImport } from './modules/ancillaryTransfersImport/reducer';
import { ancillaryTransfersRatesImport } from './modules/ancillaryTransfersRatesImport/reducer';
import { ancillaryActivitiesRatesImport } from './modules/ancillaryActivitiesRatesImport/reducer';
import { ancillaryGroundServicesImport } from './modules/ancillaryGroundServicesImport/reducer';
import { ancillaryGroundServicesRatesImport } from './modules/ancillaryGroundServicesRatesImport/reducer';
import { productsImport } from './modules/productsImport/reducer';
import { appReducer } from './modules/app/reducer';
import bookingManager from './modules/bookingManager/reducer';
import bookingConfirmation from './modules/bookingConfirmation/reducer';
import { rateBreakReducer } from './modules/rateBreak/reducer';
import companyHotelMarkupReducer from './modules/companyHotelMarkup/reducer';
import { ledgerReducer } from './modules/ledger/reducer';
import { tcBookingStatementReducer } from './modules/tcBookingStatement/reducer';
import { companyInvoiceAddresseeReducer } from './modules/companyInvoiceAddressee/reducer';
import { companyListReducer } from './modules/companyList/reducer';
import { cognitoReducer } from './modules/cognito/reducer';
import { travelAgentListReducer } from './modules/travelAgentList/reducer';
import { globalStatementReducer } from './modules/globalStatement/reducer';
import { companyDocumentsReducer } from './modules/companyDocuments/reducer';
import { companyManagersReducer } from './modules/companyManagers/reducer';
import { companyIncentivesReducer } from './modules/companyIncentives/reducer';
import staticRatesAvailabilityReducer from './modules/staticRatesAvailability/reducer';
import { staticRatesAvailabilityImport } from './modules/staticRatesAvailabilityImport/reducer';
import { credentialsInfoReducer } from './modules/credentialsInfo/reducer';
import travelAgentReducer from './modules/travelAgent/reducer';
import internalUserReducer from './modules/internalUser/reducer';
import srReducer from './modules/sr/reducer';
import notificationsReducer from './modules/notifications/reducer';
import internalUsersListReducer from './modules/internalUsersList/reducer';
import inventoryHeaderReducer from './modules/inventoryHeader/reducer';
import inventoryStaysReducer from './modules/inventoryStays/reducer';
import inventoryBootstrapReducer from './modules/inventoryBootstrap/reducer';
import { companyDepartmentsReducer } from './modules/companyDepartments';
import { companyMembershipsReducer } from './modules/companyMemberships/reducer';
import inventorySuppliersReducer from './modules/inventorySuppliers/reducer';
import { liveRatesReducer } from './modules/liveRates/reducer';
import { financesReducer } from './modules/finances/reducer';
import { taskManagementReducer } from './modules/taskManagement/reducer';

const clearState = mapObjIndexed(always(undefined));

const rootReducerFactory = history =>
  /*
  the name you give your reducer in here will be the name of the domain in the store.
  e.g the rateBreak selectors use `rateBreak` as their top level, so the `rateBreakReducer` is
  passed in as `rateBreak`.
  */
  combineReducers({
    agents, // this is the good, controlable, travel agents reducer.
    app: appReducer,
    auth,
    bookingBuilder,
    bookingManager,
    bookingConfirmation,
    bookings,
    bookingsList,
    bootstrap,
    companyDepartments: companyDepartmentsReducer,
    companyDocuments: companyDocumentsReducer,
    companyHotelMarkup: companyHotelMarkupReducer,
    companyIncentives: companyIncentivesReducer,
    companyInfo,
    companyInvoiceAddressee: companyInvoiceAddresseeReducer,
    companyList: companyListReducer,
    cognito: cognitoReducer,
    companyManagers: companyManagersReducer,
    companyMemberships: companyMembershipsReducer,
    countries,
    credentialsInfo: credentialsInfoReducer,
    fastSearch,
    globalStatement: globalStatementReducer,
    hotel,
    hotelAccommodationProducts,
    hotels,
    indexes,
    internalUser: internalUserReducer,
    internalUsersList: internalUsersListReducer,
    inventoryBootstrap: inventoryBootstrapReducer,
    inventoryHeader: inventoryHeaderReducer,
    inventoryStays: inventoryStaysReducer,
    inventorySuppliers: inventorySuppliersReducer,
    ledger: ledgerReducer,
    liveRates: liveRatesReducer,
    notifications: notificationsReducer,
    offer,
    offers,
    offersList,
    pages,
    productsImport,
    proposalDetails,
    proposalEditor,
    proposalGenerate,
    proposals,
    proposalsList,
    rateBreak: rateBreakReducer,
    ratesImport,
    ancillarySuppliersImport,
    ancillaryActivitiesImport,
    ancillaryTransfersImport,
    ancillaryTransfersRatesImport,
    ancillaryActivitiesRatesImport,
    ancillaryGroundServicesImport,
    ancillaryGroundServicesRatesImport,
    router: connectRouter(history),
    search,
    sr: srReducer,
    staticRatesAvailability: staticRatesAvailabilityReducer,
    staticRatesAvailabilityImport,
    tcBookingStatement: tcBookingStatementReducer,
    travelAgent: travelAgentReducer,
    travelAgentList: travelAgentListReducer,
    finances: financesReducer,
    ui,
    user: userDomainReducer,
    users,
    taskManagement: taskManagementReducer,
  });

export default history => {
  const rootReducer = rootReducerFactory(history);

  return (state, action) => {
    const { type } = action;

    if (equals(type, STATUS_TO_IDLE)) {
      state = resetStoreStatuses(state, action);
    }

    if (equals(type, STORE_RESET)) {
      state = clearState(state);
    }

    return rootReducer(state, action);
  };
};
