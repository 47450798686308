import React, { useCallback, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSearchBar } from '../SearchBar/SearchBar';
import { TCountryCode } from 'interfaces';

import * as Actions from 'store/modules/bookingBuilder/subdomains/search/actions';
import * as Selectors from 'store/modules/bookingBuilder/subdomains/search/selectors';
import { isSR, isTA } from 'store/modules/auth/selectors';
import { getTaFullName } from 'store/utils';
import { RouteComponentProps } from 'react-router';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AgentsActions from 'store/modules/agents/actions';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';

interface BookingBuilderSearchBarPropos {
  onSearch: () => void;
  history: RouteComponentProps['history'];
}

export const BookingBuilderSearchBar: React.FC<BookingBuilderSearchBarPropos> = props => {
  const isSr = useSelector(isSR);
  const isTa = useSelector(isTA);

  const searchQuery = useSelector(Selectors.offersQuerySelector);
  const activeLodingIndex = useSelector(Selectors.activeLodingIndexSelector);
  const totalGuestCount = useSelector(Selectors.totalGuestCountSelector);
  const showLodgingControls = useSelector(Selectors.showLodgingControlsSelector);
  const nameSearchResults = useSelector(Selectors.nameSearchResultsSelector);
  const showNameSearchResults = useSelector(Selectors.showNameSearchResultsSelector);
  const isFetchingNameSearch = useSelector(Selectors.isFetchingNameSearchSelector);
  const totalStayNights = useSelector(Selectors.totalStayNightsSelector);
  const dateRange = useSelector(Selectors.dateRangeSelector);
  const dateRangeDisplayString = useSelector(Selectors.dateRangeDisplayStringSelector);
  const selectedDates = useSelector(Selectors.selectedDatesSelector);
  const currentDate = useSelector(Selectors.datePickerCurrentDateSelector);
  const dateSelectionInProgress = useSelector(Selectors.dateSelectionInProgressSelector);
  const showDatePicker = useSelector(Selectors.showDatePickerSelector);
  const isRepeatGuest = useSelector(Selectors.isRepeatGuestSelector);
  const canSearch = useSelector(Selectors.canSearchSelector);
  const destinationList = useSelector(Selectors.hotelsSearchResultsSelector);
  const initialized = useSelector(Selectors.initializedSelector);

  const travelAgents = useSelector(Selectors.selectedCompanyAgents);
  const isFetchingTA = useSelector(Selectors.isFetchingTaSelector);
  const showTaDropdown = useSelector(Selectors.showTaDropdownSelector);
  const selectedTa = useSelector(Selectors.selectedTaSelector);
  const taNames = useSelector(Selectors.selectedCompanyAgentsNames);
  const taNameSearch = useSelector(Selectors.taNameSearchSelector);
  const companies = useSelector(Selectors.companiesSelector);
  const isFetchingCompanies = useSelector(Selectors.isFetchingCompaniesSelector);
  const companiesNames = useSelector(Selectors.companyNamesSelector);
  const selectedCompany = useSelector(Selectors.selectedCompanySelector);
  const showCompanyDropdown = useSelector(Selectors.showCompanyDropdownSelector);
  const companyNameSearch = useSelector(Selectors.companyNameSearchSelector);

  const selectedCompanyMembership = useSelector(Selectors.travelCompanyMembershipSelector);
  const companyMemberships = useSelector(AgentsSelectors.membershipsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const search = (window.location.search || '').replace('?', '');
    dispatch(Actions.initializeQueryAction(search));
  }, [dispatch]);

  const handleDestinationSearch = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      dispatch(Actions.destinationChangeAction(e.currentTarget.value));
    },
    [dispatch]
  );

  const handleDestinationChange = useCallback(
    (value: string) => {
      dispatch(Actions.destinationChangeAction(value));
    },
    [dispatch]
  );

  const handleNavigateToSearch = useCallback(() => {
    const dynamicParameters = ParameterService.getParameters();
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra &&
        // @ts-ignore
        window.woopra.track('search', {
          search: searchQuery.name,
          startDate: searchQuery.startDate,
          endDate: searchQuery.endDate,
          isTA: isTa,
        });
    }
    dispatch(Actions.executeAction(props.history));
    props.onSearch();
  }, [dispatch]);

  const handleToggleLodgingControls = useCallback(() => {
    dispatch(Actions.toggleLodgingControlsAction());
  }, [dispatch]);

  const handleShowNameSearchDropDown = useCallback(
    (visible: boolean) => () => {
      dispatch(Actions.setNamesSearchResultsVisibilityAction(visible));
    },
    [dispatch]
  );

  const handleDateChange = useCallback(
    (dates: string[]) => {
      dispatch(Actions.dateRangeChangeAction(dates));
    },
    [dispatch]
  );

  const handleGuestCountryChange = useCallback(
    (c: TCountryCode) => {
      dispatch(Actions.clientCountryCodeChangeAction(c));
    },
    [dispatch]
  );

  const handleSetLogdingControlsVisibility = useCallback(
    (visible: boolean) => () => {
      dispatch(Actions.setLodgingControlsVisibilityAction(visible));
    },
    [dispatch]
  );

  const handleIncrementRoom = useCallback(
    (step: number) => {
      dispatch(Actions.incrementRoomAction(step));
    },
    [dispatch]
  );

  const handleIncrementAdult = useCallback(
    (lodgingIdx: number, step: number) => {
      dispatch(Actions.incrementAdultAction(lodgingIdx, step));
    },
    [dispatch]
  );

  const handleIncrementChild = useCallback(
    (lodgingIdx: number, step: number) => {
      dispatch(Actions.incrementChildAction(lodgingIdx, step));
    },
    [dispatch]
  );

  const handleSetAge = useCallback(
    (lodgingIdx: number, childIdx: number, value: string) => {
      dispatch(Actions.setAgeAction(lodgingIdx, childIdx, value));
    },
    [dispatch]
  );

  const handleSetActiveLodgingIndex = useCallback(
    (idx: number) => {
      dispatch(Actions.setActiveLodgingIndexAction(idx));
    },
    [dispatch]
  );

  const handleIncrementActiveLodgingIndex = useCallback(
    (step: number) => {
      dispatch(Actions.incrementActiveLodgingIndexAction(step));
    },
    [dispatch]
  );

  const handleToggleRepeatGuest = useCallback(() => {
    dispatch(Actions.toggleRepeatGuestAction());
  }, [dispatch]);

  const handleSearchTaByName = useCallback(
    (value: string) => {
      dispatch(Actions.searchTaByNameAction(value));
    },
    [dispatch]
  );

  const handleShowTaDropdownChange = useCallback(
    (value: boolean) => {
      dispatch(Actions.showTaDropdownAction(value));
    },
    [dispatch]
  );

  const handleSearchCompanyByName = useCallback(
    (value: string) => {
      dispatch(Actions.searchCompanyByNameAction(value));
    },
    [dispatch]
  );

  const handleShowCompanyDropdownChange = useCallback(
    (value: boolean) => {
      dispatch(Actions.showCompanyDropdownAction(value));
    },
    [dispatch]
  );

  const handleTaNameChange = (taFullName: string) => {
    const isNewTa = !selectedTa || getTaFullName(selectedTa) !== taFullName;
    if (isNewTa) {
      const selectedTA = (travelAgents || []).find(ta => getTaFullName(ta) === taFullName) || null;
      dispatch(Actions.selectedTaChangeAction(selectedTA));
    } else {
      // if we select same TA - just update text input, cause it could be changed
      handleSearchTaByName(taFullName);
    }
  };

  const handleCompanyNameChange = (name: string) => {
    const isNewCompany = !selectedCompany || selectedCompany.name !== name;
    if (isNewCompany) {
      const selectedCompany = (companies || []).find(c => c.name === name) || null;
      dispatch(Actions.clearSelectedTaAction());
      dispatch(Actions.selectedCompanyChangeAction(selectedCompany));
      dispatch(Actions.setTravelCompanyMembershipAction(null)); // OWA-5724
      if (selectedCompany) {
        dispatch(AgentsActions.getMembershipsRequestAction(selectedCompany.uuid, false, true));
      }
    } else {
      // if we select same copmany - just update text input, cause it could be changed
      handleSearchCompanyByName(name);
    }
  };

  const handleSetSelectedCompanyMembership = (value: ICompanyMembership) => {
    dispatch(Actions.setTravelCompanyMembershipAction(value));
  };

  return (
    <StyledSearchBar
      isSr={isSr}
      initialized={initialized}
      isLoading={isFetchingNameSearch}
      isSearchIconVisible
      canSrSearch
      searchQuery={searchQuery}
      activeLodingIndex={activeLodingIndex}
      totalGuestCount={totalGuestCount}
      showLodgingControls={showLodgingControls}
      nameSearchResults={nameSearchResults}
      showNameSearchResults={showNameSearchResults}
      totalStayNights={totalStayNights}
      dateRange={dateRange}
      dateRangeDisplayString={dateRangeDisplayString}
      selectedDates={selectedDates}
      currentDate={currentDate}
      dateSelectionInProgress={dateSelectionInProgress}
      showDatePicker={showDatePicker}
      isRepeatGuest={isRepeatGuest}
      canSearch={canSearch}
      destinationList={destinationList}
      incrementRoom={handleIncrementRoom}
      incrementAdult={handleIncrementAdult}
      incrementChild={handleIncrementChild}
      setAge={handleSetAge}
      setActiveLodgingIndex={handleSetActiveLodgingIndex}
      incrementActiveLodgingIndex={handleIncrementActiveLodgingIndex}
      toggleRepeatGuest={handleToggleRepeatGuest}
      setLogdingControlsVisibility={handleSetLogdingControlsVisibility}
      destinationChange={handleDestinationChange}
      guestCountryChangeAction={handleGuestCountryChange}
      basicSearchHandlers={{ 
        handleDestinationChange: handleDestinationSearch,
        handleNavigateToSearch,
        handleToggleLodgingControls,
        handleSetLogdingControlsVisibility,
        handleShowNameSearchDropDown,
        handleDateChange,
      }}
      searchTaByName={handleSearchTaByName}
      showTaDropdownChange={handleShowTaDropdownChange}
      searchCompanyByName={handleSearchCompanyByName}
      showCompanyDropdownChange={handleShowCompanyDropdownChange}
      handleTaNameChange={handleTaNameChange}
      handleCompanyNameChange={handleCompanyNameChange}
      travelAgents={travelAgents}
      isFetchingTA={isFetchingTA}
      showTaDropdown={showTaDropdown}
      selectedTa={selectedTa}
      taNames={taNames}
      taNameSearch={taNameSearch}
      companies={companies}
      isFetchingCompanies={isFetchingCompanies}
      companiesNames={companiesNames}
      selectedCompany={selectedCompany}
      showCompanyDropdown={showCompanyDropdown}
      companyNameSearch={companyNameSearch}
      companyMemberships={companyMemberships}
      selectedCompanyMembership={selectedCompanyMembership}
      setSelectedCompanyMembership={handleSetSelectedCompanyMembership}
    />
  );
};
