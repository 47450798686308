import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import { TaskCommentView } from './TaskCommentView';
import { TaskCommentEdit } from './TaskCommentEdit';
import { TaskCommentCreate } from './TaskCommentCreate';
import { focusedCommentsSelector, listTaskCommentsSelector } from 'store/modules/taskManagement/selectors';
import { addFocusedCommentAction, listTaskCommentsRequestAction, removeFocusedCommentAction } from 'store/modules/taskManagement/actions';

interface ITaskCommentsProps {
  taskId: string;
}

export const TaskComments: React.FC<ITaskCommentsProps> = React.memo(({ taskId }) => {
  const comments = useSelector(listTaskCommentsSelector);
  const dispatch = useDispatch();
  const focusedComments = useSelector(focusedCommentsSelector);
  const isAddCommentFocused = focusedComments.includes(null);

  useEffect(() => {
    dispatch(listTaskCommentsRequestAction(taskId));
  }, [dispatch, taskId]);

  const handleOnBlur = useCallback(() => {
    dispatch(removeFocusedCommentAction(null));
  }, [dispatch]);
  
  const handleOnFocus = useCallback(() => {
    dispatch(addFocusedCommentAction(null));
  }, [dispatch]);

  return (
    <div className="comments flex flex-col items-start gap-[20px]">
      <p className="font-pt-sans text-black m-0 text-[16px] font-bold">Comments</p>
      {!isAddCommentFocused && <TaskCommentCreate onFocus={handleOnFocus} />}
      {/* setting value '' while edit functionality is not implemented */}
      {isAddCommentFocused && <TaskCommentEdit value={''} onCancel={handleOnBlur} taskId={taskId} commentId={null} />}
      {comments.map(comment => <TaskCommentView key={comment.id} comment={comment} />)}
    </div>
  )
});
