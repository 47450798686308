import classnames from 'classnames';
import { addDays, format } from 'date-fns';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import React, { FormEvent, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assigneeFilterSelector, bookingRefFilterSelector, categoriesFilterSelector, datesFilterSelector, getAssigneesRequestSelector, getFilterInfoRequestSelector, getReportersRequestSelector, getTaskAssigneesSelector, getTaskCategoriesSelector, getTaskReportersSelector, getTaskTeamsSelector, guestNameFilterSelector, idFilterSelector, priorityFilterSelector, reporterFilterSelector, statusesFilterSelector, teamFilterSelector } from 'store/modules/taskManagement/selectors';
import { AsyncSearchDropdown } from 'ui/AsyncSearchDropdown';
import { IMultiselectValueLabelPair, Multiselect } from 'ui/Multiselect';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';
import { generateArrayOfDatesBetween } from 'utils';
import DateRangeInput from 'pureUi/DateRangeInput';
import * as TaskManagementActions from 'store/modules/taskManagement/actions';
import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus, IUser } from 'services/BackendApi';
import { useHistory } from 'react-router';
import { taskPriorityOptions, taskStatusOptions } from './helpers';
import { ETaskStatus } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { StyledDateRangeInput } from 'containers/BookingList/StyledFilters';
import { getCurrentUser } from 'store/modules/auth';

export const TaskFilters: React.FC= React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser) as IUser;
  const taskCategoryOptions = useSelector(getTaskCategoriesSelector);
  const taskTeamOptions = useSelector(getTaskTeamsSelector);
  const assignees = useSelector(getTaskAssigneesSelector);
  const getAssigneesRequest = useSelector(getAssigneesRequestSelector);
  const reporters = useSelector(getTaskReportersSelector);
  const getReportersRequest = useSelector(getReportersRequestSelector);

  const idFilter = useSelector(idFilterSelector);
  const categoriesFilter = useSelector(categoriesFilterSelector);
  const statusesFilter = useSelector(statusesFilterSelector);
  const teamFilter = useSelector(teamFilterSelector);
  const assigneeFilter = useSelector(assigneeFilterSelector);
  const priorityFilter = useSelector(priorityFilterSelector);
  const dates = useSelector(datesFilterSelector);
  const bookingRefFilter = useSelector(bookingRefFilterSelector);
  const guestNameFilter = useSelector(guestNameFilterSelector);
  const reporterFilter = useSelector(reporterFilterSelector);

  const [assigneeQueryText, setAssigneeText] = useState(assigneeFilter);
  const [reporterQueryText, setReporterText] = useState(reporterFilter);

  useLayoutEffect(() => {
    const initialStatuses = [ETaskStatus.BACKLOG, ETaskStatus.TO_DO, ETaskStatus.IN_PROGRESS];
    const initialDates = generateArrayOfDatesBetween(new Date(), addDays(new Date(), 3))
      .map(date => new Date(date).toISOString());
    dispatch(TaskManagementActions.setStatusFilterAction(initialStatuses));
    dispatch(TaskManagementActions.setDatesFilterAction(initialDates));

    dispatch(TaskManagementActions.getFilterInfoRequestAction());
    dispatch(TaskManagementActions.getUsersRequestAction('', 'both'));
  }, []);

  const selectedDates: string[] = dates.length ? dates : generateArrayOfDatesBetween(new Date(), addDays(new Date(), 3))
    .map(date => new Date(date).toISOString());
  const categoryErrorMessage = '';
  const statusErrorMessage = '';

  
  const categoryOptions: IMultiselectValueLabelPair[] = taskCategoryOptions?.map(item => ({
    value: item.id,
    label: item.name,
  })) ?? [];

  const teamOptions: ISingleSelectOption[] = taskTeamOptions?.map(item => ({
    value: item.id,
    label: item.name,
  }) ?? []);

  const assigneeOptions: ISingleSelectOption[] = [{ value: 'no_assignment', label: 'No assignment' }, ...(assignees
    ?.map(user => ({
      value: user.uuid,
      label: user.firstName + ' ' + user.lastName,
    })) ?? [])];

  const reporterOptions: ISingleSelectOption[] = [{ value: 'all', label: 'No assignment' }, ...(reporters
    ?.map(user => ({
      value: user.uuid,
      label: user.firstName + ' ' + user.lastName,
    })) ?? [])];

  const handleIdFilterChange = useCallback(e => {
    dispatch(TaskManagementActions.setIdFilterAction(e.target.value || null));
  }, [dispatch]);

  const handleCategoryFilterChange = useCallback((selectedValues: string[]) => {
    dispatch(TaskManagementActions.setCategoryFilterAction(selectedValues));
  }, [dispatch]);

  const handleStatusFilterChange = useCallback(selectedValues => {
    dispatch(TaskManagementActions.setStatusFilterAction(selectedValues));
  }, [dispatch]);

  const handleTeamFilterChange = useCallback((value: string) => {
    dispatch(TaskManagementActions.setTeamFilterAction(value));
  }, [dispatch]);

  const handleAssigneeFilterChange = useCallback(value => {
    setAssigneeText(value);
    if (value === '') {
      dispatch(TaskManagementActions.setAssigneeFilterAction('all'));
    }
    dispatch(TaskManagementActions.getUsersRequestAction(value, 'assignee'));
  }, [dispatch]);

  const handleAssigneeFilterChoose = useCallback((value: string) => {
    const selectedAssignee = assigneeOptions.find(item => item.value === value);
    setAssigneeText(selectedAssignee?.label ?? '');
    dispatch(TaskManagementActions.setAssigneeFilterAction(value));
    dispatch(TaskManagementActions.getUsersRequestAction('', 'assignee'));
  }, [assigneeOptions, dispatch]);

  const handlePriorityFilterChange = useCallback((value: string) => {
    dispatch(TaskManagementActions.setPriorityFilterAction(value));
  }, [dispatch]);

  const handleBookingRefFilterChange = useCallback(e => {
    dispatch(TaskManagementActions.setBookingRefFilterAction(e.target.value || null));
  }, [dispatch]);

  const handleDatesFilterChange = useCallback(dates => {
    dispatch(TaskManagementActions.setDatesFilterAction(dates));
  }, []);

  const handleAssignToMe = useCallback(() => {
    const selectedAssignee = assigneeOptions.find(item => item.value === currentUser.uuid);
    setAssigneeText(selectedAssignee?.label ?? '');
    dispatch(TaskManagementActions.setAssigneeFilterAction(currentUser.uuid));
  }, [dispatch, assigneeOptions, currentUser]);

  const handleGuestFilterChange = useCallback(e => {
    dispatch(TaskManagementActions.setGuestNameFilterAction(e.target.value || null));
  }, [dispatch]);

  const handleReporterFilterChange = useCallback((value: string) => {
    dispatch(TaskManagementActions.getUsersRequestAction(value, 'reporter'));
    setReporterText(value);
    if (value === '') {
      dispatch(TaskManagementActions.setReporterFilterAction('all'));
    }
  }, []);

  const handleReporterFilterChoose = useCallback((value: string) => {
    const selectedReporter = reporterOptions.find(item => item.value === value);
    setReporterText(selectedReporter?.label ?? '');
    dispatch(TaskManagementActions.setReporterFilterAction(value));
    dispatch(TaskManagementActions.getUsersRequestAction('', 'reporter'));
  }, [reporterOptions, dispatch]);

  const handleSearch = useCallback(() => {
    dispatch(TaskManagementActions.getTaskListRequestAction());
  }, [dispatch]);
  
  const handleCreateTask = useCallback(() => {
    history.push('/tasks/create');
  }, [history]);

  const handleResetFilters = useCallback(() => {
    dispatch(TaskManagementActions.setIdFilterAction(''));
    dispatch(TaskManagementActions.setCategoryFilterAction([]));
    dispatch(TaskManagementActions.setTeamFilterAction(''));
    dispatch(TaskManagementActions.setAssigneeFilterAction(''));
    dispatch(TaskManagementActions.setPriorityFilterAction(''));
    dispatch(TaskManagementActions.setBookingRefFilterAction(''));
    dispatch(TaskManagementActions.setGuestNameFilterAction(''));
    dispatch(TaskManagementActions.setReporterFilterAction(''));
    setReporterText('');
    setAssigneeText('');
    const initialStatuses = [ETaskStatus.BACKLOG, ETaskStatus.TO_DO, ETaskStatus.IN_PROGRESS];
    const initialDates = generateArrayOfDatesBetween(new Date(), addDays(new Date(), 3))
      .map(date => new Date(date).toISOString());
    dispatch(TaskManagementActions.setStatusFilterAction(initialStatuses));
    dispatch(TaskManagementActions.setDatesFilterAction(initialDates));
    dispatch(TaskManagementActions.getTaskListRequestAction());
  }, []);

  return (
    <div className="task-list-filters mt-5 mb-5">
      <p className="task-list-filters-header font-pt-sans font-bold text-17px leading-22px">Filter Results</p>
      <div className="filters-container flex flex-wrap gap-x-[20px] gap-y-[15px] mt-10px">
        <TextInput
          id="task-id-filter"
          label="Id."
          className="task-id-filter min-w-[100px] max-w-[100px]"
          value={idFilter}
          onChange={handleIdFilterChange}
        />

        <div className="task-category-filter flex-1 min-w-[180px] max-w-[180px] relative top-[-2px]">
          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs">Category</label>
          <Multiselect
            className="multiselect-category bg-ivory mt-[4px] text-15px"
            selectedItemContentClassName="font-bold"
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-ivory"
            onUpdate={handleCategoryFilterChange}
            options={categoryOptions}
            selectedValues={categoriesFilter}
            isIncludeClearButton
          />
          {categoryErrorMessage && (
            <p className="text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ">{categoryErrorMessage}</p>
          )}
        </div>

        <div className="task-category-filter flex-1 min-w-[250px] max-w-[250px] relative top-[-2px]">
          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs">Status</label>
          <Multiselect
            selectedItemContentClassName="font-bold"
            className="multiselect-status bg-ivory mt-[4px] text-15px"
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-ivory"
            onUpdate={handleStatusFilterChange}
            options={taskStatusOptions}
            selectedValues={statusesFilter}
            isIncludeClearButton
          />
          {statusErrorMessage && (
            <p className="text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ">{statusErrorMessage}</p>
          )}
        </div>

        <SingleSelect
          fieldId="task-team-filter"
          label="Team"
          className="task-team-filter min-w-[180px] flex-1"
          value={teamFilter ?? ''}
          options={teamOptions}
          onChange={handleTeamFilterChange}
          maxVisibleItems={6}
          labelWhenNothingSelected="All"
          useCustomLabelWhenNothingSelected
        />

        <div className="relative">
          <AsyncSearchDropdown
            fieldId="assignee"
            label="Assignee"
            className="assignee min-w-[180px] flex-1"
            value={assigneeQueryText ?? undefined}
            selectedValue={assigneeFilter ?? undefined}
            options={assigneeOptions}
            onChoose={handleAssigneeFilterChoose}
            onChange={handleAssigneeFilterChange}
            maxVisibleItems={8}
            loading={getAssigneesRequest === ENetworkRequestStatus.PENDING}
            errorMessage={null}
            errorClassName="assignee-error"
          />
          <span
            className="assign-to-me absolute font-pt-sans underline text-[13px] leading-[17px] text-brown-100 cursor-pointer top-0 right-[2px]"
            onClick={handleAssignToMe}
          >
            Assigned to me
          </span>
        </div>

        <SingleSelect
          fieldId="task-priority-filter"
          label="Priority"
          className="task-priority-filter min-w-[180px] max-w-[180px]"
          labelWhenNothingSelected="All"
          useCustomLabelWhenNothingSelected
          value={priorityFilter ?? undefined}
          options={taskPriorityOptions}
          onChange={handlePriorityFilterChange}
          maxVisibleItems={6}
        />
      </div>

      <div className="filters-container flex flex-wrap gap-x-[20px] gap-y-[15px] mt-[15px]">
        <div className="task-dates-filter flex flex-col flex-1 relative min-w-[180px] max-w-[180px] ">
          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px]">Deadline</label>
          <div className="relative">
            <DatePickerStateProvider
              defaultSelectedDates={selectedDates}
              onDateChange={handleDatesFilterChange}
              render={(params: IDatePickerSateParams) => {
                return (
                  <StyledDateRangeInput
                    displayString={params.displayString}
                    currentDate={params.datePickerCurrentDate}
                    totalNights={params.totalNights}
                    selectedDates={params.selectedDates}
                    onDayClick={params.handleDayClick}
                    onDayMouseOver={params.handleDateMouseOver}
                    showDatePicker={params.showDatePicker}
                    onNextClick={params.incrementDate}
                    onPrevClick={params.decrementDate}
                    onMouseDown={params.toggleDatePicker}
                    onClickOutside={params.hideDatePicker}
                    showTotalNights={false}
                    enablePastDates
                    noPortal
                    calendarCount={2}
                    customWidth={2}
                  />
                );
              }}
            />
          </div>
        </div>

        <TextInput
          id="task-booking-filter"
          label="Booking Ref."
          className="task-booking-ref-filter min-w-[180px] max-w-[180px]"
          value={bookingRefFilter}
          onChange={handleBookingRefFilterChange}
        />

        <TextInput
          id="task-guest-filter"
          label="Guest Name"
          className="task-guest-filter flex-1"
          value={guestNameFilter}
          onChange={handleGuestFilterChange}
        />

        <AsyncSearchDropdown
          fieldId="task-reporter-filter"
          label="Reporter"
          className="task-reporter-filter min-w-[180px] flex-1"
          value={reporterQueryText}
          selectedValue={reporterFilter}
          options={reporterOptions}
          onChoose={handleReporterFilterChoose}
          onChange={handleReporterFilterChange}
          maxVisibleItems={8}
          loading={getReportersRequest === ENetworkRequestStatus.PENDING}
          errorMessage={null}
          errorClassName="reporter-error"
        />
      </div>

      <div className="buttons-container flex justify-between items-center mt-[15px]">
        <div className="buttons-container-left flex items-center gap-[20px]">
          <FluidButton type="secondary" className="" onClick={handleResetFilters}>
            Reset Filters
          </FluidButton>
          <FluidButton type="primary" className="" onClick={handleSearch}>
            Search
          </FluidButton>
        </div>
        <div className="buttons-container-left flex items-center gap-[20px]">
          <FluidButton type="primary" className="" onClick={handleCreateTask}>
            + New Task
          </FluidButton>
        </div>
      </div>
    </div>
  );
});
