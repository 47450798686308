import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash-es';
import classnames from 'classnames';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isTA as isTASelector, isSRRole as isSRSelector } from 'store/modules/auth';
import { GuestAges, ICompany, LiveRateListItemWithGuestAges } from 'services/BackendApi';
import { getCurrencySymbol, formatPrice } from 'utils';
import Tooltip from 'pureUi/Tooltip';
import MousePositionProvider from 'pureUi/providers/MousePositionProvider';
import { RateMixingWarning } from 'components/RateMixingWarning';
import { AvailabilityTag, eAvailabilityTagStatus } from './AvailabilityTag';
import { RefundabilityTag, ERefundabilityTagStatus } from './RefundabilityTag';
import { BasketRateTypes, TCurrencyCode } from 'interfaces';
import ClockIcon from 'ui/Icons/clock.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { composeCancellationPolicyFromRoomExpenseInfo, isRefundable as isRefundableFn } from 'common-lib/cancellation-policy-composer';
import { RateInclusions } from './RateInclusions';
import { companyDataSelector } from 'store/modules/companyInfo';

export const normalizeArray = a => (a || []).sort();

export interface ILiveRateRequestData extends GuestAges {
  externalRateId: string;
  externalMealPlanCode: string;
  externalMealPlanDescription: string;
  amount: number;
}

const InfoTooltip = ({ content }: { content: JSX.Element }) => {
  return (
    <MousePositionProvider
      className="acc-free-sale-rates-info-container flex items-center"
      render={mousePosition => {
        return (
          <>
            <i className="acc-free-sale-rates-info fas fa-info-circle ml-10px text-gray-40 hover:text-gray-80 active:text-gray-80" />
            {mousePosition.isOver && (
              // @ts-ignore
              <Tooltip position={mousePosition}>{content}</Tooltip>
            )}
          </>
        );
      }}
    />
  );
};

const ExpiredCancellationPolicyTooltip = props => {
  const { cancellationPolicy } = props;
  return (
    <MousePositionProvider
      className="expired-cancellation-policy-tooltip-container"
      render={mousePosition => {
        return (
          <>
            <SvgIcon
              key={cancellationPolicy.cancellationPolicy + 'icon'}
              IconComponent={ClockIcon}
              width="22px"
              height="22px"
              className="p-1"
              defaultFill={theme.colors['gray-80']}
              hoverFill={theme.colors['gray-80']}
              activeFill={theme.colors['gray-80']}
            />
            {mousePosition.isOver && (
              // @ts-ignore
              <Tooltip position={mousePosition}>
                <span className="expired-cancellation-policy-tooltip px-2 py-1">
                  This cancellation deadline has passed.
                </span>
              </Tooltip>
            )}
          </>
        );
      }}
    />
  );
};

interface IGuestAgeSetInfoProps {
  adults: number;
  children: number;
}

const GuestAgeSetInfo: React.FC<IGuestAgeSetInfoProps> = ({ adults, children }) => (
  <span className="block text-brown-prime mb-10px">
    <span className="uppercase text-14px font-semibold mr-5px">{adults + children} Guest</span>
    <span>{adults} Adults</span>
    {children > 0 && (
      <span className="">
        {' '}
        / {children} {children > 1 ? 'Children' : 'Child'}
      </span>
    )}
  </span>
);

interface IInstantBookBlockProps {
  rates: LiveRateListItemWithGuestAges[];
  accommodationProduct: any;
  addRoom: (liveRate: ILiveRateRequestData) => void;
  basketRateType?: 'static' | 'live' | 'free_sale';
  blockLabel: string;
  blockInfoTooltipContent: JSX.Element;
  forBasketType: BasketRateTypes;
  currency: TCurrencyCode;
}

export const InstantBookBlock: React.FC<IInstantBookBlockProps> = (props: IInstantBookBlockProps) => {
  const { rates, basketRateType } = props;
  const { dynamicParameters } = useDynamicParameters();

  const isSRRole = useSelector(isSRSelector);
  const isTA = useSelector(isTASelector);
  const taCompany = useSelector(companyDataSelector) as ICompany;

  const userCanBook = isTA || isSRRole;
  const isLiveRatesEnabled = isTA
  ? (dynamicParameters.ENABLE_V1_LIVE_RATES && taCompany?.enableInstantBooking)
  : (dynamicParameters.ENABLE_V1_LIVE_RATES || (dynamicParameters.ENABLE_V1_LIVE_RATES_SRS && isSRRole));
  const ratesGroupedByAgeSet = groupBy(rates, fsr => {
    return `${fsr.guestAges.numberOfAdults}${fsr.guestAges.agesOfAllChildren?.join(',') || ''}`;
  });

  // we're NOT allowed to add a rate of this type to the basket if:
  // - the basket rate type IS set, so we've got something in the basket
  // - but its not of the same type as the rate we're trying to add
  const isNotAllowedToBeAddedToBasket =
    props.basketRateType !== undefined && props.basketRateType !== props.forBasketType;

  return (
    <div className={`acc-free-sale-rates font-hurmegeometric-sans mt-0 mx-12px mb-15px p-15px rounded-1px bg-gray-7`}>
      <div className="flex items-center">
        <p className="acc-free-sale-rates-title not-italic font-bold text-sm leading-17px uppercase text-flint">
          {props.blockLabel}
        </p>
        <InfoTooltip content={props.blockInfoTooltipContent} />
      </div>
      <div className="acc-free-sale-rates-tagline mb-15px">
        <AvailabilityTag availabilityStatus={eAvailabilityTagStatus.AVAILABLE} className="acc-free-sale-rates-tag">
          AVAILABLE
        </AvailabilityTag>
      </div>

      {isNotAllowedToBeAddedToBasket && <RateMixingWarning isSr={isSRRole} />}

      <div className="space-y-[25px]">
        {Object.keys(ratesGroupedByAgeSet).map(freeSaleRatesAgeSetKey => {
          const freeSaleRatesPerAgeSet = ratesGroupedByAgeSet[freeSaleRatesAgeSetKey];
          return (
            <div key={`${freeSaleRatesAgeSetKey}-${freeSaleRatesPerAgeSet[0].externalRateId}`}>
              <GuestAgeSetInfo
                adults={freeSaleRatesPerAgeSet[0].guestAges.numberOfAdults}
                children={freeSaleRatesPerAgeSet[0].guestAges.agesOfAllChildren?.length || 0}
              />
              <div className="space-y-20px">
                {freeSaleRatesPerAgeSet.map((rate, index) => {
                  const { numberOfAdults, agesOfAllChildren } = rate.guestAges;
                  const key = `${numberOfAdults}${agesOfAllChildren?.join(',')}${rate.externalRateId}${
                    rate.mealPlanCode
                  }`;

                  if (freeSaleRatesPerAgeSet.length === 0) {
                    return null;
                  }

                  const cancelInfo = {
                    expenseDeadlines: (rate.expensesDeadlines || []).map(ed => ({
                      ...ed,
                      amount: ed.amount / 100,
                    })),
                    isLive: true,
                    isRefundable: null,
                    manualCancellationPolicy: [],
                  };

                  const isRefundable = isRefundableFn(cancelInfo);

                  const texts = composeCancellationPolicyFromRoomExpenseInfo(
                    cancelInfo,
                    {
                      currencySymbol: getCurrencySymbol(props.currency),
                      appendLines: ['*at 00.00 time at destination'],
                    }
                  );

                  return (
                    <div className="acc-free-rate-item last:pb-0 last:border-b-0" key={key}>
                      <div
                        key={rate.externalRateId}
                        data-index={index}
                        className="live-rate-listing font-hurmegeometric-sans"
                      >
                        <div className="flex flex-row items-start justify-between">
                          <div>
                            <span className="block uppercase text-brown-prime font-bold text-xs leading-14px">
                              {rate.mealPlanDescription}. TAX INCLUDED
                            </span>
                            {rate.features && <RateInclusions key={rate.externalRateId} features={rate.features}/>}
                            <RefundabilityTag
                              cancellationStatus={
                                isRefundable
                                  ? ERefundabilityTagStatus.REFUNDABLE
                                  : ERefundabilityTagStatus.NONREFUNDABLE
                              }
                              className="acc-free-sale-rates-tag flex items-center"
                            />

                            {!!rate.expensesDeadlines?.length && (
                              <>
                                <div>
                                  {texts.map(cp => {
                                    return (
                                      <div key={cp} className="flex flex-row items-center">
                                        <span className={classnames('block mr-2')}>{cp}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                            {!rate.expensesDeadlines?.length && (
                              <div>Please, confirm the cancellation policy of this rate with your sales contact</div>
                            )}
                          </div>
                          <div className="flex flex-row items-center">
                            <span className="mr-10px text-17px text-bistre">
                              {`${getCurrencySymbol(props.currency)}${formatPrice(rate.totalCents / 100)}`}
                            </span>
                            {isLiveRatesEnabled && userCanBook && (
                              <button
                                disabled={isNotAllowedToBeAddedToBasket}
                                onClick={() => {
                                  if (isNotAllowedToBeAddedToBasket) {
                                    return;
                                  }

                                  props.addRoom({
                                    ...props.accommodationProduct,
                                    liveRateData: {
                                      externalRateId: rate.externalRateId,
                                      externalMealPlanCode: rate.mealPlanCode,
                                      externalMealPlanDescription: rate.mealPlanDescription,
                                      amount: rate.totalCents,
                                      numberOfAdults: rate.guestAges.numberOfAdults,
                                      agesOfAllChildren: rate.guestAges.agesOfAllChildren ?? [],
                                    },
                                  });
                                }}
                                style={{
                                  width: '81px',
                                }}
                                className={classnames(
                                  `px-5px font-hurmegeometric-sans font-semibold border-none outline-none bg-brown-prime block h-35px text-white text-14px leading-17px uppercase`,
                                  {
                                    // not disabled
                                    'hover:bg-marine cursor-pointer': basketRateType !== BasketRateTypes.STATIC,
                                    // disabled
                                    'opacity-50': basketRateType === BasketRateTypes.STATIC,
                                  }
                                )}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
