import { TCountryCode } from 'interfaces';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { ICompanyHotelMarkup } from 'services/BackendApi/types/CHMarkupResponse';

export interface ICompanyHotel {
  uuid: string;
  name: string;
  countryCode: TCountryCode;
}

export interface ICompanyHotelMarkupModel extends ICompanyHotelMarkup {
  selected: boolean;
  isEditMode: boolean;
  editedValue: string;
}

export type CHMarkupSortField = 'hotelName' | 'hotelCountryCode' | 'markupPercentage' | 'createdAt';
export type CHMarkupSortOrder = 'asc' | 'desc';

export interface ChMarkupToDelete {
  companyUuid: string;
  hotelUuid: string;
}

export interface IBulkEdit {
  editedValue: string;
}

export interface IBulkDelete {
}

export interface ICompanyHotelMarkupDomain {
  companyUuid: string | null;
  list: ICompanyHotelMarkupModel[];
  listLoad: ENetworkRequestStatus;
  listLoadError?: Error;
  sortField?: CHMarkupSortField;
  sortOrder?: CHMarkupSortOrder;
  
  addMarkupModalOpen: boolean;
  availableHotels: ICompanyHotel[];
  availableHotelsLoad: ENetworkRequestStatus;
  availableHotelsError?: Error;
  createMarkupLoad: ENetworkRequestStatus;
  createMarkupError?: Error;
  
  editMarkupLoad: ENetworkRequestStatus;
  editMarkupError?: Error;

  chMarkupToDelete?: ChMarkupToDelete;
  deleteLoad: ENetworkRequestStatus;
  deleteError?: Error;
  
  bulkEdit: IBulkEdit | null;
  bulkDelete: IBulkDelete | null;

  productsWithCompanyHotelMarkup: string[];
  productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus;
  productsWithCompanyHotelMarkupError?: Error;
}

export const initialState: ICompanyHotelMarkupDomain = {
  companyUuid: null,
  list: [],
  listLoad: ENetworkRequestStatus.IDLE,
  addMarkupModalOpen: false,
  availableHotels: [],
  availableHotelsLoad: ENetworkRequestStatus.IDLE,
  createMarkupLoad: ENetworkRequestStatus.IDLE,
  editMarkupLoad: ENetworkRequestStatus.IDLE,
  deleteLoad: ENetworkRequestStatus.IDLE,
  bulkEdit: null,
  bulkDelete: null,
  productsWithCompanyHotelMarkup: [],
  productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus.IDLE,
};
