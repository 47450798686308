import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, Formik, FormikProps, ErrorMessage } from 'formik';
import { addYears, differenceInDays } from 'date-fns';
import classNames from 'classnames';
import * as axios from 'axios';

import TextInput from 'pureUi/TextInput';
import { IGuest } from 'services/BackendApi/types';
import { guestTitles, guestTypes } from 'utils/guestInformation';
import SingleDateInput from 'pureUi/SingleDateInput';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';

import { GuestInformationFormStyles } from './GuestInformationFormStyles';
import { GuestInformationFormValidationSchema } from './formValidation';
import { formatDate } from '../../utils';
import { FormLabel } from './FormLabel';
import FluidButton from 'ui/FluidButton';
import { StyledRadio } from 'pureUi/StyledRadio';
import { createEmptyGuest, getAge, IFormValues, makeIsFieldErrorFunction } from './helpers';
import TrashIcon from 'ui/Icons/trash.component.svg';
import { IMultiselectValueLabelPair, Multiselect } from 'ui/Multiselect';
import {
  CONST_AIRLINE_OTHER_LABEL,
  CONST_AIRLINE_OTHER_VALUE,
} from 'services/BackendApi/types/GuestInformationResponse';
import { isNil } from 'utils/string';
import { isBlank, formatDateDisplay } from 'utils';
import countryPhoneCodes from 'utils/countryPhoneCodes';
import ticketClasses from 'utils/ticketClasses';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';
import SingleSelect from 'ui/SingleSelect';

import countries from 'config/data/countries.json';
import { HidingTooltip } from 'ui/Tooltip';
import InfoIcon from 'ui/Icons/Info.component.svg';

import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  EAncillaryLocationType,
  EAncillaryProductType,
  LocationFromSearchLocationsWithType,
} from 'services/BookingManagerApi/types/AncillaryService';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { BreakdownTextArea } from '../../containers/BookingManagerBreakdown/BreakdownTextArea';
import CloseIcon from 'ui/Icons/close.component.svg';

const countryOptions = Object.values(countries).map(country => ({
  value: country.code,
  label: country.name,
}));

interface IGuestInformationFormProps {
  guests: IGuest[];
  onSave: (guests: IGuest[]) => void;
  isSaving: boolean;
  airlines: { name: string; iataCode: string }[];
  canEdit: boolean;
}

const timeFormat = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const GuestInformationForm: React.FC<IGuestInformationFormProps> = props => {
  const { airlines, canEdit } = props;

  const bookingManagerApi = makeBookingManagerApi();

  const airlineIataCodes = airlines.map(al => al.iataCode);

  let airlinesWithValueAndLabel = airlines.map(al => {
    return {
      ...al,
      value: al.iataCode,
      label: `${al.name} (${al.iataCode})`,
    };
  });

  airlinesWithValueAndLabel = [
    ...airlinesWithValueAndLabel,
    {
      name: CONST_AIRLINE_OTHER_VALUE,
      iataCode: CONST_AIRLINE_OTHER_VALUE,
      value: CONST_AIRLINE_OTHER_VALUE,
      label: CONST_AIRLINE_OTHER_LABEL,
    },
  ];

  const handleFormSubmit = useCallback((values: IFormValues) => {
    const guests = values.guests.map((guest: IGuest, index: number) => {
      Object.keys(guest).forEach((key: string) => {
        if (guest[key] === '') {
          delete guest[key];
        }
        if (guest.title === guestTitles[0]) {
          guest.title = '';
        }
      });
      return guest;
    });

    props.onSave(guests);
  }, []);

  const handleSameAsLeadGuest = (guestIndex: number, { values, setFieldValue }: FormikProps<IFormValues>) => () => {
    const leadGuest = values.guests.find(item => item.isLeadGuest === true);
    if (leadGuest !== undefined) {
      setFieldValue(`guests.${guestIndex}.arrivalDate`, leadGuest.arrivalDate);
      setFieldValue(`guests.${guestIndex}.departureDate`, leadGuest.departureDate);
      setFieldValue(`guests.${guestIndex}.arrivalFlightNo`, leadGuest.arrivalFlightNo);
      setFieldValue(`guests.${guestIndex}.departureFlightNo`, leadGuest.departureFlightNo);
      setFieldValue(`guests.${guestIndex}.arrivalTime`, leadGuest.arrivalTime);
      setFieldValue(`guests.${guestIndex}.departureTime`, leadGuest.departureTime);
      setFieldValue(`guests.${guestIndex}.ticketClassArrival`, leadGuest.ticketClassArrival);
      setFieldValue(`guests.${guestIndex}.ticketClassDeparture`, leadGuest.ticketClassDeparture);
      setFieldValue(`guests.${guestIndex}.arrivalAirportId`, leadGuest.arrivalAirportId);
      setFieldValue(`guests.${guestIndex}.arrivalAirportIataCode`, leadGuest.arrivalAirportIataCode);
      setFieldValue(`guests.${guestIndex}.arrivalAirportName`, leadGuest.arrivalAirportName);
      setFieldValue(`guests.${guestIndex}.departureAirportId`, leadGuest.departureAirportId);
      setFieldValue(`guests.${guestIndex}.departureAirportIataCode`, leadGuest.departureAirportIataCode);
      setFieldValue(`guests.${guestIndex}.departureAirportName`, leadGuest.departureAirportName);
      setFieldValue(`guests.${guestIndex}.arrivalTerminal`, leadGuest.arrivalTerminal);
      setFieldValue(`guests.${guestIndex}.departureTerminal`, leadGuest.departureTerminal);
    }
  };

  const handleAirlineLookup = async (
    searchTerm: string,
    signal: AbortSignal,
    cancelTokenSource: axios.CancelTokenSource
  ) => {
    // if any of the data is null, things are still loading, error out
    const response = await bookingManagerApi.getAncillaryAirportsWithIATA(searchTerm, cancelTokenSource);

    return response.data.map(airport => {
      return {
        value: String(airport.id),
        label: `${airport.name} ${airport.iata_code ? `(${airport.iata_code})` : ''}`,
      };
    });
  };

  const guests = props.guests.map((guest: IGuest) => {
    guest.title = guest.title ?? '';
    guest.firstName = guest.firstName ?? '';
    guest.lastName = guest.lastName ?? '';
    guest.dateOfBirth = guest.dateOfBirth ?? undefined;
    guest.arrivalDate = guest.arrivalDate ?? undefined;
    guest.arrivalAirportId = guest.arrivalAirportId ?? undefined;
    guest.arrivalAirportIataCode = guest.arrivalAirportIataCode ?? undefined;
    guest.arrivalAirportName = guest.arrivalAirportName ?? undefined;
    guest.arrivalFlightNo = guest.arrivalFlightNo ?? '';
    guest.arrivalTerminal = guest.arrivalTerminal ?? '';
    guest.departureDate = guest.departureDate ?? undefined;
    guest.departureAirportId = guest.departureAirportId ?? undefined;
    guest.departureAirportIataCode = guest.departureAirportIataCode ?? undefined;
    guest.departureAirportName = guest.departureAirportName ?? undefined;
    guest.departureFlightNo = guest.departureFlightNo ?? '';
    guest.departureTerminal = guest.departureTerminal ?? '';
    guest.arrivalTime = guest.arrivalTime ?? '';
    guest.departureTime = guest.departureTime ?? '';
    guest.passportNo = guest.passportNo ?? '';
    guest.passportExpirationDate = guest.passportExpirationDate ?? undefined;
    guest.country = guest.country ?? '';
    guest.arrivalCIPBookingNumber = guest.arrivalCIPBookingNumber ?? '';
    guest.departureCIPBookingNumber = guest.departureCIPBookingNumber ?? '';
    guest.guestType = guest.guestType ?? undefined;
    guest.comment = guest.comment ?? undefined;
    guest.mobilePhoneCode = guest.mobilePhoneCode ?? undefined;
    guest.mobilePhone = guest.mobilePhone ?? undefined;

    guest.customArrivalAirlineName = guest.customArrivalAirlineName ?? '';
    guest.customArrivalAirlineIataCode = guest.customArrivalAirlineIataCode ?? '';
    guest.customDepartureAirlineName = guest.customDepartureAirlineName ?? '';
    guest.customDepartureAirlineIataCode = guest.customDepartureAirlineIataCode ?? '';

    if (!isNil(guest.arrivalAirlineName) && !isNil(guest.arrivalAirlineIataCode)) {
      if (airlineIataCodes.includes(guest.arrivalAirlineIataCode!)) {
        guest.arrivalAirlineName = guest.arrivalAirlineName;
        guest.arrivalAirlineIataCode = guest.arrivalAirlineIataCode;
      } else {
        // its a custom one
        guest.customArrivalAirlineName = guest.arrivalAirlineName!;
        guest.customArrivalAirlineIataCode = guest.arrivalAirlineIataCode!;
      }
    }

    if (!isNil(guest.departureAirlineName) && !isNil(guest.departureAirlineIataCode)) {
      if (airlineIataCodes.includes(guest.departureAirlineIataCode!)) {
        guest.departureAirlineName = guest.departureAirlineName;
        guest.departureAirlineIataCode = guest.departureAirlineIataCode;
      } else {
        // its a custom one
        guest.customDepartureAirlineName = guest.departureAirlineName!;
        guest.customDepartureAirlineIataCode = guest.departureAirlineIataCode!;
      }
    }

    return guest;
  });

  // there should always be a lead guest among guests
  const leadGuest = guests.find(item => !!item.isLeadGuest)!;
  const otherGuests = guests.filter(item => !item.isLeadGuest);

  const initialValues: IFormValues = { guests: [leadGuest, ...otherGuests] };

  const renderFormFirstRow = (
    guestIndex: number,
    { values, errors, touched }: FormikProps<IFormValues>,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    const isFieldError = makeIsFieldErrorFunction(errors, touched, guestIndex);
    const isLeadGuest = values.guests[guestIndex].isLeadGuest;

    return (
      <div className="guestFormRow guestFormFirstRow text-black grid gap-5 grid-cols-[130px_230fr_230fr_150fr_175fr_30fr]">
        <FormLabel className="title" text="Title">
          <Field name={`guests.${guestIndex}.title`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <Multiselect
                className="title bg-ivory"
                openClassName="outline outline-teal-100"
                itemsClassname="bg-white"
                itemCtaClassName="hover:bg-gray-10"
                onUpdate={sv => {
                  setFieldValue(name, sv[0]);
                }}
                disabled={!canEdit}
                selectedValues={value == null ? [] : [value]}
                isSingleSelectMode={true}
                isCloseOnSelect={true}
                options={guestTitles.map((title, idx) => ({
                  label: title,
                  value: idx > 0 ? title : '',
                }))}
              />
            )}
          </Field>
        </FormLabel>

        <FormLabel className="first-name" text="First name">
          <Field
            as={TextInput}
            disabled={!canEdit}
            name={`guests.${guestIndex}.firstName`}
            className={classNames({
              textInputField: true,
              fieldError: isFieldError('firstName'),
            })}
          />
          {/* @ts-ignore */}
          <ErrorMessage name={`guests.${guestIndex}.firstName`} className="errorMessage" component="div" />
        </FormLabel>

        <FormLabel className="last-name" text="Last name">
          <Field
            as={TextInput}
            disabled={!canEdit}
            name={`guests.${guestIndex}.lastName`}
            className={classNames({
              textInputField: true,
              fieldError: isFieldError('lastName'),
            })}
          />
          {/* @ts-ignore */}
          <ErrorMessage name={`guests.${guestIndex}.lastName`} className="errorMessage" component="div" />
        </FormLabel>

        <Field name={`guests.${guestIndex}.guestType`}>
          {({ field: { name, value }, form: { setFieldValue } }) => {
            return (
              <SingleSelect
                fieldId="guestType"
                label="Type"
                className="guest-type"
                value={value}
                options={guestTypes.map(guestType => ({
                  value: guestType,
                  label: guestType,
                }))}
                onChange={value => {
                  setFieldValue(name, value);
                }}
                labelWhenNothingSelected="Select ..."
                disabled={!canEdit}
              />
            );
          }}
        </Field>

        <div className="leadGuestRadioButton self-start relative top-[30px] flex items-center">
          <Field name={`guests.${guestIndex}.isLeadGuest`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <label
                className={`is-lead-guest inline-flex font-pt-sans text-base leading-19px text-black cursor-pointer`}
              >
                <StyledRadio
                  type="radio"
                  name="leadGuestIndex"
                  value={value}
                  onChange={e => {
                    values.guests.forEach((g, index) => {
                      setFieldValue(`guests.${index}.isLeadGuest`, index === guestIndex);
                    });
                  }}
                  checked={value}
                  disabled={!canEdit}
                />
                <span
                  className={classNames(
                    `surrogate relative inline-block w-4 h-4 mr-2 
                  bg-ivory border border-solid border-gray-40 rounded-full`,
                    {
                      disabled: !canEdit,
                    }
                  )}
                />
                This is the lead guest
              </label>
            )}
          </Field>
        </div>

        {!isLeadGuest && canEdit && (
          <div className="removeGuestButton cursor-pointer" onClick={() => arrayHelpers.remove(guestIndex)}>
            <SvgIcon
              IconComponent={TrashIcon}
              defaultFill={theme.colors['white']}
              hoverFill={theme.colors['gray-30']}
              activeFill={theme.colors['ivory']}
              width="30px"
              height="30px"
            />
          </div>
        )}
      </div>
    );
  };

  const renderFormSecondRow = (guestIndex: number, { values }: FormikProps<IFormValues>) => {
    const guest = values.guests[guestIndex];
    return (
      // columns should always equal 900
      <div className="guestFormRow grid gap-5 grid-cols-[130px_100px_200px_130px_1fr_1fr]">
        <FormLabel className="dob" text="Date of Birth">
          <Field name={`guests.${guestIndex}.dateOfBirth`}>
            {({ field: { name, value }, form: { setFieldValue } }) =>
              canEdit ? (
                <SingleDateInput
                  value={value && new Date(value)}
                  onChange={value => setFieldValue(name, formatDate(value))}
                  showYearDropdown
                  enablePastDates
                  minDate={addYears(new Date(), -150)}
                  maxDate={new Date()}
                  internalClassName={classNames('dob-field', {
                    dateInputField: true,
                    dateNotSelected: value === undefined,
                  })}
                />
              ) : (
                <TextInput
                  value={guest.dateOfBirth ? formatDateDisplay(guest.dateOfBirth) : ''}
                  className="dob-field textInputField"
                  disabled={true}
                  readOnly
                />
              )
            }
          </Field>
        </FormLabel>

        <div className="age-wrapper">
          {guest.dateOfBirth ? (
            <label className="age">
              <div className="flex items-center space-x-2">
                <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
                  Age
                </span>
                <HidingTooltip
                  renderTooltipContent={() => {
                    return (
                      <span
                        className="block text-13px leading-17px text-black p-10px font-pt-sans"
                        style={{ width: '200px' }}
                      >
                        This age is calculated from the guest's date of birth
                      </span>
                    );
                  }}
                  position="right-bottom"
                >
                  <InfoIcon className="-translate-y-3px w-[18px] scale-[0.8]" fill={theme.colors['gray-80']} />
                </HidingTooltip>
              </div>
              <TextInput className="textInputField age" value={getAge(guest)} disabled={true} readOnly />
            </label>
          ) : (
            <FormLabel className="age" text="Age">
              <Field
                as={TextInput}
                className="textInputField"
                name={`guests.${guestIndex}.manualAge`}
                disabled={!canEdit}
              />
            </FormLabel>
          )}
        </div>

        <div className="passport-wrapper">
          <FormLabel className="passport" text="Passport No.">
            <Field
              as={TextInput}
              name={`guests.${guestIndex}.passportNo`}
              className="textInputField"
              disabled={!canEdit}
            />
          </FormLabel>
        </div>

        <FormLabel className="dob" text="Expiration Date">
          <Field name={`guests.${guestIndex}.passportExpirationDate`}>
            {({ field: { name, value }, form: { setFieldValue } }) =>
              canEdit ? (
                <SingleDateInput
                  value={value && new Date(value)}
                  onChange={value => setFieldValue(name, formatDate(value))}
                  showYearDropdown
                  enablePastDates
                  minDate={addYears(new Date(), -150)}
                  maxDate={addYears(new Date(), 150)}
                  internalClassName={classNames('dob-field', {
                    dateInputField: true,
                    dateNotSelected: value === undefined,
                  })}
                />
              ) : (
                <TextInput
                  value={guest.passportExpirationDate ? formatDateDisplay(guest.passportExpirationDate) : ''}
                  className="dob-field textInputField"
                  disabled={true}
                  readOnly
                />
              )
            }
          </Field>
        </FormLabel>

        <Field name={`guests.${guestIndex}.country`}>
          {({ field: { name, value }, form: { setFieldValue } }) => {
            return (
              <SingleSelect
                fieldId="country"
                label="Country"
                className="country"
                value={value}
                options={countryOptions}
                onChange={value => {
                  setFieldValue(name, value);
                }}
                maxVisibleItems={10}
                labelWhenNothingSelected="Select ..."
                disabled={!canEdit}
              />
            );
          }}
        </Field>

        <div className="mobile-phone-fields flex flex-col space-x">
          <span className="font-pt-sans mb-5px default:text-black default:text-13px default:leading-17px default:tracking-2xs default:select-none">
            Mobile Phone
          </span>

          <div className="flex">
            <Field name={`guests.${guestIndex}.mobilePhoneCode`}>
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <Multiselect
                  className="bg-ivory mobile-phone-code o:min-w-[90px] o:w-[90px]"
                  itemCtaClassName="hover:bg-gray-10"
                  itemsClassname="bg-ivory o:w-[224px] border-t-1 -mt-[1px]"
                  selectedValues={value ? [value] : []}
                  placeholderText="Code"
                  options={countryPhoneCodes.map(({ country, code }, idx) => ({
                    label: `${country} (+${code})`,
                    value: code,
                  }))}
                  onUpdate={value => {
                    if (value.length > 0) {
                      setFieldValue(name, value[0]);
                    } else {
                      setFieldValue(name, '');
                    }
                  }}
                  height={33}
                  itemsToDisplayBeforeScroll={10}
                  hideCheckboxes={true}
                  isCloseOnSelect={true}
                  isSingleSelectMode={true}
                  disabled={!canEdit}
                  isEnableFuzzySearch={true}
                  selectedValuesRenderer={l => {
                    const match = l.match(/\+\S*[^)](?=\))/g);
                    if (match && match.length > 0) {
                      return match[0];
                    }
                    return l;
                  }}
                />
              )}
            </Field>

            <Field name={`guests.${guestIndex}.mobilePhone`}>
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <Field
                  data-lpignore="true"
                  as={TextInput}
                  name={`guests.${guestIndex}.mobilePhone`}
                  className="textInputField"
                  disabled={!canEdit}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    );
  };

  const renderFormThirdRow = (guestIndex: number) => {
    return (
      <div className="guestFormRow grid gap-5 grid-cols-[250px_200px]">
        <FormLabel text="CIP Arr Booking No">
          <Field
            as={TextInput}
            className="textInputField arrivalCIPBookingNumber"
            name={`guests.${guestIndex}.arrivalCIPBookingNumber`}
            disabled={!canEdit}
          />
        </FormLabel>

        <FormLabel text="CIP Dep Booking No">
          <Field
            as={TextInput}
            className="textInputField departureCIPBookingNumber"
            name={`guests.${guestIndex}.departureCIPBookingNumber`}
            disabled={!canEdit}
          />
        </FormLabel>
      </div>
    );
  };

  const renderFormFourthRow = (guestIndex: number, formData: FormikProps<IFormValues>) => {
    let dropdownSelectedValues: string[] = [];
    if (!isNil(formData.values.guests[guestIndex].arrivalAirlineIataCode)) {
      if (airlineIataCodes.includes(formData.values.guests[guestIndex].arrivalAirlineIataCode!)) {
        dropdownSelectedValues.push(formData.values.guests[guestIndex].arrivalAirlineIataCode!);
      } else {
        dropdownSelectedValues.push(CONST_AIRLINE_OTHER_VALUE);
      }
    }

    return (
      <div className="guestFormRow grid gap-5 grid-cols-[100px_130px_130px_130px_1fr_70px]">
        <FormLabel text="Arrival Date" className="arrival-date relative">
          <Field name={`guests.${guestIndex}.arrivalDate`}>
            {({ field: { name, value }, form: { setFieldValue, values } }) =>
              canEdit ? (
                <React.Fragment>
                  <SingleDateInput
                    value={value && new Date(value)}
                    internalClassName={classNames('arrival-date-field', {
                      dateInputField: true,
                      dateNotSelected: value === undefined,
                    })}
                    maxDate={
                      values.guests[guestIndex].departureDate
                        ? new Date(values.guests[guestIndex].departureDate)
                        : undefined
                    }
                    enablePastDates
                    onChange={value => {
                      const arrivalStr = formatDate(value);
                      const departureStr = values.guests[guestIndex].departureDate;

                      const diff =
                        arrivalStr && departureStr
                          ? differenceInDays(new Date(departureStr), new Date(arrivalStr))
                          : Number.MAX_SAFE_INTEGER;

                      setFieldValue(name, arrivalStr);
                      setLastModifiedDateField({ guestIndex, name });
                      if (diff < 0) {
                        setFieldValue(`guests.${guestIndex}.departureDate`, null);
                      }
                    }}
                  />
                  {notifiedSameDateFields.includes(name) && (
                    <div className="absolute z-10 end-0 left-0 w-170px rounded border border-solid bg-yellow-advert-light border-yellow-advert p-2 text-black text-13px">
                      <span>Same Arrival and Departure date.</span>
                      <button
                        onClick={() => {
                          setNotifiedSameDateFields(notifiedSameDateFields.filter(item => item !== name));
                        }}
                        className="cursor-pointer bg-transparent absolute border-none top-2 right-1"
                      >
                        <SvgIcon
                          IconComponent={CloseIcon}
                          defaultFill={theme.colors['black']}
                          hoverFill={theme.colors['black']}
                          activeFill={theme.colors['black']}
                          width="12px"
                          height="12px"
                        />
                      </button>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div className="flex relative">
                  <TextInput
                    disabled={!canEdit}
                    value={
                      formData.values.guests[guestIndex].arrivalDate
                        ? formatDateDisplay(formData.values.guests[guestIndex].arrivalDate)
                        : ''
                    }
                    className="arrival-date readonly textInputField"
                    readOnly
                  />
                  <span className="absolute text-gray-100 right-2 top-7px">
                    <i className="fas fa-calendar fa-xs" />
                  </span>
                </div>
              )
            }
          </Field>
        </FormLabel>

        <FormLabel text="Flight Arr Time">
          <Field name={`guests.${guestIndex}.arrivalTime`}>
            {({ field: { name, value }, form: { setFieldValue } }) => {
              return (
                <div className="textInputField">
                  <input
                    id={`arrival-time-input-${guestIndex}`}
                    className="arrival-time-input h-full w-full pl-2 pr-2 focus:outline-teal-100"
                    type="time"
                    disabled={!canEdit}
                    value={value}
                    onChange={e => {
                      if (!timeFormat.test(e.target.value)) {
                        return;
                      }
                      setFieldValue(name, e.target.value);
                    }}
                  />
                </div>
              );
            }}
          </Field>
        </FormLabel>

        <FormLabel className="arrival-flight" text="Flight No Arr">
          <Field
            disabled={!canEdit}
            as={TextInput}
            className="arrival-flight-field textInputField"
            name={`guests.${guestIndex}.arrivalFlightNo`}
          />
        </FormLabel>

        <FormLabel className="ticketClassArrival" text="Ticket Class">
          <Field name={`guests.${guestIndex}.ticketClassArrival`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <SingleSelect
                fieldId="ticketClassArrival"
                className="title bg-ivory"
                value={value}
                options={ticketClasses.map((classLabel, idx) => ({
                  label: classLabel,
                  value: classLabel,
                }))}
                onChange={value => {
                  setFieldValue(name, value);
                }}
                maxVisibleItems={10}
                disabled={!canEdit}
              />
            )}
          </Field>
        </FormLabel>

        <FormLabel text="Arrival Airport">
          <Field name={`guests.${guestIndex}.arrivalAirportId`}>
            {({ field: { name, value }, form: { setFieldValue } }) => {
              return (
                <Multiselect
                  options={[]}
                  disabled={!canEdit}
                  className="title bg-ivory"
                  itemsClassname="bg-ivory"
                  itemCtaClassName="hover:bg-gray-10"
                  onUpdate={values => {
                    if (values.length === 0) {
                      setFieldValue(name, null);
                      setFieldValue(`guests.${guestIndex}.arrivalAirportIataCode`, null);
                      setFieldValue(`guests.${guestIndex}.arrivalAirportName`, null);
                      return;
                    }
                    const id = Number(values[0]);
                    const location = locations.find(l => l.id === id);
                    setFieldValue(name, values[0]);
                    setFieldValue(`guests.${guestIndex}.arrivalAirportIataCode`, location?.iata_code);
                    setFieldValue(`guests.${guestIndex}.arrivalAirportName`, location?.name);
                  }}
                  selectedValues={
                    formData.values.guests[guestIndex].arrivalAirportId
                      ? [formData.values.guests[guestIndex].arrivalAirportId || '0']
                      : []
                  }
                  isCloseOnSelect
                  isSingleSelectMode
                  hideCheckboxes
                  hideDropdownArrow
                  isEnableLookupSearch
                  onLookupSearch={handleAirlineLookup}
                  noOptionsAvailableText="Please type and search to lookup airlines"
                />
              );
            }}
          </Field>
        </FormLabel>

        <FormLabel className="arrival-terminal" text="Terminal">
          <Field
            disabled={!canEdit}
            as={TextInput}
            className="arrival-terminal textInputField"
            name={`guests.${guestIndex}.arrivalTerminal`}
          />
        </FormLabel>
      </div>
    );
  };

  const renderFormFifthRow = (guestIndex: number, formData: FormikProps<IFormValues>) => {
    let dropdownSelectedValues: string[] = [];
    if (!isNil(formData.values.guests[guestIndex].departureAirlineIataCode)) {
      if (airlineIataCodes.includes(formData.values.guests[guestIndex].departureAirlineIataCode!)) {
        dropdownSelectedValues.push(formData.values.guests[guestIndex].departureAirlineIataCode!);
      } else {
        dropdownSelectedValues.push(CONST_AIRLINE_OTHER_VALUE);
      }
    }
    return (
      <div className="guestFormRow grid gap-5 grid-cols-[100px_130px_130px_130px_1fr_70px]">
        <FormLabel className="departure-date relative" text="Departure Date">
          <Field name={`guests.${guestIndex}.departureDate`}>
            {({ field: { name, value }, form: { setFieldValue, values } }) =>
              canEdit ? (
                <React.Fragment>
                  <SingleDateInput
                    value={value && new Date(value)}
                    enablePastDates
                    internalClassName={classNames('departure-date-field', {
                      dateInputField: true,
                      dateNotSelected: value === undefined,
                    })}
                    minDate={
                      values.guests[guestIndex].arrivalDate
                        ? new Date(values.guests[guestIndex].arrivalDate)
                        : undefined
                    }
                    onChange={value => {
                      setFieldValue(name, formatDate(value));
                      setLastModifiedDateField({ guestIndex, name });
                    }}
                  />
                  {notifiedSameDateFields.includes(name) && (
                    <div className="absolute z-10 end-0 left-0 w-170px rounded border border-solid bg-yellow-advert-light border-yellow-advert p-2 text-black text-13px">
                      <span>Same Arrival and Departure date.</span>
                      <button
                        onClick={() => {
                          setNotifiedSameDateFields(notifiedSameDateFields.filter(item => item !== name));
                        }}
                        className="cursor-pointer bg-transparent absolute border-none top-2 right-1"
                      >
                        <SvgIcon
                          IconComponent={CloseIcon}
                          defaultFill={theme.colors['black']}
                          hoverFill={theme.colors['black']}
                          activeFill={theme.colors['black']}
                          width="12px"
                          height="12px"
                        />
                      </button>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div className="flex relative">
                  <TextInput
                    disabled={!canEdit}
                    value={
                      formData.values.guests[guestIndex].departureDate
                        ? formatDateDisplay(formData.values.guests[guestIndex].departureDate)
                        : ''
                    }
                    className="departure-date readonly textInputField"
                    readOnly
                  />
                  <span className="absolute text-gray-100 right-2 top-7px">
                    <i className="fas fa-calendar fa-xs" />
                  </span>
                </div>
              )
            }
          </Field>
        </FormLabel>

        <FormLabel text="Flight Dep Time">
          <Field name={`guests.${guestIndex}.departureTime`}>
            {({ field: { name, value }, form: { setFieldValue } }) => {
              return (
                <div className="textInputField">
                  <input
                    id={`departure-time-input-${guestIndex}`}
                    className="departure-time-input h-full w-full pl-2 pr-2 focus:outline-teal-100"
                    type="time"
                    value={value}
                    disabled={!canEdit}
                    onChange={e => {
                      if (!timeFormat.test(e.target.value)) {
                        return;
                      }
                      setFieldValue(name, e.target.value);
                    }}
                  />
                </div>
              );
            }}
          </Field>
        </FormLabel>

        <FormLabel className="departure-flight" text="Flight No Dep">
          <Field
            disabled={!canEdit}
            as={TextInput}
            className="textInputField"
            name={`guests.${guestIndex}.departureFlightNo`}
          />
        </FormLabel>

        <FormLabel className="ticketClassDeparture" text="Ticket Class">
          <Field name={`guests.${guestIndex}.ticketClassDeparture`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <SingleSelect
                fieldId="ticketClassDeparture"
                className="title bg-ivory"
                value={value}
                options={ticketClasses.map((classLabel, idx) => ({
                  label: classLabel,
                  value: classLabel,
                }))}
                onChange={value => {
                  setFieldValue(name, value);
                }}
                maxVisibleItems={10}
                disabled={!canEdit}
              />
            )}
          </Field>
        </FormLabel>

        <FormLabel text="Departure Airport">
          <Field name={`guests.${guestIndex}.departureAirportId`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <Multiselect
                options={[]}
                disabled={!canEdit}
                className="title bg-ivory"
                itemsClassname="bg-ivory"
                itemCtaClassName="hover:bg-gray-10"
                onUpdate={values => {
                  if (values.length === 0) {
                    setFieldValue(name, null);
                    setFieldValue(`guests.${guestIndex}.departureAirportIataCode`, null);
                    setFieldValue(`guests.${guestIndex}.departureAirportName`, null);
                    return;
                  }
                  const id = Number(values[0]);
                  const location = locations.find(l => l.id === id);
                  setFieldValue(name, values[0]);
                  setFieldValue(`guests.${guestIndex}.departureAirportIataCode`, location?.iata_code);
                  setFieldValue(`guests.${guestIndex}.departureAirportName`, location?.name);
                }}
                selectedValues={
                  formData.values.guests[guestIndex].departureAirportId
                    ? [formData.values.guests[guestIndex].departureAirportId || '0']
                    : []
                }
                isCloseOnSelect
                isSingleSelectMode
                hideCheckboxes
                hideDropdownArrow
                isEnableLookupSearch
                onLookupSearch={handleAirlineLookup}
                noOptionsAvailableText="Please type and search to lookup airlines"
              />
            )}
          </Field>
        </FormLabel>

        <FormLabel className="departure-terminal" text="Terminal">
          <Field
            disabled={!canEdit}
            as={TextInput}
            className="departure-terminal textInputField"
            name={`guests.${guestIndex}.departureTerminal`}
          />
        </FormLabel>
      </div>
    );
  };

  const renderFormSixthRow = (guestIndex: number, formData: FormikProps<IFormValues>) => {
    return (
      <div className="guestFormRow grid gap-5 grid-cols-[1fr]">
        <FormLabel text="Comment">
          <Field name={`guests.${guestIndex}.comment`}>
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <BreakdownTextArea
                title={''}
                className="offer-terms mt-[5px] o:block"
                text={formData.values.guests[guestIndex].comment ?? ''}
                disabled={!canEdit}
                onChange={text => setFieldValue(name, text)}
              />
            )}
          </Field>
        </FormLabel>
      </div>
    );
  };

  const [locations, setLocations] = React.useState<LocationFromSearchLocationsWithType[]>([]);
  const [getLocationsNetwork, setGetLocationsNetwork] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);
  const [notifiedSameDateFields, setNotifiedSameDateFields] = useState<string[]>([]);
  const [lastModifiedDateField, setLastModifiedDateField] = useState<{ guestIndex: number; name: string }>();

  // we mount open modal, we need to get the locations that are going to power the pickup and dropoff
  useEffect(() => {
    async function fetchLocations() {
      setGetLocationsNetwork(ENetworkRequestStatus.PENDING);
      try {
        const c = await bookingManagerApi.postAncillaryLocationsSearch(EAncillaryProductType.GROUND_SERVICE, [
          EAncillaryLocationType.AIRPORT,
        ]);
        const locationsList = [...c.data.airports.map(x => ({ ...x, type: EAncillaryLocationType.AIRPORT }))];
        locationsList.unshift({
          name: 'Other',
          iata_code: null,
          id: 0,
          type: EAncillaryLocationType.AIRPORT,
        });
        setLocations(locationsList);
        setGetLocationsNetwork(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setLocations([]);
        setGetLocationsNetwork(ENetworkRequestStatus.ERROR);
      }
    }
    fetchLocations();
  }, []); // empty array, execute only in initial render

  return (
    <GuestInformationFormStyles>
      <Formik
        initialValues={initialValues}
        validationSchema={GuestInformationFormValidationSchema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<IFormValues>) => {
          useEffect(() => {
            if (lastModifiedDateField) {
              if (
                form.values.guests[lastModifiedDateField.guestIndex].arrivalDate ===
                form.values.guests[lastModifiedDateField.guestIndex].departureDate
              ) {
                setNotifiedSameDateFields(oldArray => [...oldArray, lastModifiedDateField.name]);
              } else {
                setNotifiedSameDateFields(
                  notifiedSameDateFields.filter(
                    item =>
                      item !== `guests.${lastModifiedDateField.guestIndex}.arrivalDate` &&
                      item !== `guests.${lastModifiedDateField.guestIndex}.departureDate`
                  )
                );
              }
            }
          }, [lastModifiedDateField]);

          const leadGuest = form.values.guests.find(g => g.isLeadGuest);

          const isLeadGuestDatesSet =
            leadGuest != null && leadGuest.arrivalDate != null && leadGuest.departureDate != null;

          const doesLeadGuestHaveLastName = leadGuest != null && leadGuest?.lastName?.length;

          return (
            <React.Fragment>
              <LeaveWithoutSavingModal when={form.dirty} />

              {props.isSaving ? (
                <LoadingBar />
              ) : (
                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  {/* @ts-ignore */}
                  <FieldArray
                    name="guests"
                    render={(arrayHelpers: FieldArrayRenderProps) => (
                      <React.Fragment>
                        {form.values.guests.map((guest, guestIndex) => (
                          <div key={guestIndex} className="guestForm">
                            {renderFormFirstRow(guestIndex, form, arrayHelpers)}
                            {renderFormSecondRow(guestIndex, form)}
                            {renderFormThirdRow(guestIndex)}
                            {!form.values.guests[guestIndex].isLeadGuest && canEdit && (
                              <FluidButton
                                type="secondary"
                                className="copy-lead-guest self-end justify-self-start mt-4"
                                onClick={handleSameAsLeadGuest(guestIndex, form)}
                              >
                                Same Lead Guest Flight
                              </FluidButton>
                            )}
                            {renderFormFourthRow(guestIndex, form)}
                            {renderFormFifthRow(guestIndex, form)}
                            {renderFormSixthRow(guestIndex, form)}
                          </div>
                        ))}
                        {canEdit && (
                          <div className="addGuestButtonContainer">
                            <FluidButton
                              type="secondary"
                              className="addGuestButton"
                              onClick={() => arrayHelpers.push(createEmptyGuest())}
                            >
                              Add guest details
                            </FluidButton>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  />

                  {!doesLeadGuestHaveLastName && (
                    <p
                      className={`error-message-lastname text-red-92 text-14px leading-17px font-pt-sans mt-[30px] mb-0`}
                    >
                      Lead guest must have Last Name set.
                    </p>
                  )}

                  {!isLeadGuestDatesSet && (
                    <p className={`error-message-dates text-red-92 text-14px leading-17px font-pt-sans mt-[30px] mb-0`}>
                      Lead guest must have arrival date and departure date set.
                    </p>
                  )}

                  {canEdit && (
                    <div className="submitFormButtonContainer">
                      <FluidButton type="primary" className="min-w-130px" submit isLoading={props.isSaving}>
                        Save
                      </FluidButton>
                    </div>
                  )}
                </form>
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    </GuestInformationFormStyles>
  );
};

export default GuestInformationForm;
