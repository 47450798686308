import { createSelector } from 'reselect';
import { IProposalsListDomain } from './model';

import { isSR } from 'store/modules/auth';

export const proposalsListDomain = (state: any): IProposalsListDomain => state.proposalsList;

export const requestPendingSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['requestPending'] => domain.requestPending
);

export const errorSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['error'] => domain.error
);

export const proposalsSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['proposals'] => {
    return domain.proposals;
  }
);

export const nameFilterSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['nameFilter'] => domain.nameFilter
);

export const clientFilterSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['clientFilter'] => domain.clientFilter
);

export const itemsPerPageSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['itemsPerPage'] => domain.itemsPerPage
);

export const currentPageSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['currentPage'] => domain.currentPage
);

export const totalResultsSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['totalResults'] => domain.totalResults
);

export const bookingFieldsSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['bookingFields'] => domain.bookingFields
);

export const sortBySelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['sortBy'] => domain.sortBy
);

export const sortOrderSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['sortOrder'] => domain.sortOrder
);

export const pageCountSelector = createSelector(
  itemsPerPageSelector,
  totalResultsSelector,
  (itemsPerPage, totalResults): number => {
    return itemsPerPage && totalResults ? Math.ceil(totalResults / itemsPerPage) : 0;
  }
);

export const selectedTravelAgentUuidSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['travelAgentUuid'] => domain.travelAgentUuid
);

export const tableLoadingSelector = createSelector(
  proposalsListDomain,
  (domain: IProposalsListDomain): IProposalsListDomain['tableLoading'] => domain.tableLoading
);

export const proposalsListQuerySelector = createSelector(
  sortBySelector,
  nameFilterSelector,
  clientFilterSelector,
  bookingFieldsSelector,
  currentPageSelector,
  itemsPerPageSelector,
  sortOrderSelector,
  selectedTravelAgentUuidSelector,
  isSR,
  (sortBy, nameFilter, clientFilter, bookingFields, currentPage, itemsPerPage, sortOrder, travelAgentUuid, isSr) => {
    const associations = ['bookings'];
    const fields = {
      bookings: bookingFields.join(','),
    };

    if (isSr) {
      associations.push('user');
      fields['user'] = 'uuid,title,firstName,lastName';
    }

    let filterParam = {
      proposal: {},
    };

    if (nameFilter != '') {
      filterParam = {
        proposal: {
          ...filterParam.proposal,
          [`name:ilike`]: nameFilter,
        },
      };
    }

    if (clientFilter != '') {
      filterParam = {
        proposal: {
          ...filterParam.proposal,
          [`guestFirstName,guestLastName:ilike`]: clientFilter,
        },
      };
    }

    if (travelAgentUuid) {
      filterParam['proposal']['userUuid'] = travelAgentUuid;
    }

    return {
      sort: sortOrder === 'asc' ? `proposal.${sortBy}` : `-proposal.${sortBy}`,
      page: {
        offset: currentPage * itemsPerPage,
        limit: itemsPerPage,
      },
      associations: associations.join(','),
      fields,
      filter: filterParam,
    };
  }
);
