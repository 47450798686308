import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import {
  AnonymousAvatarComponent as AnonymousAvatarIcon,
} from 'ui/Icons';
import { theme } from '../../../../tailwind.config';
import { UITextArea } from 'ui/UITextArea';
import { createTaskCommentRequestAction } from 'store/modules/taskManagement/actions';
import { commentSavingSelector } from 'store/modules/taskManagement/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi';

interface ITaskCommentEditProps {
  commentId: string | null;
  value: string;
  onCancel: () => void;
  taskId: string;
}

export const TaskCommentEdit: React.FC<ITaskCommentEditProps> = ({ value, taskId, commentId, onCancel }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const commentSavingStatus = useSelector(commentSavingSelector);
  const isSaving = commentSavingStatus === ENetworkRequestStatus.PENDING;
  const isCommentEmpty = comment.length === 0;
  
  const handleCancelEdit = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirmEdit = useCallback(() => {
    dispatch(createTaskCommentRequestAction(taskId, comment, commentId));
  }, [dispatch, comment, taskId, commentId]);
  
  const handleCommentTextChange = useCallback((value: string) => {
    setComment(value);
  }, []);

  return (
    <div className="edit-comment flex w-full items-start gap-[10px]">
      <div className="user-picture">
        <AnonymousAvatarIcon icon={theme.colors['gray-100']} fill={theme.colors['gray-40']} />
      </div>
      <div className="comment-container flex-1">
        <UITextArea
          id="comment"
          className="comment"
          value={comment}
          rows={2}
          autoFocus
          onChange={handleCommentTextChange}
        />        
        <div className="buttons flex gap-[10px] ">
          <FluidButton type="secondary" onClick={handleCancelEdit} disabled={isSaving}>
            Cancel
          </FluidButton>
          <FluidButton type="primary" onClick={handleConfirmEdit} isLoading={isSaving} disabled={isSaving || isCommentEmpty}>
            Save
          </FluidButton>
        </div>
      </div>
    </div>
  )
};
