import { TCurrencyCode } from 'interfaces';
import {
  ITCBookingStatementPageResponse,
  ITCBookingStatementSortableItems,
  IStatementPdf,
} from '../../../services/BookingManagerApi/types';
import {
  ITCBookingsTotals,
} from './model';

// Totals
export const TC_BOOKING_STATEMENT_TOTALS_REQUEST = 'tcBookingStatement/TC_BOOKING_STATEMENT_TOTALS_REQUEST';
export const TC_BOOKING_STATEMENT_TOTALS_SUCCESS = 'tcBookingStatement/TC_BOOKING_STATEMENT_TOTALS_SUCCESS';
export const TC_BOOKING_STATEMENT_TOTALS_FAILURE = 'tcBookingStatement/TC_BOOKING_STATEMENT_TOTALS_FAILURE';

export const TC_BOOKING_STATEMENT_SELECT_COMPANY = 'tcBookingStatement/SELECT_COMPANY';
export const TC_BOOKING_STATEMENT_REQUEST = 'tcBookingStatement/TC_BOOKING_STATEMENT_REQUEST';
export const TC_BOOKING_STATEMENT_SUCCESS = 'tcBookingStatement/TC_BOOKING_STATEMENT_SUCCESS';
export const TC_BOOKING_STATEMENT_FAILURE = 'tcBookingStatement/TC_BOOKING_STATEMENT_FAILURE';
export const TC_BOOKING_STATEMENT_SET_SORT = 'tcBookingStatement/TC_BOOKING_STATEMENT_SET_SORT';
export const TC_BOOKING_STATEMENT_SET_PAGE_NUMBER = 'tcBookingStatement/TC_BOOKING_STATEMENT_SET_PAGE_NUMBER';
export const TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE = 'tcBookingStatement/TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE';
export const TC_BOOKING_STATEMENT_RESET = 'tcBookingStatement/TC_BOOKING_STATEMENT_RESET';

// Filter
export const TC_STATEMENT_FILTER_SET_FROM_DATE = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_FROM_DATE';
export const TC_STATEMENT_FILTER_SET_TO_DATE = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_TO_DATE';
export const TC_STATEMENT_FILTER_SET_STATUS = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_STATUS';
export const TC_STATEMENT_FILTER_ADD_BOOKING_REF = 'tcBookingStatement/TC_STATEMENT_FILTER_ADD_BOOKING_REF';
export const TC_STATEMENT_FILTER_SET_BOOKING_REF = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_BOOKING_REF';
export const TC_STATEMENT_FILTER_SET_AMOUNT_MIN = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_AMOUNT_MIN';
export const TC_STATEMENT_FILTER_SET_AMOUNT_MAX = 'tcBookingStatement/TC_STATEMENT_FILTER_SET_AMOUNT_MAX';
export const TC_STATEMENT_FILTER_DELETE_BOOKING_REF = 'tcBookingStatement/TC_STATEMENT_FILTER_DELETE_BOOKING_REF';
export const TC_STATEMENT_FILTER_RESET = 'tcBookingStatement/TC_STATEMENT_FILTER_RESET';
export const TC_STATEMENT_FILTER_UPDATE_RESULTS = 'tcBookingStatement/TC_STATEMENT_FILTER_UPDATE_RESULTS';

// CSV Exports
export const TRIGGER_EXPORT_CSV_REQUEST = '<YOUR MODULE KEY HERE>/TRIGGER_EXPORT_CSV_REQUEST';
export const TRIGGER_EXPORT_CSV_SUCCESS = '<YOUR MODULE KEY HERE>/TRIGGER_EXPORT_CSV_SUCCESS';
export const TRIGGER_EXPORT_CSV_FAILURE = '<YOUR MODULE KEY HERE>/TRIGGER_EXPORT_CSV_FAILURE';
export const POLL_EXPORT_CSV_REQUEST = '<YOUR MODULE KEY HERE>/POLL_EXPORT_CSV_REQUEST';
export const POLL_EXPORT_CSV_SUCCESS = '<YOUR MODULE KEY HERE>/POLL_EXPORT_CSV_SUCCESS';
export const POLL_EXPORT_CSV_CONTINUE = '<YOUR MODULE KEY HERE>/POLL_EXPORT_CSV_CONTINUE';
export const POLL_EXPORT_CSV_FAILURE = '<YOUR MODULE KEY HERE>/POLL_EXPORT_CSV_FAILURE';
export const POLL_EXPORT_CSV_CANCEL = '<YOUR MODULE KEY HERE>/POLL_EXPORT_CSV_CANCEL';

export const TC_BOOKING_STATEMENT_POST_EXPORT_REQUEST = 'tcBookingStatement/TC_BOOKING_STATEMENT_POST_EXPORT_REQUEST';
export const TC_BOOKING_STATEMENT_POST_EXPORT_SUCCESS = 'tcBookingStatement/TC_BOOKING_STATEMENT_POST_EXPORT_SUCCESS';
export const TC_BOOKING_STATEMENT_POST_EXPORT_FAILURE = 'tcBookingStatement/TC_BOOKING_STATEMENT_POST_EXPORT_FAILURE';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF = 'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF_SUCCESS = 'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF_SUCCESS';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF_FAILURE = 'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF_FAILURE';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST =
  'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_SUCCESS =
  'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_SUCCESS';
export const TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_FAILURE =
  'tcBookingStatement/TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_FAILURE';


export type TCStatementFilterSetFromDateAction = ReturnType<typeof tcStatementFilterSetFromDateAction>;
export const tcStatementFilterSetFromDateAction = (fromDate: string) => ({
  type: TC_STATEMENT_FILTER_SET_FROM_DATE as typeof TC_STATEMENT_FILTER_SET_FROM_DATE,
  fromDate,
});

export type TCStatementFilterSetToDateAction = ReturnType<typeof tcStatementFilterSetToDateAction>;
export const tcStatementFilterSetToDateAction = (toDate: string) => ({
  type: TC_STATEMENT_FILTER_SET_TO_DATE as typeof TC_STATEMENT_FILTER_SET_TO_DATE,
  toDate,
});

export type TCStatementFilterSetStatusAction = ReturnType<typeof tcStatementFilterSetStatusAction>;
export const tcStatementFilterSetStatusAction = (status: string[]) => ({
  type: TC_STATEMENT_FILTER_SET_STATUS as typeof TC_STATEMENT_FILTER_SET_STATUS,
  status,
});

export type TCStatementFilterSetAmountMinAction = ReturnType<typeof tcStatementFilterSetAmountMinAction>;
export const tcStatementFilterSetAmountMinAction = (amount?: number) => ({
  type: TC_STATEMENT_FILTER_SET_AMOUNT_MIN as typeof TC_STATEMENT_FILTER_SET_AMOUNT_MIN,
  amount,
});

export type TCStatementFilterSetAmountMaxAction = ReturnType<typeof tcStatementFilterSetAmountMaxAction>;
export const tcStatementFilterSetAmountMaxAction = (amount?: number) => ({
  type: TC_STATEMENT_FILTER_SET_AMOUNT_MAX as typeof TC_STATEMENT_FILTER_SET_AMOUNT_MAX,
  amount,
});

export type TCStatementFilterAddBookingRefAction = ReturnType<typeof tcStatementFilterAddBookingRefAction>;
export const tcStatementFilterAddBookingRefAction = () => ({
  type: TC_STATEMENT_FILTER_ADD_BOOKING_REF as typeof TC_STATEMENT_FILTER_ADD_BOOKING_REF,
});

export type TCStatementFilterSetBookingRefAction = ReturnType<typeof tcStatementFilterSetBookingRefAction>;
export const tcStatementFilterSetBookingRefAction = (index: number, bookingRefValue: string) => ({
  type: TC_STATEMENT_FILTER_SET_BOOKING_REF as typeof TC_STATEMENT_FILTER_SET_BOOKING_REF,
  index,
  bookingRefValue,
});

export type TCStatementFilterDeleteBookingRefAction = ReturnType<typeof tcStatementFilterDeleteBookingRefAction>;
export const tcStatementFilterDeleteBookingRefAction = (index: number) => ({
  type: TC_STATEMENT_FILTER_DELETE_BOOKING_REF as typeof TC_STATEMENT_FILTER_DELETE_BOOKING_REF,
  index,
});

export type TCStatementFilterResetAction = ReturnType<typeof tcStatementFilterResetAction>;
export const tcStatementFilterResetAction = () => ({
  type: TC_STATEMENT_FILTER_RESET as typeof TC_STATEMENT_FILTER_RESET,
});

export type TCStatementFilterUpdateResultsAction = ReturnType<typeof tcStatementFilterUpdateResultsAction>;
export const tcStatementFilterUpdateResultsAction = () => ({
  type: TC_STATEMENT_FILTER_UPDATE_RESULTS as typeof TC_STATEMENT_FILTER_UPDATE_RESULTS,
});

export type TCBookingStatementSelectCompanyAction = ReturnType<typeof tcBookingStatementselectCompanyAction>;
export const tcBookingStatementselectCompanyAction = (company: string) => ({
  type: TC_BOOKING_STATEMENT_SELECT_COMPANY as typeof TC_BOOKING_STATEMENT_SELECT_COMPANY,
  company,
});

export type TCBookingStatementRequestAction = ReturnType<typeof tcBookingStatementRequestAction>;
export const tcBookingStatementRequestAction = (currency: TCurrencyCode) => ({
  type: TC_BOOKING_STATEMENT_REQUEST as typeof TC_BOOKING_STATEMENT_REQUEST,
  currency,
});

export type TCBookingStatementSuccessAction = ReturnType<typeof tcBookingStatementSuccessAction>;
export const tcBookingStatementSuccessAction = (tcBookingStatement: ITCBookingStatementPageResponse) => ({
  type: TC_BOOKING_STATEMENT_SUCCESS as typeof TC_BOOKING_STATEMENT_SUCCESS,
  tcBookingStatement,
});

export type TCBookingStatementFailureAction = ReturnType<typeof tcBookingStatementFailureAction>;
export const tcBookingStatementFailureAction = (error: Error) => ({
  type: TC_BOOKING_STATEMENT_FAILURE as typeof TC_BOOKING_STATEMENT_FAILURE,
  error,
});

export type TCBookingStatementRequestTotalsAction = ReturnType<typeof tcBookingStatementRequestTotalsAction>;
export const tcBookingStatementRequestTotalsAction = (company: string) => ({
  type: TC_BOOKING_STATEMENT_TOTALS_REQUEST as typeof TC_BOOKING_STATEMENT_TOTALS_REQUEST,
  company,
});

export type TCBookingStatementSuccessTotalsAction = ReturnType<typeof tcBookingStatementSuccessTotalsAction>;
export const tcBookingStatementSuccessTotalsAction = (tcTotals: ITCBookingsTotals[]) => ({
  type: TC_BOOKING_STATEMENT_TOTALS_SUCCESS as typeof TC_BOOKING_STATEMENT_TOTALS_SUCCESS,
  tcTotals,
});

export type TCBookingStatementFailureTotalsAction = ReturnType<typeof tcBookingStatementFailureTotalsAction>;
export const tcBookingStatementFailureTotalsAction = (error: Error) => ({
  type: TC_BOOKING_STATEMENT_TOTALS_FAILURE as typeof TC_BOOKING_STATEMENT_TOTALS_FAILURE,
  error,
});

export type TCBookingStatementSetSortAction = ReturnType<typeof tcBookingStatementSetSortAction>;
export const tcBookingStatementSetSortAction = (
  sortBy: ITCBookingStatementSortableItems,
  sortOrder: 'asc' | 'desc'
) => ({
  type: TC_BOOKING_STATEMENT_SET_SORT as typeof TC_BOOKING_STATEMENT_SET_SORT,
  sortBy,
  sortOrder,
});

export type TCBookingStatementSetPageNumberAction = ReturnType<typeof tcBookingStatementSetPageNumberAction>;
export const tcBookingStatementSetPageNumberAction = (pageNumber: number) => ({
  type: TC_BOOKING_STATEMENT_SET_PAGE_NUMBER as typeof TC_BOOKING_STATEMENT_SET_PAGE_NUMBER,
  pageNumber,
});

export type TCBookingStatementSetItemsPerPageAction = ReturnType<typeof tcBookingStatementSetItemsPerPageAction>;
export const tcBookingStatementSetItemsPerPageAction = (itemsPerPage: number) => ({
  type: TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE as typeof TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type TCBookingStatementResetAction = ReturnType<typeof tcBookingStatementResetAction>;
export const tcBookingStatementResetAction = () => ({
  type: TC_BOOKING_STATEMENT_RESET as typeof TC_BOOKING_STATEMENT_RESET,
});

export type TriggerExportCsvRequestAction = ReturnType<typeof triggerExportCsvRequestAction>;
export const triggerExportCsvRequestAction = () => ({
  type: TRIGGER_EXPORT_CSV_REQUEST as typeof TRIGGER_EXPORT_CSV_REQUEST,
});

export type TriggerExportCsvSuccessAction = ReturnType<typeof triggerExportCsvSuccessAction>;
export const triggerExportCsvSuccessAction = (exportUuid: string) => ({
  type: TRIGGER_EXPORT_CSV_SUCCESS as typeof TRIGGER_EXPORT_CSV_SUCCESS,
  exportUuid,
});

export type TriggerExportCsvFailureAction = ReturnType<typeof triggerExportCsvFailureAction>;
export const triggerExportCsvFailureAction = (error: Error) => ({
  type: TRIGGER_EXPORT_CSV_FAILURE as typeof TRIGGER_EXPORT_CSV_FAILURE,
  error,
});

export type PollExportCsvRequestAction = ReturnType<typeof pollExportCsvRequestAction>;
export const pollExportCsvRequestAction = () => ({
  type: POLL_EXPORT_CSV_REQUEST as typeof POLL_EXPORT_CSV_REQUEST,
});

export type PollExportCsvSuccessAction = ReturnType<typeof pollExportCsvSuccessAction>;
export const pollExportCsvSuccessAction = (signedUrl: string) => ({
  type: POLL_EXPORT_CSV_SUCCESS as typeof POLL_EXPORT_CSV_SUCCESS,
  signedUrl,
});

export type PollExportCsvContinueAction = ReturnType<typeof pollExportCsvContinueAction>;
export const pollExportCsvContinueAction = () => ({
  type: POLL_EXPORT_CSV_CONTINUE as typeof POLL_EXPORT_CSV_CONTINUE,
});

export type PollExportCsvFailureAction = ReturnType<typeof pollExportCsvFailureAction>;
export const pollExportCsvFailureAction = (error: Error) => ({
  type: POLL_EXPORT_CSV_FAILURE as typeof POLL_EXPORT_CSV_FAILURE,
  error,
});

export type PollExportCsvCancelAction = ReturnType<typeof pollExportCsvCancelAction>;
export const pollExportCsvCancelAction = () => ({
  type: POLL_EXPORT_CSV_CANCEL as typeof POLL_EXPORT_CSV_CANCEL,
});

export type TCBookingStatementDownloadPdfHistoryRequestAction = ReturnType<
  typeof tcBookingStatementDownloadPdfHistoryRequestAction
>;
export const tcBookingStatementDownloadPdfHistoryRequestAction = (companyUuid: string, currency: TCurrencyCode) => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST,
  companyUuid,
  currency,
});

export type TCBookingStatementDownloadPdfHistorySuccessAction = ReturnType<
  typeof tcBookingStatementDownloadPdfHistorySuccessAction
>;
export const tcBookingStatementDownloadPdfHistorySuccessAction = (pdfs: IStatementPdf[]) => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_SUCCESS as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_SUCCESS,
  pdfs,
});

export type TCBookingStatementDownloadPdfHistoryFailureAction = ReturnType<
  typeof tcBookingStatementDownloadPdfHistoryFailureAction
>;
export const tcBookingStatementDownloadPdfHistoryFailureAction = (error: string) => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_FAILURE as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_FAILURE,
  error,
});

export type TCBookingStatementDownloadPDFAction = ReturnType<typeof tcBookingStatementDownloadPdfAction>;
export const tcBookingStatementDownloadPdfAction = () => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF,
});

export type TCBookingStatementDownloadPDFSuccessAction = ReturnType<typeof tcBookingStatementDownloadPdfSuccessAction>;
export const tcBookingStatementDownloadPdfSuccessAction = (pdf: IStatementPdf) => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF_SUCCESS as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF_SUCCESS,
  pdf,
});

export type TCBookingStatementDownloadPDFFailureAction = ReturnType<typeof tcBookingStatementDownloadPdfFailureAction>;
export const tcBookingStatementDownloadPdfFailureAction = (error: string) => ({
  type: TC_BOOKING_STATEMENT_DOWNLOAD_PDF_FAILURE as typeof TC_BOOKING_STATEMENT_DOWNLOAD_PDF_FAILURE,
  error,
});

export type TCBookingStatementAction =
  | TCStatementFilterSetFromDateAction
  | TCStatementFilterSetToDateAction
  | TCStatementFilterSetStatusAction
  | TCStatementFilterSetAmountMinAction
  | TCStatementFilterSetAmountMaxAction
  | TCStatementFilterAddBookingRefAction
  | TCStatementFilterSetBookingRefAction
  | TCStatementFilterDeleteBookingRefAction
  | TCStatementFilterResetAction
  | TCStatementFilterUpdateResultsAction
  | TCBookingStatementSelectCompanyAction
  | TCBookingStatementRequestAction
  | TCBookingStatementSuccessAction
  | TCBookingStatementFailureAction  
  | TCBookingStatementRequestTotalsAction
  | TCBookingStatementSuccessTotalsAction
  | TCBookingStatementFailureTotalsAction
  | TCBookingStatementSetSortAction
  | TCBookingStatementSetPageNumberAction
  | TCBookingStatementSetItemsPerPageAction
  | TCBookingStatementResetAction
  | PollExportCsvRequestAction
  | PollExportCsvSuccessAction
  | PollExportCsvContinueAction
  | PollExportCsvFailureAction
  | PollExportCsvCancelAction
  | TriggerExportCsvRequestAction
  | TriggerExportCsvSuccessAction
  | TriggerExportCsvFailureAction
  | TCBookingStatementDownloadPDFAction
  | TCBookingStatementDownloadPDFSuccessAction
  | TCBookingStatementDownloadPDFFailureAction
  | TCBookingStatementDownloadPdfHistoryRequestAction
  | TCBookingStatementDownloadPdfHistorySuccessAction
  | TCBookingStatementDownloadPdfHistoryFailureAction;
