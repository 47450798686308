import React from 'react';
import classnames from 'classnames';

export interface ITextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  id: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  labelStyle?: React.CSSProperties;
  errorMessage?: string | null;
  errorClassName?: string;
  warning?: boolean;
}

export const TextInput = (props: ITextInputProps) => {

  const { label, id, className, warning, errorMessage, labelClassName, inputClassName, errorClassName, ...rest } = props;
  const isError = !!errorMessage || errorMessage === '';
  const inputClassname = classnames('default:text-15px default:leading-19px py-7px px-10px mt-5px default:font-pt-sans outline-none default:placeholder:italic', inputClassName,
    {
      "default:border-gray-40 default:bg-ivory": !isError && !props.disabled,
      "border-red-95 bg-red-25": isError && !props.disabled,
      "border-yellow-30": !!warning,
      "text-black border border-solid": !props.disabled,
      "default:text-gray-100 border border-gray-20 bg-ivory": props.disabled,
    }
  )

  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      {label && (
        <label
          style={props.labelStyle}
          className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs', labelClassName)}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <input className={inputClassname} id={id} {...rest} />
      {errorMessage && (
        <p className={`text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ${errorClassName ?? ''}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
