import React, { Dispatch, SetStateAction, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BookingRightTotalCost } from './BookingRightTotalCost';
import { BasketLeadGuestInfo } from './BasketLeadGuestInfo';
import { useCurrentWidth } from 'effects';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as BookingsSelectors from 'store/modules/bookings/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder';
import { BookingMargin, useMarginChange } from 'containers/SummaryFormExtras/BookingMargin';
import { useTranslation } from 'react-i18next';
import { BookingButtons } from './BookingButtons';
import { TermsAndConditions } from 'containers/HotelContainer/TermsAndConditions';
import { LodgingSummary } from 'interfaces';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { BookingBuilder, IHotel } from 'services/BackendApi';
import { BasketTravelAgentInfo } from './BasketTravelAgentInfo';
import { isNil } from 'lodash-es';

interface IBasketRightColumnProps {
  bookingBuild: BookingBuilder | null;
  lodgingSummaries: LodgingSummary[];
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  shouldShowLeaveAlert: boolean;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const BasketRightColumn: React.FC<IBasketRightColumnProps> = React.memo(
  ({ bookingBuild, className, lodgingSummaries, setRedirectLocation, setShouldShowLeaveAlert }) => {
    const { currentWidth } = useCurrentWidth();
    const isTAMarginApplied = useSelector(BookingBuilderSelectors.isTAMarginAppliedSelector);
    const grandTotal = useSelector(BookingBuilderSelectors.bookingBuilderTotalSelector);
    const taMarginType = useSelector(BookingBuilderSelectors.taMarginTypeSelector);
    const canBook = useSelector(BookingBuilderSelectors.bookingCanBookSelector);
    const currencyCode = useSelector(BookingsSelectors.getBookingCurrencySymbol);
    const taMarginAmount = useSelector(BookingBuilderSelectors.taMarginAmountSelector);
    const { t } = useTranslation();

    const isAdmin = useSelector(AuthSelectors.isAdmin);
    const isSr: boolean = useSelector(AuthSelectors.isSR);
    const isRl: boolean = useSelector(AuthSelectors.isRL);
    const isTA: boolean = useSelector(AuthSelectors.isTA);
    const shouldShowTAInfo = isAdmin || (isSr && !isRl);
    const [isRequestInProgress, setRequestInProgress] = useState(false);
    const [liveRatePricesModalData, setLiveRatePricesModalData] = useState([]);
    const [isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen] = useState(false);
    const handleLiveRatePricesModalOpen = (value: boolean) => {
      setIsLiveRatePricesModalOpen(value);
    };
    const handleSetLiveRatePricesModalData = (value: any) => {
      setLiveRatePricesModalData(value);
    };

    return (
      <div className="basket-right-column w-full bg-white">
        {isNil(bookingBuild) && (
          <span className="block bg-green-25 uppercase text-black font-hurmegeometric-sans py-15px text-center w-full mb-30px">
            No item has been selected
          </span>
        )}
        <BasketLeadGuestInfo disabled={isNil(bookingBuild)} setShouldShowLeaveAlert={setShouldShowLeaveAlert} />
        {shouldShowTAInfo ? <BasketTravelAgentInfo travelAgent={null} /> : null}
        <BookingButtons
          bookingBuild={bookingBuild}
          lodgingSummaries={lodgingSummaries}
          isRequestInProgress={isRequestInProgress}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRequestInProgress={setRequestInProgress}
          setRedirectLocation={setRedirectLocation}
          canBook={canBook}
          isLiveRatePricesModalOpen={isLiveRatePricesModalOpen}
          setIsLiveRatePricesModalOpen={handleLiveRatePricesModalOpen}
          liveRatePricesModalData={liveRatePricesModalData}
          setLiveRatePricesModalData={handleSetLiveRatePricesModalData}
        />

        <div className="terms-and-conditions uppercase">
          <TermsAndConditions className="font-hurmegeometric-sans text-brown-100 visited:text-brown-100 inline-block mt-[18px] text-[12px] uppercase" />
        </div>
        {!isNil(bookingBuild) && (
          <>
            <BookingRightTotalCost booking={bookingBuild} />
            <BookingMargin
              t={t}
              currencyCode={currencyCode}
              onMarginChange={() => {}}
              grandTotal={grandTotal}
              canBook={canBook}
              isTAMarginApplied={isTAMarginApplied}
              taMarginType={taMarginType}
              taMarginAmount={taMarginAmount}
              textClassname="normal-case"
              depth={0}
              titleClassname="font-hurmegeometric-sans font-[12px] leading-[14px] font-bold text-flint pb-[10px] mb-[10px] ml-[-20px] border-b border-gray-17"
              marginClassname="mx-[-20px]"
            />
          </>
        )}
      </div>
    );
  }
);
