import { IEditAncillaryProductModalContainerState } from 'containers/EditAncillaryProductModalContainer/EditAncillaryProductModalContainer';
import React from 'react';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { IBookingManagerBreakdownSubdomain } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { GeneralModal } from 'ui/GeneralModal';
import AirplaneIcon from 'ui/Icons/airplane.component.svg';
import { Radio } from 'ui/Radio';

import { isNil } from 'lodash-es';
import {
  getDisplayNameForAncillaryProductResult,
  AncillaryStyledDateRangeInput,
} from 'ui/AddAncillarySharedComponents/utils';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { AddAncillaryRightHandPanelOptions } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelOptions';
import { AddAncillaryRightHandPanelUnitCounter } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelUnitCounter';
import { AddAncillaryRightHandPanelTotalCost } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelTotalCost';
import {
  AncillaryProductWithRatesWithUserData,
  EAncillaryProductType,
  EAncillaryTransferTripType,
  isAncillaryRatedResult,
  LocationFromSearchLocationsWithType,
} from 'services/BookingManagerApi/types/AncillaryService';
import FluidButton from 'ui/FluidButton';
import { LoadingBar } from 'ui/NetworkStatusBar';
import classNames from 'classnames';
import { SvgIcon } from 'ui/SvgIcon';
import PickupLocationIcon from 'ui/Icons/pickuplocation.component.svg';
import DropoffLocationIcon from 'ui/Icons/dropofflocation.component.svg';

export interface IEditActivityModalProps {
  currencySymbol: string;
  currentlyEditingLineItem: IBookingManagerBreakdownSubdomain['currentlyEditingLineItem'];
  containerState: IEditAncillaryProductModalContainerState;
  updateContainerState: (state: IEditAncillaryProductModalContainerState) => void;
  onClose: () => void;
  onSave: () => void;
  enabledDates: string[];
  flexibleAttributesWithAnInvalidSelection: any[];
  airport?: LocationFromSearchLocationsWithType;
}

export const EditAncillaryProductModal = (props: IEditActivityModalProps) => {
  const { containerState } = props;

  if (isNil(props.currentlyEditingLineItem)) {
    return null;
  }

  const minimumAge = props.containerState.selectedProduct?.product.productRestrictions.minimumAge;
  const isAnyChildBelowMinimumAge = containerState.agesOfAllChildren.some(
    age => !isNil(minimumAge) && age < minimumAge
  );

  const allDatesAreUnrated = Object.values(containerState.fetchedRates).every(rate => rate.hasRateForDate === false);

  const isCurrentSelectedDateRated =
    !isNil(containerState.selectedDate) &&
    !isNil(containerState.fetchedRates[containerState.selectedDate]) &&
    isAncillaryRatedResult(containerState.fetchedRates[containerState.selectedDate]);

  const isTransferPickupDateRated =
    !isNil(containerState.transfer_pickupDate) &&
    !isNil(containerState.fetchedRates[containerState.transfer_pickupDate]) &&
    isAncillaryRatedResult(containerState.fetchedRates[containerState.transfer_pickupDate]);

  const productType = props.currentlyEditingLineItem?.ancillaryData?.product?.productType!;

  const hasFailedToGetProduct = props.containerState.getProductNetworkRequest === ENetworkRequestStatus.ERROR;

  let modalTitleNoun = 'Product';
  let disableFormInputs = false;
  let disableSaveButton = false;
  let datesExistButAllAreUnrated = false;

  switch (productType) {
    case EAncillaryProductType.ACTIVITY:
      modalTitleNoun = 'Activity';
      datesExistButAllAreUnrated = Object.keys(containerState.fetchedRates).length >= 1 && allDatesAreUnrated;
      disableFormInputs = datesExistButAllAreUnrated || hasFailedToGetProduct;
      break;
    case EAncillaryProductType.GROUND_SERVICE:
      modalTitleNoun = 'Ground Service';
      datesExistButAllAreUnrated = Object.keys(containerState.fetchedRates).length >= 1 && allDatesAreUnrated;
      disableFormInputs = datesExistButAllAreUnrated || hasFailedToGetProduct;
      break;
    case EAncillaryProductType.TRANSFER:
      modalTitleNoun = 'Transfer';
      disableFormInputs = datesExistButAllAreUnrated || hasFailedToGetProduct;
      disableSaveButton =
        disableSaveButton ||
        isNil(containerState.transfer_pickupDate) ||
        isNil(containerState.transfer_pickupTime) ||
        containerState.transfer_pickupTime === '';

      if (containerState.transfer_tripType === EAncillaryTransferTripType.RETURN) {
        disableSaveButton =
          disableSaveButton ||
          isNil(containerState.transfer_returnDate) ||
          isNil(containerState.transfer_returnTime) ||
          containerState.transfer_returnTime === '';
      }

      if (!isTransferPickupDateRated) {
        disableSaveButton = true;
      }
      break;
  }

  const transferPickupLocationName = props.containerState.locations.find(
    l => l.id === props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.from?.id
  )?.name;

  const transferDropOffLocationName = props.containerState.locations.find(
    l => l.id === props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.to?.id
  )?.name;

  return (
    <GeneralModal
      onClose={props.onClose}
      modalWindowClassName="w-[620px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip font-pt-sans flex flex-col"
      shouldCloseByClickingOutside={false}
    >
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Edit {modalTitleNoun}</h2>

      <span className="text-17px leading-22px text-black font-bold mb-5">
        {getDisplayNameForAncillaryProductResult(props.currentlyEditingLineItem.ancillaryData!.product!)}
      </span>

      {productType === EAncillaryProductType.TRANSFER && !isNil(transferPickupLocationName) && (
        <div className="flex flex-col space-y-2 mb-6">
          <span className="flex items-center space-x-2">
            <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={PickupLocationIcon} />
            <span>{transferPickupLocationName}</span>
          </span>
          <span className="flex items-center space-x-2">
            <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={DropoffLocationIcon} />
            <span>{transferDropOffLocationName}</span>
          </span>
        </div>
      )}

      {productType === EAncillaryProductType.GROUND_SERVICE && !isNil(props.airport) && (
        <span className="text-15px leading-19px text-black mb-5">
          <span className="flex items-center space-x-2">
            <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={AirplaneIcon} />
            <span>
              {props.airport?.name}
              {props.airport.iata_code ? ` ,${props.airport.iata_code}` : ''}
            </span>
          </span>
        </span>
      )}

      {datesExistButAllAreUnrated && (
        <span className="text-15px text-yellow-60 leading-19px mb-5 ">
          This {modalTitleNoun.toLocaleLowerCase()} can't be edited because there are no rates available for any of the
          potential dates. Please, review the information with the System Admin.
        </span>
      )}

      {props.containerState.getProductNetworkRequest === ENetworkRequestStatus.ERROR && (
        <span className="text-15px text-yellow-60 leading-19px mb-5 ">
          This {modalTitleNoun.toLocaleLowerCase()} can't be edited because the product is not available. Please, review
          the information with the System Admin.
        </span>
      )}

      {(props.containerState.getProductNetworkRequest === ENetworkRequestStatus.PENDING ||
        props.containerState.getLocationsNetworkRequest === ENetworkRequestStatus.PENDING) && <LoadingBar />}

      {(props.containerState.getProductNetworkRequest === ENetworkRequestStatus.SUCCESS ||
        props.containerState.getProductNetworkRequest === ENetworkRequestStatus.ERROR) && (
        <div className="grow flex flex-col">
          <div className="flex space-x-5 mb-20px">
            {/* top left is always the people */}
            <span className="w-1/2">
              <h4 className="mt-0 mb-5px font-pt-sans text-17px leading-22px text-black font-bold">People *</h4>
              <GuestAgesPicker
                disabled={disableFormInputs}
                numberOfAdults={containerState.numberOfAdults}
                agesOfChildren={containerState.agesOfAllChildren}
                childAgePickerItemsToDisplayBeforeScroll={5}
                onUpdate={(numberOfAdults, agesOfAllChildren) => {
                  props.updateContainerState({
                    ...props.containerState,
                    numberOfAdults,
                    agesOfAllChildren: agesOfAllChildren || [],
                  });
                }}
              />
              {!isNil(minimumAge) && isAnyChildBelowMinimumAge && (
                <span className="text-15px text-yellow-60 leading-19px">
                  The minimum age for this {modalTitleNoun.toLocaleLowerCase()} is {minimumAge} years old
                </span>
              )}
            </span>

            {/* for activities and ground services, the date is always the second thing */}
            {(productType === EAncillaryProductType.ACTIVITY ||
              productType === EAncillaryProductType.GROUND_SERVICE) && (
              <span className="w-1/2">
                <h4 className="mt-0 mb-5px font-pt-sans text-17px leading-22px text-black font-bold">Date *</h4>
                <DatePickerStateProvider
                  isSingleDateSelection={true}
                  defaultSelectedDates={[containerState.selectedDate]}
                  onDateChange={dates => {
                    props.updateContainerState({
                      ...props.containerState,
                      selectedDate: dates[0].split('T')[0],
                    });
                  }}
                  placeholder="Select date"
                  render={(params: IDatePickerSateParams) => {
                    return (
                      <AncillaryStyledDateRangeInput
                        disabled={disableFormInputs}
                        calendarCount={1}
                        displayString={params.displayString}
                        currentDate={params.datePickerCurrentDate}
                        selectedDates={[...params.selectedDates].map(d => new Date(d).toISOString())}
                        onDayClick={params.handleDayClick}
                        onDayMouseOver={params.handleDateMouseOver}
                        showDatePicker={params.showDatePicker}
                        onNextClick={params.incrementDate}
                        onPrevClick={params.decrementDate}
                        onMouseDown={params.toggleDatePicker}
                        onClickOutside={params.hideDatePicker}
                        showTotalNights={false}
                        enabledDates={props.enabledDates}
                        enablePastDates
                        noPortal
                      />
                    );
                  }}
                />
                {!isCurrentSelectedDateRated && !allDatesAreUnrated && (
                  // if the current date isn't rated, but also not all the dates are unrated, that means
                  // we have SOME rates, but the specific date we've got right now isn't rated - so show
                  // the warning
                  <span className="text-15px text-yellow-60 leading-19px">
                    The selected date for this {modalTitleNoun.toLocaleLowerCase()} is no longer available. If you
                    select a new date, you will not be able to re-select the currently selected date
                  </span>
                )}
              </span>
            )}

            {/* for ground services, the time is always the third thing */}
            {productType === EAncillaryProductType.GROUND_SERVICE && (
              <label className="w-1/2 mr-4">
                <div className="flex">
                  <h4 className="mt-0 mb-5px font-pt-sans text-17px leading-22px text-black font-bold">Time *</h4>
                </div>
                <input
                  onChange={e => {
                    props.updateContainerState({
                      ...props.containerState,
                      groundService_pickupTime: e.target.value,
                    });
                  }}
                  type="time"
                  className={classNames(
                    'pickup-time-input p-2 bg-ivory border border-solid border-gray-40 min-h-37px max-h-37px w-full font-pt-sans'
                  )}
                  value={props.containerState.groundService_pickupTime || ''}
                />
              </label>
            )}

            {/* for transfers, they pick the trip type here */}
            {productType === EAncillaryProductType.TRANSFER && (
              <span className="w-1/2">
                {/* trip type */}
                <div className="w-[166px]">
                  <span className="font-bold text-black mb-5px block">Trip Type</span>
                  <div className={classNames('flex mt-3 space-x-3')}>
                    <label className="block cursor-pointer">
                      <Radio
                        checked={props.containerState.transfer_tripType === EAncillaryTransferTripType.ONE_WAY}
                        onClick={() => {
                          props.updateContainerState({
                            ...props.containerState,
                            transfer_tripType: EAncillaryTransferTripType.ONE_WAY,
                            transfer_returnDate: undefined,
                            transfer_returnTime: undefined,
                          });
                        }}
                      />
                      <span className="ml-2">One Way</span>
                    </label>

                    <label className="block cursor-pointer">
                      <Radio
                        checked={props.containerState.transfer_tripType === EAncillaryTransferTripType.RETURN}
                        onClick={() => {
                          props.updateContainerState({
                            ...props.containerState,
                            transfer_tripType: EAncillaryTransferTripType.RETURN,
                          });
                        }}
                      />
                      <span className="ml-2">Return</span>
                    </label>
                  </div>
                </div>
              </span>
            )}
          </div>

          {/* transfers have some extra nonsense going on */}
          {productType === EAncillaryProductType.TRANSFER && (
            <div>
              {/* these are the date pickers */}
              <div className="flex space-x-5 mb-10px">
                {/* this column is always here because its for the pickup date and pickup time */}
                <div className="w-1/2">
                  <span className="w-full">
                    <h4 className="mt-0 mb-5px font-pt-sans text-17px leading-22px text-black font-bold">
                      Pickup Date *
                    </h4>
                    <DatePickerStateProvider
                      isSingleDateSelection={true}
                      defaultSelectedDates={[containerState.transfer_pickupDate!]}
                      onDateChange={dates => {
                        props.updateContainerState({
                          ...props.containerState,
                          transfer_pickupDate: dates[0].split('T')[0],
                        });
                      }}
                      placeholder="Select date"
                      render={(params: IDatePickerSateParams) => {
                        return (
                          <AncillaryStyledDateRangeInput
                            disabled={hasFailedToGetProduct}
                            calendarCount={1}
                            displayString={params.displayString}
                            currentDate={params.datePickerCurrentDate}
                            selectedDates={[...params.selectedDates].map(d => new Date(d).toISOString())}
                            onDayClick={params.handleDayClick}
                            onDayMouseOver={params.handleDateMouseOver}
                            showDatePicker={params.showDatePicker}
                            onNextClick={params.incrementDate}
                            onPrevClick={params.decrementDate}
                            onMouseDown={params.toggleDatePicker}
                            onClickOutside={params.hideDatePicker}
                            showTotalNights={false}
                            enabledDates={props.enabledDates}
                            enablePastDates
                            noPortal
                          />
                        );
                      }}
                    />
                  </span>
                </div>

                {/* then if its a return, we have a second column here */}
                <div
                  className={classNames('w-1/2', {
                    invisible: containerState.transfer_tripType === EAncillaryTransferTripType.ONE_WAY,
                  })}
                >
                  <span className="w-full">
                    <h4 className="mt-0 mb-5px font-pt-sans text-17px leading-22px text-black font-bold">
                      Return Date *
                    </h4>
                    <DatePickerStateProvider
                      isSingleDateSelection={true}
                      defaultSelectedDates={
                        containerState.transfer_returnDate ? [containerState.transfer_returnDate] : []
                      }
                      onDateChange={dates => {
                        props.updateContainerState({
                          ...props.containerState,
                          transfer_returnDate: dates[0].split('T')[0],
                        });
                      }}
                      placeholder="Select date"
                      render={(params: IDatePickerSateParams) => {
                        return (
                          <AncillaryStyledDateRangeInput
                            disabled={hasFailedToGetProduct}
                            calendarCount={1}
                            displayString={params.displayString}
                            currentDate={params.datePickerCurrentDate}
                            selectedDates={[...params.selectedDates].map(d => new Date(d).toISOString())}
                            onDayClick={params.handleDayClick}
                            onDayMouseOver={params.handleDateMouseOver}
                            showDatePicker={params.showDatePicker}
                            onNextClick={params.incrementDate}
                            onPrevClick={params.decrementDate}
                            onMouseDown={params.toggleDatePicker}
                            onClickOutside={params.hideDatePicker}
                            showTotalNights={false}
                            minDate={containerState.transfer_pickupDate}
                            enablePastDates
                            noPortal
                          />
                        );
                      }}
                    />
                  </span>
                </div>
              </div>
              {/* this is the error message if the date(s) is not rated */}
              {!isTransferPickupDateRated && (
                <div className="w-full">
                  <span className="text-15px text-yellow-60 leading-19px">
                    {containerState.transfer_tripType === EAncillaryTransferTripType.ONE_WAY
                      ? 'This transfer is unavailable on this date. Please select a different date in order to update the transfer.'
                      : 'This return transfer is unavailable on these dates. Please select different dates in order to update the return transfer.'}
                  </span>
                </div>
              )}
              {/* these are the time pickers */}
              <div className="flex space-x-5 mb-20px">
                {/* this column is always here because its for the pickup date and pickup time */}
                <div className="w-1/2">
                  <span className="w-full flex flex-col space-y-1 mt-3">
                    <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                      Pickup Time *
                    </span>
                    <input
                      onChange={e => {
                        props.updateContainerState({
                          ...props.containerState,
                          transfer_pickupTime: e.currentTarget.value,
                        });
                      }}
                      disabled={disableFormInputs}
                      type="time"
                      className={classNames(
                        'arrival-time-input bg-ivory p-2 font-pt-sans border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                        {
                          'pointer-events-none o:cursor-auto o:bg-gray-10 o:border-gray-10 o:text-gray-100': disableFormInputs,
                        }
                      )}
                      value={props.containerState.transfer_pickupTime!}
                    />
                  </span>
                </div>

                {/* then if its a return, we have a second column here */}

                <div
                  className={classNames('w-1/2', {
                    invisible: containerState.transfer_tripType === EAncillaryTransferTripType.ONE_WAY,
                  })}
                >
                  <span className="w-full flex flex-col space-y-1 mt-3">
                    <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                      Return Time *
                    </span>
                    <input
                      onChange={e => {
                        props.updateContainerState({
                          ...props.containerState,
                          transfer_returnTime: e.currentTarget.value,
                        });
                      }}
                      disabled={disableFormInputs}
                      type="time"
                      className={classNames(
                        'arrival-time-input bg-ivory p-2 font-pt-sans  border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                        {
                          'pointer-events-none o:cursor-auto o:bg-gray-10 o:border-gray-10 o:text-gray-100': disableFormInputs,
                        }
                      )}
                      value={props.containerState.transfer_returnTime!}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}

          {!isNil(props.containerState.selectedProduct) && (
            <>
              <AddAncillaryRightHandPanelOptions
                className="o:h-[150px]"
                errorIds={props.containerState.errorIds.map(id => id.toString())}
                flexibleAttributesWithAnInvalidSelection={
                  // if we're in an error state, we don't want to show the invalid selections
                  disableFormInputs ? [] : props.flexibleAttributesWithAnInvalidSelection
                }
                disabled={disableFormInputs}
                nonBooleanWrapperClassname="flex flex-row flex-wrap gap-[20px] [&>*]:w-[170px]"
                selectedProduct={props.containerState.selectedProduct}
                updateSelectedProductWithUserData={(
                  data: Partial<AncillaryProductWithRatesWithUserData['userData']>
                ) => {
                  props.updateContainerState({
                    ...props.containerState,
                    selectedProduct: {
                      ...props.containerState.selectedProduct!,
                      userData: {
                        ...props.containerState.selectedProduct!.userData,
                        ...data,
                      },
                    },
                  });
                }}
              />

              <div className="flex flex-col space-y-3">
                <AddAncillaryRightHandPanelUnitCounter
                  selectedProduct={props.containerState.selectedProduct}
                  updateSelectedProductWithUserData={data => {
                    props.updateContainerState({
                      ...props.containerState,
                      selectedProduct: {
                        ...props.containerState.selectedProduct!,
                        userData: {
                          ...props.containerState.selectedProduct!.userData,
                          unitCount: data.unitCount,
                        },
                      },
                    });
                  }}
                />

                {!isNil(props.containerState.selectedProduct.userData.rate) &&
                  isAncillaryRatedResult(props.containerState.selectedProduct.userData.rate) && (
                    <AddAncillaryRightHandPanelTotalCost
                      isOnRequest={props.containerState.selectedProduct.userData.rate.rateIsOnRequest}
                      price={props.containerState.selectedProduct.userData.rate.price!}
                      currencySymbol={props.currencySymbol}
                    />
                  )}
              </div>
            </>
          )}

          <div className="flex space-x-2 mt-2">
            <FluidButton type={'secondary'} onClick={props.onClose}>
              Cancel
            </FluidButton>
            <FluidButton type={'primary'} onClick={props.onSave} disabled={disableFormInputs || disableSaveButton}>
              Save
            </FluidButton>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};
