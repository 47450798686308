import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { CircleIconButton } from 'ui/CircleIconButton';
import { saveAs } from 'file-saver';
import { ENetworkRequestStatus, Upload, EUploadTag, EUserType } from 'services/BackendApi';
import { formatDateDisplay } from 'utils';
import { StandardModal } from 'pureUi/Modal';
import * as UploadsSelectors from 'store/modules/bookingManager/subdomains/uploads/selectors';
import * as UploadsActions from 'store/modules/bookingManager/subdomains/uploads/actions';
import * as AuthSelectors from 'store/modules/auth';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import * as DashboardActions from 'store/modules/bookingManager/subdomains/dashboard/actions';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { NewUploadModalConnected } from './NewUploadModal';

export interface ITabSpec {
  newUploadTag: EUploadTag;
  tags: EUploadTag[];
  route: string;
  styles: string;
  roles: EUserType[];
  private?: boolean;
}

const FINANCE_TAGS = [
  EUploadTag.FINANCE_SALES,
  EUploadTag.FINANCE_SALES_INVOICE,
  EUploadTag.FINANCE_SALES_PROFORMA_INVOICE,
  EUploadTag.FINANCE_SALES_PROFORMA_INVOICE_FINAL_CLIENT,
  EUploadTag.FINANCE_SALES_MANUAL_PROFORMA_INVOICE,
  EUploadTag.FINANCE_SALES_MANUAL_PROFORMA_INVOICE_FINAL_CLIENT,
  EUploadTag.BOOKING_CANCELLATION_INVOICE,
];

const OTHER_INTERNAL_TAGS = [EUploadTag.OTHER_INTERNAL, EUploadTag.FINANCE_PURCHASE, EUploadTag.INSTANT_BOOK_HOTEL_CONFIRMATION];

const NON_DELETABLE_TAGS = [...FINANCE_TAGS, EUploadTag.FINANCE_PURCHASE];

const TAG_LABEL_LUT = {
  [EUploadTag.BREAKDOWN]: 'Quote',
  [EUploadTag.VOUCHER]: 'Voucher',
  [EUploadTag.FINANCE_SALES]: 'Finance',
  [EUploadTag.FINANCE_SALES_INVOICE]: 'Invoice',
  [EUploadTag.FINANCE_SALES_PROFORMA_INVOICE]: 'Proforma Invoice',
  [EUploadTag.FINANCE_SALES_PROFORMA_INVOICE_FINAL_CLIENT]: 'Proforma Invoice to Client',
  [EUploadTag.FINANCE_SALES_MANUAL_PROFORMA_INVOICE]: 'Manual Proforma Invoice',
  [EUploadTag.FINANCE_SALES_MANUAL_PROFORMA_INVOICE_FINAL_CLIENT]: 'Manual Proforma Invoice to client',
  [EUploadTag.FINANCE_PURCHASE]: 'Finance',
  [EUploadTag.OTHER_GENERAL]: 'Other General',
  [EUploadTag.OTHER_INTERNAL]: 'Other Internal',
  [EUploadTag.BOOKING_CANCELLATION_INVOICE]: 'Booking Cancellation Invoice',
  [EUploadTag.INSTANT_BOOK_HOTEL_CONFIRMATION]: 'Hotel Confirmation',
};

const TABS_SPEC: ITabSpec[] = [
  {
    tags: [EUploadTag.BREAKDOWN],
    newUploadTag: EUploadTag.BREAKDOWN,
    route: 'breakdown-details',
    styles: 'min-w-150px',
    roles: [EUserType.SR, EUserType.ADMIN],
  },
  {
    tags: [EUploadTag.VOUCHER],
    newUploadTag: EUploadTag.VOUCHER,
    route: 'voucher',
    styles: 'min-w-150px',
    roles: [EUserType.SR, EUserType.ADMIN],
  },
  {
    tags: FINANCE_TAGS,
    newUploadTag: EUploadTag.FINANCE_SALES,
    route: 'finance',
    styles: 'min-w-150px',
    roles: [EUserType.SR, EUserType.ADMIN],
  },
  {
    tags: [EUploadTag.OTHER_GENERAL],
    newUploadTag: EUploadTag.OTHER_GENERAL,
    route: 'other-general',
    styles: 'min-w-150px',
    roles: [EUserType.SR, EUserType.ADMIN],
  },
  {
    tags: OTHER_INTERNAL_TAGS,
    newUploadTag: EUploadTag.OTHER_INTERNAL,
    route: 'other-internal',
    private: true,
    styles: 'min-w-150px',
    roles: [EUserType.SR, EUserType.ADMIN, EUserType.FINANCE],
  },
];

//this is necessary because cannot use child selectors with utility classes
const StyledNavLink = styled(NavLink)`
  &.not-active,
  &.not-active:hover {
    color: #000;
  }

  &.active,
  &.active:hover {
    color: #70d6ba;
  }
`;

export interface IBookingManagerUploadsProps extends RouteComponentProps {
  className?: string;
}

export const BookingManagerUploads = (props: IBookingManagerUploadsProps) => {
  const dispatch = useDispatch();

  const isTA = useSelector(AuthSelectors.isTA);
  const isSRRole = useSelector(AuthSelectors.isSRRole);
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const userRole = useSelector(AuthSelectors.getCurrentUserType);
  const uploads = useSelector(UploadsSelectors.uploadListSelector);
  const getUploadListRequestStatus = useSelector(UploadsSelectors.uploadListRequestStatusSelector);
  const newUploadPayload = useSelector(UploadsSelectors.newUploadPayloadSelector);
  const deleteUploadUuid = useSelector(UploadsSelectors.deleteUploadUuidSelector);
  const deleteUploadRequestStatus = useSelector(UploadsSelectors.deleteUploadRequestStatusSelector);
  const canDeleteList = useSelector(UploadsSelectors.canDeleteListSelector);
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const dashboardNetworkRequests = useSelector(DashboardSelectors.dashboardNetworkRequestsSelector);
  const bookingInformationReservationTeamData = useSelector(
    DashboardSelectors.bookingInformationReservationTeamDashboardSelector
  );

  useEffect(() => {
    dispatch(UploadsActions.getUploadsRequestAction());
    dispatch(DashboardActions.getTopNavigationDataRequestAction());
  }, []);

  const getTabsSpec = () => (isTA ? TABS_SPEC.filter(item => !item.private) : TABS_SPEC);

  const getRoute = (path: string): string => `${props.match?.url}/${path}`;

  const renderTagSelector = () => (
    <div className="tag-selector flex">
      {getTabsSpec().map(spec => (
        <StyledNavLink
          key={spec.newUploadTag}
          to={getRoute(spec.route)}
          replace
          className={`${spec.newUploadTag} font-pt-sans text-base text-center border border-solid border-gray-40 border-r-0 last-of-type:border-r rounded rounded-b-none p-4 not-active ${spec.styles}`}
          activeClassName="border-b-0 font-bold text-teal-100 active"
        >
          {TAG_LABEL_LUT[spec.newUploadTag]}
        </StyledNavLink>
      ))}
      <div className="w-full border-t-0 border-r-0 border-l-0 border-b border-solid border-gray-40" />
    </div>
  );

  const handleDownload = (file: Upload) => saveAs(file.url, file.filename);

  const renderFileRow = (file: Upload, spec: ITabSpec) => {
    const isSrDeletable =
      isSRRole && !spec.tags.includes(EUploadTag.BREAKDOWN) && !spec.tags.includes(EUploadTag.FINANCE_SALES);

    const isDeletable =
      (isSrDeletable || isAdmin) &&
      !NON_DELETABLE_TAGS.includes(file.tag as EUploadTag) &&
      canDeleteList.includes(file.uuid);

    return (
      <tr key={file.uuid} className="text-base odd:bg-brown-10">
        <td className="creation-date text-black-true px-4 py-3">{formatDateDisplay(file.createdAt)}</td>
        <td className="filename px-4 py-3">
          <a className="text-brown-100 visited:text-brown-100 underline" href={file.url} target="blank">
            {file.displayName}
          </a>
        </td>
        <td className="tags text-black-true px-4 py-3">{TAG_LABEL_LUT[file.tag] || file.tag}</td>
        <td className="actions px-4 py-2">
          <span className="flex flex-row items-center justify-end space-x-4">
            <CircleIconButton
              className="download-button"
              type="secondary"
              iconClass="fas fa-arrow-down"
              onClick={() => handleDownload(file)}
            />
            {isDeletable && (
              <CircleIconButton
                className="delete-button"
                type="secondary"
                iconClass="fas fa-trash"
                onClick={() => dispatch(UploadsActions.setDeleteUploadAction(file.uuid))}
              />
            )}
          </span>
        </td>
      </tr>
    );
  };

  const renderRouting = () => {
    return (
      <Switch>
        {getTabsSpec().map(spec => {
          const files = (uploads || []).filter(item => spec.tags.includes(item.tag as EUploadTag));

          return (
            <Route key={spec.newUploadTag} exact path={getRoute(spec.route)}>
              <table className="font-pt-sans border-collapse border border-t-0 border-solid border-gray-40 table-fixed w-full">
                <thead className="border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-40">
                  <tr className="text-xs text-gray-100 uppercase">
                    <th className="creation-date font-normal text-left px-4 pb-2 pt-4">Date of creation</th>
                    <th className="filename font-normal text-left px-4 pb-2 pt-4">File name</th>
                    <th className="tags font-normal text-left px-4 pb-2 pt-4">Tags</th>
                    <th className="actions font-normal text-left px-4 pb-2 pt-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {!files.length ? (
                    <tr className="text-base">
                      <td className="text-black-true px-4 py-3" colSpan={4}>
                        No data available
                      </td>
                    </tr>
                  ) : (
                    files.map(file => renderFileRow(file, spec))
                  )}
                </tbody>
              </table>
            </Route>
          );
        })}
        <Route path={`${props.match?.path}`}>
          <Redirect to={getRoute(TABS_SPEC[0].route)} />
        </Route>
      </Switch>
    );
  };

  const renderUploads = () => {
    return (
      <Fragment>
        {renderTagSelector()}
        {renderRouting()}
      </Fragment>
    );
  };

  const renderDeleteConfirmationModal = () => {
    const isLoading = deleteUploadRequestStatus === ENetworkRequestStatus.PENDING;

    return (
      <StandardModal
        className="delete-modal"
        frameClassName="w-500px px-9 py-8 border-4 border-solid border-yellow-50 rounded-xl"
        showCloseButton={false}
        removePadding={true}
      >
        <div className="flex justify-between items-center">
          <span className="fa-stack text-lg">
            <i className="fas fa-circle fa-stack-2x text-yellow-50"></i>
            <i className="fas fa-exclamation fa-stack-1x text-white"></i>
          </span>
          <div className="w-5/6">
            <h1 className="text-black text-lg font-pt-sans m-0">Are you sure you want to delete this file?</h1>
          </div>
        </div>

        <div className="flex justify-between mt-6">
          <button
            className="confirm block w-full mr-3 border-solid hover:bg-brown-120 cursor-pointer border-2 border-brown-100 hover:border-brown-120 text-base font-pt-sans p-2 rounded font-bold bg-brown-100 text-white tracking-wider outline-none disabled:text-gray-80 disabled:bg-gray-20 disabled:border-gray-20 disabled:cursor-default"
            onClick={() => dispatch(UploadsActions.deleteUploadRequestAction())}
            disabled={isLoading}
          >
            Delete
          </button>
          <button
            className="cancel block w-full ml-3 border-solid hover:bg-brown-20 cursor-pointer border-2 border-brown-100 text-base font-pt-sans p-2 rounded font-bold bg-white text-brown-100 tracking-wider outline-none disabled:text-gray-80 disabled:border-gray-20 disabled:bg-white disabled:cursor-default"
            onClick={() => dispatch(UploadsActions.cancelDeleteUploadAction())}
          >
            Cancel
          </button>
        </div>
      </StandardModal>
    );
  };

  if (
    dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING ||
    getUploadListRequestStatus === ENetworkRequestStatus.PENDING
  ) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div>
      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isError={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />

      <div className="uploads">
        <div className="header flex justify-between items-center mb-6">
          <h1 className="section-title font-noe-display text-black font-normal text-4xl mb-0 mt-0">
            Booking Documents
          </h1>
          {!isTA && (
            <button
              onClick={() => dispatch(UploadsActions.openUploadFormAction())}
              className="upload-file font-pt-sans font-bold text-sm text-brown-100 py-2 px-3 border border-solid border-brown-100 rounded bg-white-true hover:bg-brown-20 cursor-pointer transition-bg-color duration-200"
            >
              Upload file
            </button>
          )}
        </div>
        {getUploadListRequestStatus === ENetworkRequestStatus.ERROR && <ErrorBar />}
        {getUploadListRequestStatus === ENetworkRequestStatus.SUCCESS && renderUploads()}
        {newUploadPayload && (
          //@ts-ignore
          <NewUploadModalConnected
            tabsSpec={getTabsSpec()
              .filter(item => item.roles.includes(userRole as EUserType))
              .map(spec => ({
                tag: spec.newUploadTag,
                label: TAG_LABEL_LUT[spec.newUploadTag],
                roles: spec.roles,
              }))}
          />
        )}
        {deleteUploadUuid && renderDeleteConfirmationModal()}
      </div>
    </div>
  );
};

export default BookingManagerUploads;
