import { __, propSatisfies, defaultTo, pipe, includes, props, prop, equals } from 'ramda';

import { AuthTypes } from 'config/enums';
import { createSelector } from 'store/utils';

// List of roles that are SR or above
const qualifiesAsSr = ['SR', 'ADMIN', 'RL'];

/**
 * SR Check
 *
 * Checks a user role if it is SR+
 *
 * @param {string}
 * @returns {boolean}
 */
const srCheck = pipe(defaultTo({}), propSatisfies(includes(__, props(qualifiesAsSr, AuthTypes)), 'type'));

/**
 * Get auth selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuth = prop('auth');

/**
 * Get auth status selector
 *
 * @param {object}
 * @returns {string}
 */
export const getAuthStatus = createSelector(getAuth, prop('status'));

/**
 * Get auth data selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuthData = createSelector(getAuth, prop('data'));

/**
 * Get auth error selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuthError = createSelector(getAuth, prop('error'));

/**
 * Get auth token selector
 *
 * @param {object}
 * @returns {string}
 */
export const getAuthToken = createSelector(getAuth, prop('token'));

export const getLoggedIn = createSelector(getAuth, prop('loggedIn'));

/**
 * Get current user selector
 *
 * @param {object}
 * @returns {object}
 */
export const getCurrentUser = createSelector(getAuthData, prop('user'));

/**
 * Get current user uuid selector
 *
 * @param {object}
 * @returns {string}
 */
export const getCurrentUserUuid = createSelector(getCurrentUser, prop('uuid'));

/**
 * Get current user type selector
 *
 * @param {object}
 * @returns {string}
 */
export const getCurrentUserType = createSelector(getCurrentUser, prop('type'));

/**
 * Is company booking manager  selector
 *
 * @param {object}
 * @returns {string}
 */
export const isCompanyBookingManager = createSelector(getCurrentUser, prop('companyBookingManager'));

/**
 * Is authenticated selector
 *
 * @param {object}
 * @returns {boolean}
 */
export const isAuthenticated = createSelector(getAuthToken, Boolean);

/**
 * LEGACY Is SR selector
 *
 * Returns if the current user is EFFECTIVELY an SR, e.g is an SR OR ADMIN OR RL
 *
 * @param {object}
 * @returns {boolean}
 */
export const isSR = createSelector(getCurrentUser, srCheck);

/**
 * Is SR selector
 *
 * Returns if the current user is an SR
 *
 * @param {object}
 * @returns {boolean}
 */
export const isSRRole = createSelector(getCurrentUserType, equals(AuthTypes.SR));

/**
 * Is RL selector
 *
 * Returns if the current user is an RL
 *
 * @param {object}
 * @returns {boolean}
 */
export const isRL = createSelector(getCurrentUserType, equals(AuthTypes.RL));

/**
 * Is Admin selector
 *
 * Returns if the current user is an Admin
 *
 * @param {object}
 * @returns {boolean}
 */
export const isAdmin = createSelector(getCurrentUserType, equals(AuthTypes.ADMIN));

/**
 * Is TA selector
 *
 * Returns if the current user is an TA
 *
 * @param {object}
 * @returns {boolean}
 */
export const isTA = createSelector(getCurrentUserType, equals(AuthTypes.TA));

export const isFinanceUser = createSelector(getCurrentUserType, equals(AuthTypes.FINANCE));

export const getUpdatePasswordData = createSelector(getAuthData, prop('updatePassword'));

/**
 * Returns if the current user is a ta manager
 *
 * @param {object}
 * @returns {boolean}
 */
export const isCompanyTaManager = createSelector(getCurrentUser, prop('companyTaManager'));

/**
 * Get current company selector
 *
 * @param {object}
 * @returns {object}
 */
export const getCompanySelector = createSelector(getCurrentUser, prop('company'));

export const hideFinanceSelector = createSelector(
  getCurrentUser,
  user => user.type === AuthTypes.TA && !user.companyTaManager && user.company?.disableTaAccessToFinance
);
