import { call, select, takeLatest, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';
import {
  EDIT_MARKUP_REQUEST,
  EditMarkupRequestAction,
  editMarkupSuccessAction,
  editMarkupFailureAction,
  fetchCHMarkupListRequestAction
} from '../actions';
import { bulkEditSelector, companyHotelMarkupListSelector } from '../selectors';
import { IBulkEdit, ICompanyHotelMarkupModel } from '../model';

function *successNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'success' }})
  );
}

function *errorNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'error' }})
  );
}

function* putMany(action: EditMarkupRequestAction) {
  try {
    const markupList: ICompanyHotelMarkupModel[] = yield select(companyHotelMarkupListSelector);
    const bulkEdit: IBulkEdit = yield select(bulkEditSelector);

    const selected = markupList.filter(x => x.selected);
    if(!selected.length) return;

    yield call(
      makeBackendApi().putCompanyHotelMarkups,
      action.companyUuid,
      parseFloat(bulkEdit.editedValue),
      selected.map(x => x.hotelUuid),
    );

    yield put(editMarkupSuccessAction(action.companyUuid, bulkEdit.editedValue));
    yield put(fetchCHMarkupListRequestAction());
    yield successNotification('Mark-Ups edited successfully.');
  } catch (e) {
    yield put(editMarkupFailureAction(action.companyUuid, e));
    yield errorNotification('Error while updating mark-ups.');
  }
}

function* putOne(action: EditMarkupRequestAction) {
  const markupList: ICompanyHotelMarkupModel[] = yield select(companyHotelMarkupListSelector);
  const selected = markupList.find(item =>
    item.companyUuid === action.companyUuid && item.hotelUuid === action.hotelUuid
  );
  if(!selected) return;

  try {
    yield call(
      makeBackendApi().putCompanyHotelMarkups,
      action.companyUuid,
      parseFloat(selected.editedValue),
      [selected.hotelUuid],
    );

    yield put(editMarkupSuccessAction(action.companyUuid, selected.editedValue, action.hotelUuid));
    yield successNotification('This mark up has been updated');
  } catch (e) {
    yield put(editMarkupFailureAction(action.companyUuid, e, action.hotelUuid));
    yield errorNotification('Error while updating mark up');
  }
}

function* putCompanyHotelMarkupSaga(action: EditMarkupRequestAction) {
  const bulkEdit = yield select(bulkEditSelector);
  bulkEdit
    ? yield putMany(action)
    : yield putOne(action);
}

export function* watchPutCHMarkupSaga() {
  yield takeLatest(
    [EDIT_MARKUP_REQUEST],
    putCompanyHotelMarkupSaga
  );
}
