
import { AxiosResponse } from 'axios';
import { call, select, takeLatest, put } from 'redux-saga/effects';
import {
  FetchCompanyHotelsWithoutMarkupRequestAction,
  fetchCompanyHotelsWithoutMarkupSuccessAction,
  fetchCompanyHotelsWithoutMarkupFailureAction,
  FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST,
} from '../actions';
import { makeBackendApi } from 'services/BackendApi';
import {
  travelCompanyUuidSelector,
} from '../selectors';
import { ICompanyHotel } from '../model';

function* fetchAvailableHotelsSaga(action: FetchCompanyHotelsWithoutMarkupRequestAction) {
  try {
    const companyUuid = yield select(travelCompanyUuidSelector);
    const backendApi = makeBackendApi();
    const result: AxiosResponse<ICompanyHotel[]> = yield call(
      backendApi.fetchCompanyHotelAvailableToCreateMarkups,
      companyUuid,
    );

    yield put(fetchCompanyHotelsWithoutMarkupSuccessAction(result.data));
  } catch (e) {
    yield put(fetchCompanyHotelsWithoutMarkupFailureAction(e));
  }
}

export function* watchFetchCHAvailableSaga() {
  yield takeLatest(
    [FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST],
    fetchAvailableHotelsSaga
  );
}
