import { saveAs } from 'file-saver';
import { call, takeLatest, debounce, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBackendApi,
  ITravelAgentResponse,
  ITravelAgentFilter,
  ITravelAgent,
  ICsvResponse,
  EUserType,
  ISalesRepresentativesResponse,
} from 'services/BackendApi';

import { ITravelAgentListDomain, ITravelAgentListPagination } from './model';

import {
  GET_REQUEST,
  SET_SEARCH_FILTER,
  SET_COUNTRY_CODE_FILTER,
  SET_COMPANY_UUID_FILTER,
  SET_PAGE,
  SET_PER_PAGE,
  getRequestAction,
  getSuccessAction,
  getFailureAction,
  SET_STATUS_FILTER,
  DELETE_REQUEST,
  deleteSuccessAction,
  deleteFailureAction,
  CSV_REQUEST,
  csvSuccessAction,
  csvFailureAction,
  GetRequestAction,
  GET_SALES_REPS_REQUEST,
  getSalesRepsSuccessAction,
  getSalesRepsFailureAction,
  SET_SALES_REP_FILTER,
  MASS_ASSIGN_REQUEST,
  massAssignSuccessAction,
  massAssignFailureAction,
  SET_CREATED_BY_FILTER,
  SET_SORT_ORDER,
  SET_EMAIL_FILTER,
  TA_HAS_BOOKINGS_REQUEST,
  taHasBookingsSuccessAction,
  taHasBookingsFailureAction,
} from './actions';

import {
  filterSelector,
  massAssignModeSelector,
  massAssignSrsSelector,
  paginationSelector,
  toDeleteSelector,
  sortSelector,
} from './selectors';

import { enqueueNotification } from '../ui';
import { EMassAssignTaSrMode } from 'containers/TravelAgents/List/MassAssignModal';

import { isSR } from 'store/modules/auth/selectors';
import { IBookingSearchResponse, makeBookingManagerApi } from 'services/BookingManagerApi';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getRequestSaga(action: GetRequestAction) {
  const backendApi = makeBackendApi();

  const f: ITravelAgentFilter = yield select(filterSelector);
  const p: ITravelAgentListPagination = yield select(paginationSelector);
  const sort: ITravelAgentListDomain['sort'] = yield select(sortSelector);
  const isSr = yield select(isSR); // OWA-5286

  try {
    const res: AxiosResponse<ITravelAgentResponse> = yield call(
      backendApi.getTravelAgentList,
      f,
      {
        offset: p.page * p.perPage,
        limit: p.perPage,
      },
      action.userType,
      sort,
      !!f.salesRepUuid || isSr, // OWA-5286
    );

    yield put(getSuccessAction(res.data.data, res.data.meta.total));
  } catch (e) {
    yield put(getFailureAction(e.message));
  }
}

export function* getSalesRepsRequestSaga(action: GetRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const res: AxiosResponse<ISalesRepresentativesResponse> = yield call(backendApi.getSalesReps);

    yield put(getSalesRepsSuccessAction(res.data.data));
  } catch (e) {
    yield put(getSalesRepsFailureAction(e.message));
  }
}

export function* deleteRequestSaga() {
  const backendApi = makeBackendApi();

  const toDelete: ITravelAgent = yield select(toDeleteSelector);

  try {
    yield call(backendApi.deleteUser, toDelete.uuid);

    yield put(deleteSuccessAction());
    yield successNotification('Travel Agent deleted.');

    yield put(getRequestAction(EUserType.ADMIN));
  } catch (e) {
    yield put(deleteFailureAction(e.message));
    yield errorNotification('Failed to delete Travel Agent.');
  }
}

export function* csvRequestSaga() {
  const backendApi = makeBackendApi();
  const f: ITravelAgentFilter = yield select(filterSelector);

  try {
    const res: AxiosResponse<ICsvResponse> = yield call(backendApi.getTravelAgentsCsv, f);
    saveAs(res.data.data.signedUrl);

    yield put(csvSuccessAction());
    yield successNotification('CSV created and downloaded.');
  } catch (e) {
    yield put(csvFailureAction(e.message));
    yield errorNotification('Failed to download CSV.');
  }
}

export function* massAssignTasToSrsSaga() {
  const backendApi = makeBackendApi();

  const f: ITravelAgentFilter = yield select(filterSelector);
  const srUuids = yield select(massAssignSrsSelector);

  const massAssignMode = yield select(massAssignModeSelector);
  const methodMapping = {
    [EMassAssignTaSrMode.ADD]: backendApi.massAssignAdd,
    [EMassAssignTaSrMode.OVERWRITE]: backendApi.massAssignOverwrite,
  };
  // Use "EMassAssignTaSrMode.ADD" by default
  const method = methodMapping[massAssignMode] ?? backendApi.massAssignAdd;

  try {
    yield call(method, f, srUuids);

    yield put(massAssignSuccessAction());
    yield put(getRequestAction(EUserType.TA));
    yield successNotification('SR Assignment updated successfully.');
  } catch (e) {
    yield put(massAssignFailureAction(e.message));
    yield errorNotification('Failed to assign Travel Agents to SRs.');
  }
}

export function* getBookingsOfTASaga(action: any) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const result: AxiosResponse<IBookingSearchResponse> = yield call(
      bookingManagerApi.bookingSearch,
      {
        filter: { travelAgentUserUuid: action.travelAgentUuid },
        pagination: { limit: 1, offset: 0 }
      }
    );
    
    yield put(taHasBookingsSuccessAction(result.data.bookings.length > 0));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(taHasBookingsFailureAction(e));
  }
}

export function* watchTravelAgentListSaga() {
  yield takeLatest(
    [
      GET_REQUEST,
      SET_EMAIL_FILTER,
      SET_COUNTRY_CODE_FILTER,
      SET_COMPANY_UUID_FILTER,
      SET_STATUS_FILTER,
      SET_CREATED_BY_FILTER,
      SET_SORT_ORDER,
    ],
    getRequestSaga
  );

  yield takeLatest([GET_SALES_REPS_REQUEST], getSalesRepsRequestSaga);

  yield takeLatest([CSV_REQUEST], csvRequestSaga);

  yield takeLatest([DELETE_REQUEST], deleteRequestSaga);
  
  yield takeLatest([MASS_ASSIGN_REQUEST], massAssignTasToSrsSaga);
  
  yield debounce(200, [SET_SEARCH_FILTER, SET_SALES_REP_FILTER, SET_PAGE, SET_PER_PAGE], getRequestSaga);
 
  yield takeLatest([TA_HAS_BOOKINGS_REQUEST], getBookingsOfTASaga);
}
