import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import * as AuthSelectors from 'store/modules/auth';
import { getBookingRequestAction } from 'store/modules/bookingManager/actions';

import * as BookingManagerSelectors from 'store/modules/bookingManager/selectors';

import { SidebarNav, NavItem } from 'pureUi/SidebarNav';
import { settings } from 'ui/Icons';

import { NavigationLayoutConnected } from 'containers/NavigationLayout';
import BookingManagerBreakdown from 'containers/BookingManagerBreakdown';
import BookingManagerGuestInformation from 'containers/BookingManagerGuestInformation';
import BookingManagerCommentsConnected from 'containers/BookingManagerComments';
import BookingManagerDownloadsConnected from 'containers/BookingManagerDownloads';
import BookingManagerUploads from 'containers/BookingManagerUploads';
import BookingManagerVouchersConnected from 'containers/BookingManagerVouchers';
import BookingManagerFinanceConnected from 'containers/BookingManagerFinance';
import BookingManagerDashboard from 'containers/BookingManagerDashboard';
import BookingManagerSettings from 'containers/BookingManagerSettings';

import { EBookingManageRoutes } from './routes';
import { BookingManagerStyles } from './BookingManagerStyles';
import { LoadingBar } from 'ui/NetworkStatusBar';

interface IRouteParams {
  bookingUuid: string;
}
export interface IBookingManagerContainerProps extends RouteComponentProps<IRouteParams> {
  className?: string;
}
export const BookingManagerContainer = (props: IBookingManagerContainerProps) => {
  const dispatch = useDispatch();
  const booking = useSelector(BookingManagerSelectors.bookingSelector);
  const bookingLoadRequestStatus = useSelector(BookingManagerSelectors.bookingLoadSelector);
  const isSr = useSelector(AuthSelectors.isSR);
  const hideFinance = useSelector(AuthSelectors.hideFinanceSelector);
  const isAdmin = useSelector(AuthSelectors.isAdmin);

  const { bookingUuid } = props.match.params;

  useEffect(() => {
    const shouldLoadNewBooking = booking?.bookingUuid !== bookingUuid;

    if (shouldLoadNewBooking) {
      dispatch(getBookingRequestAction(bookingUuid));
    }
  }, [bookingUuid]);

  const getRoutePath = (route: EBookingManageRoutes) => `${props.match.url}/${route}`;

  const userIsAllowed = (featureFlag?: boolean) => {
    if (typeof featureFlag !== 'undefined' && !featureFlag) {
      return false;
    }

    return isSr || isAdmin;
  };

  const renderMenu = () => (
    <SidebarNav className="bookingManageNav">
      <NavItem to={getRoutePath(EBookingManageRoutes.Overview)}>Overview</NavItem>
      <NavItem to={getRoutePath(EBookingManageRoutes.Breakdown)}>Breakdown</NavItem>
      <NavItem to={getRoutePath(EBookingManageRoutes.GuestInformation)}>Guest Information</NavItem>
      <NavItem to={getRoutePath(EBookingManageRoutes.Comments)}>Comments</NavItem>
      <NavItem to={getRoutePath(EBookingManageRoutes.HotelDocuments)}>Hotel Documents</NavItem>
      <NavItem to={getRoutePath(EBookingManageRoutes.Uploads)}>Booking Documents</NavItem>
      {userIsAllowed() && <NavItem to={getRoutePath(EBookingManageRoutes.Vouchers)}>Vouchers</NavItem>}
      {!hideFinance && <NavItem to={getRoutePath(EBookingManageRoutes.Finance)}>Finance</NavItem>}
      {userIsAllowed() && (
        <NavItem to={getRoutePath(EBookingManageRoutes.Settings)}>
          <div className="flex items-center" style={{ height: '34px' }}>
            <img className="mr-2" src={settings} width="16" height="16" alt="Settings" />
            <span className="leading-20px">Settings</span>
          </div>
        </NavItem>
      )}
    </SidebarNav>
  );

  if (bookingLoadRequestStatus === ENetworkRequestStatus.PENDING) {
    return (
      <BookingManagerStyles>
        <div className="mt-5">
          <LoadingBar />
        </div>
      </BookingManagerStyles>
    );
  }

  if (bookingLoadRequestStatus === ENetworkRequestStatus.ERROR) {
    return (
      <div>
        <p>There was an error loading the booking. Please try again.</p>
      </div>
    );
  }

  if (!booking) {
    return <p>No booking</p>;
  }

  return (
    <BookingManagerStyles>
      {/* @ts-ignore */}
      <NavigationLayoutConnected menuPath="/" menu={renderMenu()}>
        <div className="content">
          <section className="routeContent flex flex-col">
            <Switch>
              <Route exact path={getRoutePath(EBookingManageRoutes.Overview)} component={BookingManagerDashboard} />
              <Route exact path={getRoutePath(EBookingManageRoutes.Breakdown)} component={BookingManagerBreakdown} />
              <Route
                exact
                path={getRoutePath(EBookingManageRoutes.GuestInformation)}
                component={BookingManagerGuestInformation}
              />
              <Route
                exact
                path={getRoutePath(EBookingManageRoutes.Comments)}
                component={BookingManagerCommentsConnected}
              />
              <Route
                path={getRoutePath(EBookingManageRoutes.HotelDocuments)}
                component={BookingManagerDownloadsConnected}
              />
              <Route path={getRoutePath(EBookingManageRoutes.Uploads)} component={BookingManagerUploads} />
              {userIsAllowed() && (
                <Route
                  exact
                  path={getRoutePath(EBookingManageRoutes.Vouchers)}
                  component={BookingManagerVouchersConnected}
                />
              )}
              {!hideFinance && (
                <Route
                  exact
                  path={getRoutePath(EBookingManageRoutes.Finance)}
                  component={BookingManagerFinanceConnected}
                />
              )}
              {userIsAllowed() && (
                <Route exact path={getRoutePath(EBookingManageRoutes.Settings)} component={BookingManagerSettings} />
              )}
              <Route
                exact
                path={`${props.match.path}`}
                render={() => <Redirect to={getRoutePath(EBookingManageRoutes.Overview)} />}
              />
            </Switch>
          </section>
        </div>
      </NavigationLayoutConnected>
    </BookingManagerStyles>
  );
};
export default BookingManagerContainer;
