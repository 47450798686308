import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initial, last } from 'lodash-es';
import classNames from 'classnames';
import InfoIcon from 'ui/Icons/Info.component.svg';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { AddMarkupModal } from './AddMarkupModal';
import { EditMarkupModal } from './EditMarkupModal';
import { ProductTypes } from 'config/enums';

import {
  addMarkupModalStateSelector,
  chmCloseDeleteModalAction,
  chmDeleteLoadSelector,
  chmMarkupToDeleteSelector,
  chmOpenDeleteModalAction,
  companyHotelMarkupListLoadSelector,
  companyHotelMarkupListSelector,
  companyHotelMarkupSortFieldSelector,
  companyHotelMarkupSortOrderSelector,
  bulkEditSelector,
  bulkDeleteSelector,
  editMarkupRequestAction,
  deleteCHMarkupRequestAction,
  fetchCHMarkupListRequestAction,
  openCreateMarkupModalAction,
  setCHMarkupEditingModeAction,
  setCHMarkupPercentageEditedAction,
  setCHMarkupSortFieldAction,
  chmEditLoadSelector,
  toggleMarkupAction,
  toggleAllMarkupsAction,
  selectedSelector,
  openBulkDeleteModalAction,
  closeBulkDeleteModalAction,
  openBulkEditModalAction,
  fetchProductsWithCompanyHotelMarkupRequestAction,
  productsWithCompanyHotelMarkupSelector,
  productsWithCompanyHotelMarkupLoadSelector,
} from 'store/modules/companyHotelMarkup';
import { formatDateDisplay } from 'utils';
import { CHMarkupSortField, ICompanyHotelMarkupModel } from 'store/modules/companyHotelMarkup/model';
import { CircleIconButton } from 'ui/CircleIconButton';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { getCountryName } from 'utils/country';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import { theme } from '../../../../tailwind.config';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface ICompanyAdditionalMarkupsProps {
  isEditMode: boolean;
}

function getApplicableMarkupMessage(productTypes: string[]) {
  const productTypeLabelMapping = {
    [ProductTypes.ACCOMMODATION]: 'Accommodation (including Extra person supplement)',
    [ProductTypes.MEAL_PLAN]: 'Meal Plans',
    [ProductTypes.TRANSFER]: 'Transfers',
    [ProductTypes.GROUND_SERVICE]: 'Ground services',
    [ProductTypes.FINE]: 'Fines',
  };

  const productLabels = productTypes.map(item => productTypeLabelMapping[item]);
  if (productLabels.length === 0) {
    return '';
  }
  const commaSeparated = initial(productLabels).join(', ');
  const andSeparated = [commaSeparated, last(productLabels)].filter(item => !!item).join(' and ');

  const message = `The Mark-ups will be applied ON TOP of the selling price for ${andSeparated}.`;

  return message;
}

export const AdditionalMarkups: React.FC<ICompanyAdditionalMarkupsProps> = ({ isEditMode }) => {
  const chMarkupList: ICompanyHotelMarkupModel[] = useSelector(companyHotelMarkupListSelector);
  const chMarkupSelected: ICompanyHotelMarkupModel[] = useSelector(selectedSelector);
  const chMarkupListLoad = useSelector(companyHotelMarkupListLoadSelector);
  const sortField = useSelector(companyHotelMarkupSortFieldSelector);
  const sortOrder = useSelector(companyHotelMarkupSortOrderSelector);

  const bulkDelete = useSelector(bulkDeleteSelector);
  const bulkEdit = useSelector(bulkEditSelector);

  const isAddMarkupModalOpen = useSelector(addMarkupModalStateSelector);
  const isDeleteModalOpen = !!useSelector(chmMarkupToDeleteSelector);
  const isBulkDeleteModalOpen = !!bulkDelete;
  const isBulkEditModalOpen = !!bulkEdit;

  const chmDeleteLoadStatus = useSelector(chmDeleteLoadSelector);
  const isDeleteInProgress = chmDeleteLoadStatus === ENetworkRequestStatus.PENDING;
  const chmEditLoadStatus = useSelector(chmEditLoadSelector);
  const isEditInProgress = chmEditLoadStatus === ENetworkRequestStatus.PENDING;
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const productTypesWithCHMarkup: string[] = useSelector(productsWithCompanyHotelMarkupSelector);
  const productTypesWithCHMarkupLoad = useSelector(productsWithCompanyHotelMarkupLoadSelector);

  const companyExternalMarkupPercentage = useSelector(CompanyInfoSelectors.companyExternalMarkupPercentageSelector);

  useEffect(() => {
    dispatch(fetchCHMarkupListRequestAction());
    dispatch(fetchProductsWithCompanyHotelMarkupRequestAction());
  }, [dispatch]);

  const handleSort = useCallback(
    (sortBy: CHMarkupSortField) => () => {
      const newSortOrder = sortBy === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
      dispatch(setCHMarkupSortFieldAction(sortBy, newSortOrder));
    },
    [dispatch, sortField, sortOrder]
  );

  const handleAddMarkup = useCallback(() => {
    dispatch(openCreateMarkupModalAction());
  }, [dispatch]);

  const handleDeleteMarkup = useCallback(
    (companyUuid: string, hotelUuid: string) => () => {
      dispatch(chmOpenDeleteModalAction(companyUuid, hotelUuid));
    },
    [dispatch]
  );

  const handleConfirmDeleteMarkup = useCallback(() => {
    dispatch(deleteCHMarkupRequestAction());
  }, [dispatch]);

  const handleDeleteModalClose = useCallback(() => {
    dispatch(chmCloseDeleteModalAction());
  }, [dispatch]);

  const handleBulkDeleteModalClose = useCallback(() => {
    dispatch(closeBulkDeleteModalAction());
  }, [dispatch]);

  const renderHeaderSpan = (headerLabel: ReactNode, headerSortByKey: CHMarkupSortField, sortBy?: CHMarkupSortField) => {
    return (
      <span
        className={classNames(
          'group flex font-normal items-center px-2 justify-between h-8 hover:bg-gray-10 cursor-pointer rounded',
          {
            rounded: sortBy === headerSortByKey,
            'bg-gray-15': sortBy === headerSortByKey,
          }
        )}
      >
        <span>{headerLabel}</span>
        {renderSortIcon(headerSortByKey)}
      </span>
    );
  };

  const handleEditMarkup = useCallback(
    (companyUuid: string, hotelUuid: string) => () => {
      dispatch(setCHMarkupEditingModeAction(companyUuid, hotelUuid, true));
      setHasChanges(true);
    },
    [dispatch]
  );

  const handleConfirmEdit = useCallback(
    (companyUuid: string, hotelUuid: string) => () => {
      dispatch(editMarkupRequestAction(companyUuid, hotelUuid));
      setHasChanges(false);
    },
    [dispatch]
  );

  const handleDiscardEdit = useCallback(
    (companyUuid: string, hotelUuid: string) => () => {
      dispatch(setCHMarkupEditingModeAction(companyUuid, hotelUuid, false));
      setHasChanges(false);
    },
    [dispatch]
  );

  const handleMarkupPercentageChange = useCallback(
    (companyUuid: string, hotelUuid: string) => (markupPercentage: string) => {
      dispatch(setCHMarkupPercentageEditedAction(companyUuid, hotelUuid, markupPercentage));
    },
    [dispatch]
  );

  const handleToggle = useCallback((hotelUuid: string) => dispatch(toggleMarkupAction(hotelUuid)), [dispatch]);

  const handleToggleAll = useCallback(
    e => {
      e.stopPropagation();
      dispatch(toggleAllMarkupsAction());
    },
    [dispatch]
  );

  const handleDeleteSelected = useCallback(() => dispatch(openBulkDeleteModalAction()), [dispatch]);

  const handleEditSelected = useCallback(() => dispatch(openBulkEditModalAction()), [dispatch]);

  const renderSortIcon = (newSortField: CHMarkupSortField) => {
    return (
      <span
        style={{ marginRight: '-18px', width: '38px' }}
        className={classNames('fa-stack fa-xs', {
          'group-hover:text-gray-40 text-ivory': newSortField !== sortField,
        })}
      >
        <i
          style={{ position: 'relative', top: '-5px' }}
          className={classNames('fas fa-stack-1x fa-chevron-up', {
            'text-gray-100': sortOrder === 'asc' && newSortField === sortField,
            'text-gray-40': sortOrder === 'desc' && newSortField === sortField,
          })}
        ></i>
        <i
          style={{ position: 'relative', top: '-12px' }}
          className={classNames('fas fa-stack-1x fa-chevron-down', {
            'text-gray-40': sortOrder === 'asc' && newSortField === sortField,
            'text-gray-100': sortOrder === 'desc' && newSortField === sortField,
          })}
        ></i>
      </span>
    );
  };

  const renderHotelHeader = () => (
    <label className="flex flex-row items-center">
      <Checkbox checked={chMarkupList.length === chMarkupSelected.length} onClick={handleToggleAll} readOnly />
      <span className="ml-2">HOTEL</span>
    </label>
  );

  return (
    <div className="additional-markups-section w-full">
      <p className="additional-markups-title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        External Mark-Up
      </p>

      <div className="mt-5px flex items-center font-pt-sans space-x-8px">
        <DecimalInput
          className="w-[65px] h-[35px] border border-solid border-gray-40 bg-ivory text-center text-15px focus:outline-solid focus:outline-teal-80 focus:outline-1"
          decimalPlaces={2}
          onBlur={val => {
            dispatch(CompanyInfoActions.updateCompanyExternalMarkupPercentageAction(val ? val.toString() : null));
          }}
          allowNull={true}
          value={companyExternalMarkupPercentage ? companyExternalMarkupPercentage : ''}
        />
        <span className="text-13px leading-16px text-black">%</span>
        <span className="text-13px leading-16px text-black w-[91px]">Including two decimals (##.##)</span>
        <span>
          <HidingTextTooltip
            tooltipContent="This will apply ONLY to Accommodations (including Extra Person rate), Meal Plans, Transfers, Ground Services and Fines."
            position="right-bottom"
            width="150px"
            tooltipContentClassname="px-[7px] py-[5px]"
          >
            <InfoIcon className="w-[18px]" fill={theme.colors['gray-80']} />
          </HidingTextTooltip>
        </span>
      </div>

      <p className="additional-markups-title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        Additional Mark-Ups (Internal)
      </p>
      {productTypesWithCHMarkupLoad === ENetworkRequestStatus.SUCCESS && (
        <p className="additional-markups-description font-pt-sans text-black text-15px leading-sm mt-5px mb-0">
          {getApplicableMarkupMessage(productTypesWithCHMarkup)}
        </p>
      )}
      <div className="flex items-center justify-between mt-20px">
        <div className="controls flex items-center">
          {isEditMode && (
            <FluidButton
              type="secondary"
              className="delete-selected"
              onClick={handleDeleteSelected}
              disabled={!chMarkupSelected.length}
            >
              Delete Selected <i className="far fa-trash-alt ml-2"></i>
            </FluidButton>
          )}
          {isEditMode && (
            <FluidButton
              type="secondary"
              className="edit-selected ml-10px"
              onClick={handleEditSelected}
              disabled={!chMarkupSelected.length || !isEditMode}
            >
              Edit Selected
            </FluidButton>
          )}
        </div>
        <p className="markups-quantity font-pt-sans text-brown-100 text-13px leading-sm">
          <span className="font-bold">{chMarkupList?.length ?? 0}</span> Mark-Ups Added
        </p>
      </div>

      {(chMarkupListLoad === ENetworkRequestStatus.PENDING ||
        productTypesWithCHMarkupLoad === ENetworkRequestStatus.PENDING) && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}

      {chMarkupListLoad === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}

      {chMarkupListLoad === ENetworkRequestStatus.SUCCESS && chMarkupList?.length > 0 && (
        <table className="additional-markups-table table-fixed inline-block border-collapse text-left font-pt-sans mt-15px">
          <thead>
            <tr className="additional-markups-table-headers bg-ivory border border-solid border-gray-40 shadow-pe2 h-10 uppercase text-xs text-gray-120 leading-16px tracking-2xs">
              <th
                id="hotelName-header"
                style={{ minWidth: '400px' }}
                className="pl-4"
                onClick={handleSort('hotelName')}
              >
                {renderHeaderSpan(renderHotelHeader(), 'hotelName', sortField)}
              </th>

              <th id="location-header" style={{ minWidth: '250px' }} onClick={handleSort('hotelCountryCode')}>
                {renderHeaderSpan('location', 'hotelCountryCode', sortField)}
              </th>

              <th id="markUp-header" style={{ minWidth: '200px' }} onClick={handleSort('markupPercentage')}>
                {renderHeaderSpan('mark-up (%)', 'markupPercentage', sortField)}
              </th>

              <th id="createdAt-header" style={{ minWidth: '266px' }} onClick={handleSort('createdAt')}>
                {renderHeaderSpan('created', 'createdAt', sortField)}
              </th>

              <th style={{ minWidth: '125px' }}>{/* actions */}</th>
            </tr>
          </thead>
          <tbody className="flex">
            <tr>
              <td className="p-0">
                <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                  <table className="w-full border-collapse" cellSpacing="0">
                    <tbody>
                      {chMarkupList &&
                        chMarkupList.map((item, index) => {
                          return (
                            <tr
                              key={`${item.companyUuid}.${item.hotelUuid}`}
                              className={classNames('h-50px text-15px leading-19px text-black', {
                                'bg-teal-15': item.isEditMode,
                                'bg-ivory': index % 2 === 1 && !item.isEditMode,
                              })}
                            >
                              <td
                                className="pl-6 text-ellipsis overflow-hidden whitespace-nowrap border-solid border-b border-b-gray-20"
                                style={{ minWidth: '400px' }}
                              >
                                <label
                                  className="flex flex-row items-center"
                                  onClick={e => handleToggle(item.hotelUuid)}
                                >
                                  <Checkbox checked={item.selected} readOnly />
                                  <span className="ml-2">{item.hotelName}</span>
                                </label>
                              </td>
                              <td
                                className="px-2 text-ellipsis overflow-hidden whitespace-nowrap border-solid border-b border-b-gray-20"
                                style={{ minWidth: '250px' }}
                              >
                                {getCountryName(item.hotelCountryCode)}
                              </td>
                              <td className="px-2 border-solid border-b border-b-gray-20" style={{ minWidth: '200px' }}>
                                {item.isEditMode ? (
                                  <div className="edit-markup-input flex items-center">
                                    <div className="w-15 border-3 border border-solid border-teal-80">
                                      <NumericMaskedInput
                                        initialValue={item.markupPercentage}
                                        value={item.markupPercentage}
                                        disabled={isEditInProgress}
                                        mask="##.##"
                                        onChange={handleMarkupPercentageChange(item.companyUuid, item.hotelUuid)}
                                        inputClassName="edit-markup-input-element"
                                        allowNegative
                                      />
                                    </div>
                                    <span className="font-pt-sans text-13px leading-16px text-black font-normal ml-5px">
                                      % Two decimals (##.##)
                                    </span>
                                  </div>
                                ) : (
                                  `${item.markupPercentage} %`
                                )}
                              </td>
                              <td className="px-2 border-solid border-b border-b-gray-20" style={{ minWidth: '266px' }}>
                                {formatDateDisplay(item.createdAt)}
                              </td>
                              <td className="px-2 border-solid border-b border-b-gray-20" style={{ minWidth: '125px' }}>
                                <div className="flex items-center ml-2">
                                  <div className="w-1 h-30px bg-brown-15 rounded"></div>
                                  {item.isEditMode ? (
                                    <React.Fragment>
                                      <CircleIconButton
                                        style={{ paddingTop: '2px' }}
                                        className="chm-row-edit-confirm mr-1 ml-5 w-30px h-30px relative"
                                        iconClass="fas fa-check"
                                        disabled={isEditInProgress}
                                        type={'primary'}
                                        onClick={handleConfirmEdit(item.companyUuid, item.hotelUuid)}
                                      />
                                      <CircleIconButton
                                        style={{ paddingTop: '2px' }}
                                        className="chm-row-edit-discard mx-1 w-30px h-30px relative"
                                        iconClass="fas fa-times"
                                        type="secondary"
                                        onClick={handleDiscardEdit(item.companyUuid, item.hotelUuid)}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {isEditMode && (
                                        <CircleIconButton
                                          type="secondary"
                                          className="chm-row-edit-button mr-1 ml-5 w-30px h-30px "
                                          iconClass="fas fa-pen"
                                          onClick={handleEditMarkup(item.companyUuid, item.hotelUuid)}
                                        />
                                      )}
                                      {isEditMode && (
                                        <CircleIconButton
                                          type="secondary"
                                          className="chm-row-delete-button mx-1 w-30px h-30px"
                                          iconClass="far fa-trash-alt"
                                          onClick={handleDeleteMarkup(item.companyUuid, item.hotelUuid)}
                                        />
                                      )}
                                    </React.Fragment>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {isEditMode && (
        <FluidButton
          type="secondary"
          className="add-additional-markup mt-20px"
          onClick={handleAddMarkup}
          disabled={!isEditMode}
        >
          Add Additional Mark-Up
        </FluidButton>
      )}

      <AddMarkupModal isOpen={isAddMarkupModalOpen} />
      <EditMarkupModal isOpen={isBulkEditModalOpen} />

      <ConfirmationModal
        className="additional-markups-delete-warning"
        type={EConfirmationModalType.WARNING}
        isOpen={isDeleteModalOpen}
        title="Delete Additional Mark-Up"
        message="Are you sure you want to delete this mark-up?"
        extraMessage="This cannot be undone!"
        confirmButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        isConfirmLoading={isDeleteInProgress}
        onConfirm={handleConfirmDeleteMarkup}
        onCancel={handleDeleteModalClose}
      />

      <ConfirmationModal
        className="additional-markups-delete-selected-warning"
        type={EConfirmationModalType.WARNING}
        isOpen={isBulkDeleteModalOpen}
        title="Delete Additional Mark-Ups"
        message="Are you sure want to delete the items selected?"
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
        isConfirmLoading={isDeleteInProgress}
        onConfirm={handleConfirmDeleteMarkup}
        onCancel={handleBulkDeleteModalClose}
      />

      <LeaveWithoutSavingModal when={hasChanges} />
    </div>
  );
};
