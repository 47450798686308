import * as React from 'react';
import { formatDateDisplay, offsetDate } from 'utils';
import classNames from 'classnames';
import { BookingTasksOverviewStyles } from './BookingTasksOverviewStyles';
import { EBookingManageRoutes } from 'containers/BookingManager/routes';
import SectionTitle from 'ui/SectionTitle';
import { ETaskStatus, ITask } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { TaskListStatus } from 'ui/TaskListStatus';
import { TaskListPriority } from 'ui/TaskListPriority';
import { PriorityHighComponent } from 'ui/Icons';
import { useSelector } from 'react-redux';
import { bookingRefSelector } from 'store/modules/bookingManager/selectors';
import Checkbox from 'ui/Checkbox';

interface IBookingTasksWrapperProps {
  shouldShowDone?: boolean;
  handleShowDone?: React.FormEventHandler<HTMLInputElement>;
  hasContent?: boolean;
  children?: React.ReactNode;
}

const BookingTasksWrapper: React.FC<IBookingTasksWrapperProps> = ({ hasContent, children, shouldShowDone, handleShowDone }) => {
  const bookingRef = useSelector(bookingRefSelector);

  return (
    <BookingTasksOverviewStyles className="task-overview font-pt-sans">
      <div className="table font-pt-sans w-full">
        <div className="header flex justify-between">
          <SectionTitle>Booking Tasks</SectionTitle>
          {handleShowDone && <div className="show-done flex items-center gap-[8px]">
            <Checkbox checked={shouldShowDone} onClick={handleShowDone} />
            <span className="font-pt-sans text-[16px] select-none capitalize">Show Done</span>
          </div>}
        </div>
        <div className={`${hasContent && 'cells'} content text-black flex  overflow-auto min-h-[80px] max-h-[231px]`}>{children}</div>
        <div className="footer bg-brown-10">
          <a className="view" href={`/tasks/create?bookingRef=${bookingRef}`} target="_blank" rel="noopener noreferrer">
            Create task
          </a>
        </div>
      </div>
    </BookingTasksOverviewStyles>
  );
};
interface IBookingTasksOverviewProps {
  bookingTasks: ITask[] | null;
  isLoading: boolean;
  error: string | null;
}

export const BookingTasksOverview: React.FC<IBookingTasksOverviewProps> = ({ bookingTasks, isLoading, error }) => {
  const [shouldShowDone, setShowDone] = React.useState(false);

  const handleShowDone = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setShowDone(e.currentTarget.checked);
  }, []);

  if (error !== null) {
    return (
      <BookingTasksWrapper>
        <span className="info error text-red-100">{error}</span>
      </BookingTasksWrapper>
    );
  }

  if (isLoading || !bookingTasks) {
    return (
      <BookingTasksWrapper>
        <span className="info loading">Loading...</span>
      </BookingTasksWrapper>
    );
  }

  if (bookingTasks.length === 0) {
    return (
      <BookingTasksWrapper shouldShowDone={shouldShowDone} handleShowDone={handleShowDone}>
        <div className="flex items-start h-full mt-[20px]">
          <span className="no-booking-tasks font-pt-sans text-[15px] italic">{shouldShowDone ? 'No tasks' : 'No pending tasks'}</span>
        </div>
      </BookingTasksWrapper>
    );
  }

  const renderBookingTasks = (bookingTasks: ITask[]) => {
    return bookingTasks
    .filter(task => shouldShowDone ? true : task.status !== ETaskStatus.DONE)
    .map((task: ITask, taskIndex: number) => {
      const isUrgent = task.status !== ETaskStatus.DONE && offsetDate(task.deadline) < offsetDate(new Date());
      const cellClassName: string = classNames({
        lastRow: bookingTasks.length - 1 === taskIndex,
      });

      return (
        <div key={task.id} className="flex">
          <div className={`name tableCell flex-1 pr-[8px] ${cellClassName}`}>
            <span title={task.key + ' - ' + task.subject} className="max-w-[195px] overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer underline">{task.subject}</span>
          </div>
          <div className={`arrival-flight tableCell text-left min-w-[100px] px-[8px] ${cellClassName}`}>
            <TaskListStatus status={task.status} />
          </div>
          <div className={`departure-flight tableCell text-right min-w-[45px] px-[8px] ${cellClassName}`}>
            <TaskListPriority priority={task.priority} />
          </div>
          <div className={`stay-dates tableCell text-right min-w-[92px] pl-[8px] ${cellClassName}`}>
            <div className={classNames('flex items-center', { 'text-status-cancelled': isUrgent })}>{formatDateDisplay(task.deadline)} {isUrgent && <PriorityHighComponent className='w-4 h-4 fill-status-cancelled' />}</div>
          </div>
        </div>
      );
    });
  };

  return <BookingTasksWrapper hasContent shouldShowDone={shouldShowDone} handleShowDone={handleShowDone}>{renderBookingTasks(bookingTasks)}</BookingTasksWrapper>;
};

export default BookingTasksOverviewStyles;
